import {Controller} from "@hotwired/stimulus"

// wrapper around https://github.com/lannex/react-password-strength-bar
export default class extends Controller {
  static targets = ['password', 'input', 'display']
  static values = {
    inputs: Array,
    alwaysVisible:{ type: Boolean, default: true }, // false - show only when something typed
  }


  async connect() {
    const {createRoot, createPasswordStrengthBarElement} = await import("../imports/react_password_strength_bar")
    this.renderElement = createPasswordStrengthBarElement;
    this.root = createRoot(this.displayTarget);
    const warningTag = document.createElement('div');
    warningTag.className = 'password-warning-text';
    this.passwordTarget.after(warningTag);
    this.passwordTarget.addEventListener('input', () => warningTag.textContent = this.passwordTarget.value.length <= 6 && this.passwordTarget.value.length > 0 ? 'Must be 7 or more characters (' + this.passwordTarget.value.length + '/7)' : '');


    if(this.alwaysVisibleValue){
      this.render()
    }
  }

  get props() {
    return {
      password: this.passwordTarget.value,
      userInputs: [...this.inputTargets.map(f => f.value), ...this.inputsValue]
    }
  }

  // event
  update() {
    this.render()
  }

  render() {
    this.root.render(
        this.renderElement(this.props)
    );
  }

  disconnect() {
    this.root.unmount();
  }

}
