/*
  FullCalendar Business Hours
  Docs: https://fullcalendar.io/docs/businessHours

  This class is used to set business hours for the calendar.

  businessHours: [ // specify an array instead
    {
      daysOfWeek: [ 1], // Monday
      startTime: '08:00', // 8am
      endTime: '12:00' // 6pm
    },
    {
      daysOfWeek: [1], // Monday
      startTime: '14:00',
      endTime: '18:00'
    {
      daysOfWeek: [ 4, 5 ], // Thursday, Friday
      startTime: '10:00',
      endTime: '16:00'
    }
  ]

*/

class CalendarBusinessHours {
  constructor(businessHours) {
    this._businessHours = businessHours
  }

  get minTime() {
    let min;

    this.businessHours.forEach((businessHour) => {
      let time = this.timeToMoment(businessHour.startTime)

      if(!min || time.isBefore(min)) min = time
    });

    return min ? min.format('HH:mm:ss') : '00:00:00';
  }

  minTimeByDay(day) {
    let min;

    this.businessHours.forEach((businessHour) => {
      if(!businessHour.daysOfWeek.includes(day)) return;

      let time = this.timeToMoment(businessHour.startTime)

      if(!min || time.isBefore(min)) min = time
    });

    return min ? min.format('HH:mm:ss') : '00:00:00';
  }

  get businessHours() {
    return this._businessHours
  }

  // convert string '08:00' to moment object
  timeToMoment(time) {
    return moment(time, 'HH:mm')
  }
}

export default CalendarBusinessHours;
