import { Controller } from "@hotwired/stimulus"

import Quill from 'quill'
import 'quill/dist/quill.snow'

export default class extends Controller {
  static values = {
    placeholder: String
  }

  connect() {
    this.$element = $(this.element)
    this.initEditor()
  }

  initEditor() {
    // we need this workaround to avoid form auto submitting on page load, because quill trigger the change event even on rendering
    this.changeEventsCount = 0

    this.$element.addClass('d-none')

    this.editorContainerId = 'id_' + (+new Date() + Math.floor(Math.random() * 10000));

    this.$element.after(`<div class='text-editor-wrapper' id='${this.editorContainerId}'></div`)

    this.quill = new Quill(`#${this.editorContainerId}`, {
      theme: 'snow',
      placeholder: this.placeholderValue,
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'link'],
          [ { list: 'ordered' }, { list: 'bullet' }, { 'align': [] }, { 'size': [] } ],
          ['clean']
        ]
      }
    })

    this.__setContent()

    let timerId;
    this.quill.on('text-change', () => {
      this.changeEventsCount += 1

      this.$element.val(this.quill.root.innerHTML)

      // do not trigger change event first time, because quill trigger it on rendering
      if( this.changeEventsCount > 1) {
        // use jQuery to trigger change event to make sure jQuery "on" handler will catch it
        //this.element.dispatchEvent(new Event('change'))
        clearTimeout(timerId);
        timerId = setTimeout(() => {
          this.$element.trigger('change')
          this.element.dispatchEvent(new Event('change')) // Dispatch still required to call stimulus action
        }, 2000);
      }
    })
  }

  reload() {
    this.__setContent()
  }

  // set content with innerHTML to avoid "TypeError: after.appendChild is not a function" error for the <strong> tag
  __setContent() {
    this.quill.root.innerHTML = this.$element.val()
  }
}
