import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static onChange;

  connect() {
    let $element = $(this.element)

    let select = $element.selectize({
      persist: false,
      create: true,
      maxItems: 1,
      closeAfterSelect: true,
      dropdownParent: null,
      highlight: false,
      addPrecedence: true,
      // fix: add data attribute to option
      onInitialize: function () {
        var s = this;
        this.revertSettings.$children.each(function () {
          if(this.tagName == 'OPTION') $.extend(s.options[this.value], $(this).data());
          else if(this.tagName == 'OPTGROUP') {
            $(this.children).each(function(){
              $.extend(s.options[this.value], $(this).data());
            })
          }
        });
      },
      onChange: (value) => {
        let price, duration, seats, description;
        if(select.options[value] ){
          price = select.options[value].price
          duration = select.options[value].duration
          seats = select.options[value].seats
          description = select.options[value].description
        }

        const event = new CustomEvent("change", { detail: { value: value, price: price, duration: duration, seats: seats, description: description} })
        this.element.dispatchEvent(event)
      },
      render: {
        option: function(item, escape) {
          console.log(item)
          return `<div class="option"><span>${escape(item.text)}</span><span class="right">${item.durationFormatted}</span></div>`
        }
      },
      create: function(input, callback) {
        RemoteModal.show('class-service-form', `/classes/new?nested=true&name=${encodeURIComponent(input)}`, {
          nested: true,
          success: (data) => {
            if(!data.duration) data =  {}
            this.$dropdown.hide();
            callback(data)
          },
          hidden: () => {
            this.$dropdown.hide();
            callback([]);
          }
        })
      }
    })[0].selectize;
  }
}
