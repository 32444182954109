// DEPREACATED
import Rails from '@rails/ujs';
import FilterDateRange from './filter_date_range';
import moment from 'moment';

window.moment = moment;

class CommonFilters {
  constructor() {
    // Autosubmit form
    $('#page-main').on('change', 'select.autosubmit', function(e) {
      Rails.fire($(this).closest('form')[0], 'submit');
    });

    const dateRangeEl = document.getElementById('filter-daterange');
    if(dateRangeEl) {
      // RW
      const dateRanges = {
        'All': [undefined, undefined],
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'This week (Sun - Today)': [moment().startOf('week'), moment()],
        'This week (Mon - Today)': [moment().startOf('week').add(1, 'days'), moment()],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last week (Sun - Sat)': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
        'Last week (Mon - Sun)': [moment().subtract(1, 'week').startOf('week').add(1, 'days'), moment().subtract(1, 'week').endOf('week').add(1, 'days')],
        'Last business week (Mon - Fri)': [moment().subtract(1, 'week').startOf('week').add(1, 'days'), moment().subtract(1, 'week').startOf('week').add(5, 'days')],
        'Last 14 days': [moment().subtract(13, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
      new FilterDateRange('#filter-daterange', dateRanges, $(dateRangeEl).data('submit'));
    }

    this.initGeneralDropdown();
    this.initMultiselectDropdown();

    // if(document.getElementById('select-user')) this.initSelectUser();
  }

  initMultiselectDropdown() {
    $('#page-main').on('click', '.dropdown.filter-control.multiple .dropdown-item-apply .btn', function (e) {
      $(this).closest('.dropdown').find('.dropdown-toggle').trigger('click');
    });

    $('#page-main').on('click', '.dropdown.filter-control.multiple .dropdown-menu .dropdown-item', function (e) {
      e.stopPropagation();
    });
  }

  initGeneralDropdown() {
    $('#page-main').on('click', '.dropdown.filter-control.single .dropdown-item', function(e) {
      e.stopPropagation();
      e.preventDefault();

      const li = $(this)
      const dropdown = li.closest('.dropdown')

      // set selected text to toggle control
      dropdown.find('.dropdown-toggle').text(li.data('text'))

      // change selected element
      dropdown.find('a.active').removeClass('active')
      li.addClass('active')

      // set selected value to hidden input
      dropdown.find('input').val(li.data('value')).trigger('change')

      if(dropdown.hasClass('submit')) {
        Rails.fire(dropdown.closest('form')[0], 'submit');
      }

      // close dropdown
      dropdown.find('.dropdown-toggle').trigger('click');

      return false
    });
  }

}

export default CommonFilters;
