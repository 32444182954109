import './server-message';

// reload page via turbo when get ServerMessage.RELOAD event with data {dom_id: 'element_id', success: 'success notice message'}
// reload page only if page have #dom_id element
export default class {
  constructor() {
    window.ServerMessage.on('reload', (e) => {
      if(document.getElementById(e.message.dom_id)){
        // wait when visit completed and show notification on turbo:load event
        this.notify = e.message;

        // debounce calls, if several requests came in same time to reload page, wait till last
        // this add 1 second delay
        if(this.timeout) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(this.reload.bind(this), 1000)
      }
    });

    $(document).on('turbo:load', ()=> {
      if( !this.notify ) return;
      if(this.notify.success) {
        Notify.success(this.notify.success);
      }
      this.notify = null;
    });
  }

  reload() {
    this.timeout = null;
    Turbo.visit(location.toString());
  }
}

