import { Controller } from "@hotwired/stimulus"

// controller shows loader for image
// and hides it just as image downloaded and shown
export default class extends Controller {
  static values = {
    blockLink: Boolean
  }

  connect() {
    this.linkHref = null
    this.element.addEventListener('load', this.loaded.bind(this))
    this.setLoading()
  }

  setLoaded(){
    $(this.element).removeClass("loading")
    if(this.blockLinkValue) {
      $(this.element.closest("a")).href = this.linkHref
    }
  }

  loaded() {
    this.setLoaded()
  }

  setLoading(){
    if(this.blockLinkValue) {
      this.linkHref = $(this.element.closest("a")).href
    }
    $(this.element).addClass("loading")
  }

  disconnect() {
    // remove event listener
    this.element.removeEventListener('load', this.loaded)
  }
}