import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import SignaturePad from "signature_pad"

// sign pad based on HTML5 canvas with control to reset its content
export default class extends Controller {
  static targets=['formSubmitButton', 'formDeclineButton', 'signatureField', 'actionField']

  bindCanvas(){
    this.padRootElement = document.getElementById("signature-pad")
    if(!this.padRootElement) {
      this.handleReady()
      return
    }

    this.canvas = this.padRootElement.querySelector("canvas")
    this.placeholder = this.padRootElement.querySelector(".sign-placeholder")

    this.clearBtn = this.padRootElement.querySelector("#modal-clear-pad-btn")
    this.clearBtn.addEventListener("click", this.clear.bind(this))
    this.sizeTimer = setInterval(this.resizeCanvas.bind(this),100)
  }


  connect(){
    this.bindCanvas()
    this.formSubmitButtonTarget.addEventListener("click", this.signAndSubmitHandler.bind(this))
    this.formDeclineButtonTarget.addEventListener("click", this.declineHandler.bind(this))
    this.signedForm = this.element.closest('form')
  }

  disconnect(){
    this.formSubmitButtonTarget.removeEventListener("click", this.signAndSubmitHandler.bind(this))
    this.formDeclineButtonTarget.removeEventListener("click", this.declineHandler.bind(this))

    if(this.clearBtn) {
      this.clearBtn.removeEventListener("click", this.clear.bind(this))
    }
  }

  // Rails.fire does not handle named submits correctly and we need to pass resulting action as hidden field
  setAction(action) {
    this.actionFieldTarget.value = action
  }

  declineHandler(event) {
    this.setAction('decline')
  }

  signAndSubmitHandler(event) {
    event.preventDefault()

    if(this.signaturePad) {
      if(this.signaturePad.isEmpty()) {
        $(this.placeholder).addClass('signature-empty-error')
        return
      } else {
        this.signatureFieldTarget.value = this.signaturePad.toDataURL()
      }
    }

    this.setAction('approve')
    Rails.fire(this.signedForm,'submit');
  }

  resizeCanvas() {
    const ratio =  Math.max(window.devicePixelRatio || 1, 1)
    // there is a delay between canvas is available and styled, so, here we 
    // are waiting for canvas size adjusted
    if(this.canvas.offsetWidth == 0) {
      return 
    }
    this.canvas.width = this.canvas.offsetWidth * ratio;
    this.canvas.height = this.canvas.offsetHeight* ratio;
    var emptySignPad = !this.signaturePad
    this.signaturePad = new SignaturePad(this.canvas);

    this.signaturePad.addEventListener('beginStroke', this.canvasChange.bind(this))
    this.canvas.getContext("2d").scale(ratio, ratio);
    this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    clearInterval(this.sizeTimer)
    if(emptySignPad) {
      this.handleReady()
    }
  }

  handleReady() {
    this.formSubmitButtonTarget.disabled = false
    this.formDeclineButtonTarget.disabled = false
    $(this.element).addClass('ready')
  }

  canvasChange() {
    $(this.placeholder).removeClass('signature-empty-error')
  }

  clear(){ 
    this.signaturePad.clear();
    $(this.placeholder).removeClass('signature-empty-error')
    this.signatureFieldTarget.value = this.signaturePad.toDataURL();
  }
}
