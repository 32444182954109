import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

var stripeScriptLoaded = false;

export default class extends Controller {
  static targets = ["cardElement", "addressElement",
                    "cardErrors", "loadErrors"]

  initialize() {
    if(stripeScriptLoaded) {
      this.init()
    } else {
      this.__loadStripeScript()
    }
  }

  init() {
    try {
      var self = this

      this.$form = $(this.element)

      this.stripe = Stripe(this.data.get('key'))

      let returnUrl = this.data.get('return-url')
      let options = {
        clientSecret: this.data.get('intent-key'),
        appearance: {
          theme: 'stripe',
          variables: {
            colorTextPlaceholder: '#B3BCC7',
            colorText: '#071E3E'
          }
        }
      }

      this.stripeElements = this.stripe.elements(options)

      let paymentOptions = {}
      let accountName = this.data.get('account-name')

      if( accountName ) {
        paymentOptions.business = {
          name: accountName
        }
      }

      this.card = this.stripeElements.create('payment', paymentOptions)
      this.card.mount(this.cardElementTarget)

      if(this.hasAddressElementTarget) {
        let addressOptions = { mode: 'billing' }
        this.address = this.stripeElements.create('address', addressOptions)
        this.address.mount(this.addressElementTarget)
      } else {
        this.address = null
      }

      // this flag will prevent updating PaymentIntent on initial rendering
      // when the first "change" event fired without actual changing
      this.paymentWidgetReady = false
      // it prevent updating PaymentIntent on every widget change
      // even when the payment type stays the same
      this.previousPaymentType = 'card'

      this.card.on('ready', (event)=> {
        this.paymentWidgetReady = true
      })
      // listen for widget changes and update the PaymentIntent to recalculate fees depends on chosen payment type
      this.card.on('change', (event)=> {
        if( event.elementType == 'payment' && this.paymentWidgetReady && this.previousPaymentType != event.value.type ) {
          this.previousPaymentType = event.value.type

          // enable billing address on card payments only
          if(this.address) {
            if(event.value.type == 'card') {
              this.address.mount(this.addressElementTarget)
            } else {
              this.address.unmount()
            }
          }

          this.__disablePayButton()

          let url = this.data.get('intent-update-url')

          $.ajax(url, {
            type: 'PATCH',
            dataType: 'json',
            data: {
              "payment_method": event.value.type,
            }})
            .done((response) => {
              this.stripeElements.fetchUpdates().
                then((response) => {
                  this.__enablePayButton()
                })
            })
        }
      })

      this.$form.submit(function(event) {
        if($(self.cardElementTarget).is(':visible')) {
          self.__disablePayButton()

          let submitOptions = {
            elements: self.stripeElements,
            confirmParams: {
              return_url: returnUrl
            }
          }

          self.stripe.confirmPayment(submitOptions).then(function(result) {
            if(result.error) {
              let errorMessage = result.error.message
              if((/^missing.*?address/i).test(errorMessage)) {
                errorMessage = "Address is required for this payment type"
              }

              // Inform the user if there was an error.
              self.cardErrorsTarget.innerText = errorMessage
            }
            self.__enablePayButton()
          })
        }

        // if the card_choice visible and set to "saved card" submit payment form as usual
        // to process PI on our side with saved payment method, instead of stripe processing
        if(self.$form.find("[name='paylink_payment_form[card_choice]']").is(':visible') &&
           self.$form.find("[name='paylink_payment_form[card_choice]']:checked").val() == 'saved') {
          let hiddenInput = document.createElement('input')
          hiddenInput.setAttribute('type', 'hidden')
          hiddenInput.setAttribute('name', 'intent_id')
          hiddenInput.setAttribute('value', self.data.get('intent-id'))

          self.$form.append(hiddenInput)

          return true
        } else {
          // do not submit form to server, because payment will be processed by the Stripe
          return false
        }
      })

    } catch(e) {
      this.__loadError()
    }
  }

  toggleSaveCard(event) {
    this.__disablePayButton()

    let url = this.data.get('intent-update-url')

    $.ajax(url, {
      type: 'PATCH',
      dataType: 'json',
      data: {
        "save_card": event.target.checked,
      }})
    .done((response) => {
      this.stripeElements.fetchUpdates().
        then((response) => {
          this.__enablePayButton()
        })
    })
  }

  __disablePayButton() {
    $('.buttons .btn').attr('disabled', true)
  }

  __enablePayButton() {
    $('.buttons .btn').attr('disabled', false)
  }

  // Handle real-time validation errors from the stripe elements
  __errorsHandler(event) {
    if(event.error) {
      this.cardErrorsTarget.innerText = event.error.message
    } else {
      this.cardErrorsTarget.innerText = ''
    }
  }

  __loadStripeScript(){
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => this.init();
    script.src = "https://js.stripe.com/v3/";
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  __loadError() {
    $(this.loadErrorsTarget).removeClass('d-none')
    this.$form.submit(() => false)
  }
}
