import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["selectedItems"]

  connect() {
  }

  controlId(id, key) {
    return "selected_items_" + id + "_" + key
  }

  controlName(id, key) {
    return "selected_items[" + id + "][" + key + "]"
  }

  deleteMetadataToCreateForm(id) {
    this.selectedItemsTarget.querySelectorAll("input").forEach((input) => {
      if (input.id.startsWith("selected_items_" + id)) {
        input.remove()
      }
    })
  }

  addHiddenInput(inputId, inputName, inputValue) {
    // Create a new input element of type 'hidden'
    var input = document.createElement("input");
    input.type = "hidden";
    input.id = inputId;
    input.name = inputName;
    input.value = inputValue;

    // Find the form using its ID and append the input to it
    this.selectedItemsTarget.appendChild(input);
  }

  addMetadataToCreateForm(id, metadata) {
    const transformations = {'attachmentMetadataId': "attachment_metadata_id"}
    Object.keys(metadata).forEach((initialKey) => {
      let key = initialKey
      if (transformations[initialKey]) {
        key = transformations[initialKey]
      }
      this.addHiddenInput(this.controlId(id, key), this.controlName(id, key), metadata[initialKey])
    })
  }

  itemMetadata(element) {
    return {
      id: element.dataset.id,
      title: element.dataset.title || "",
      attachmentMetadataId: element.dataset.attachmentMetadataId
    }
  }

  selectItem(event) {
    let clicked_item = event.target.closest(".image-item")
    let imageMetadata = this.itemMetadata(clicked_item)
    let imageId = imageMetadata.id

    if (clicked_item.classList.contains('selected')) {
      clicked_item.classList.remove("selected")
      this.deleteMetadataToCreateForm(imageId)
    } else {
      clicked_item.classList.add("selected")
      this.addMetadataToCreateForm(imageId, imageMetadata)
    }
  }
}
