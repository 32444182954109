import {Controller} from "@hotwired/stimulus";

export default class extends Controller {

  onEditRole(e) {
    e.preventDefault()

    RemoteModal.show('role-form', e.currentTarget.getAttribute('href'), {
      nested: true,
      success: (data) => {
        // change label text for edited role
        $(`#user_role_id_${data.value}`).next().text(data.text)

        Notify.success('The role is updated')
      }
    })
  }

  onNewRole(e) {
    e.preventDefault()

    RemoteModal.show('role-form', e.currentTarget.getAttribute('href'), {
      nested: true,
      success: (data) => {
        // clone existing role
        let newRole = $('.select-role-item:first').clone()
        let radioButton = newRole.find('input[type="radio"]')
        let idToReplace = radioButton.val()

        // replace cloned data and links to the newly created role
        radioButton.val(data.value)
        radioButton.prop('id', `user_role_id_${data.value}`)
        radioButton.next().text(data.text)
        newRole.html(newRole.html().replaceAll(`/roles/${idToReplace}`, `/roles/${data.value}`).replaceAll(`source_id=${idToReplace}`, `source_id=${data.value}`))

        // select the newly created role
        radioButton = newRole.find('input[type="radio"]')
        radioButton.prop('checked', true)

        $('.select-role').append(newRole) // add role to the roles list

        Notify.success('The role is created')
      }
    })
  }
}
