import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  collapseAll(e) {
    e.preventDefault();
    e.stopPropagation();

    $(this.element).find('.table-date--tbody').addClass('collapsed');

    return false;
  }

  expandAll(e) {
    e.preventDefault();
    e.stopPropagation();

    $(this.element).find('.table-date--tbody').removeClass('collapsed');
    return false;
  }

  toggleGroup(e) {
    $(e.currentTarget).closest('.table-date--tbody').toggleClass('collapsed');
  }
}
