import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["button", 'body']

  connect() {
    this.accordion = $(this.bodyTarget)
    this.button = $(this.buttonTarget)
    this.boundHandleHide = this.handleHide.bind(this)
    this.accordion.on('hidden.bs.collapse', this.boundHandleHide)
  }

  disconnect() {
    this.accordion.off('hidden.bs.collapse', this.boundHandleHide)
  }

  handleHide() {
    this.buttonTarget.querySelector('input').checked = false
    this.buttonTarget.classList.remove('active')
    this.accordion.collapse('hide')
  }

  hide() {
    this.handleHide()
    this.accordion.collapse('hide')
  }
}