import Rails from '@rails/ujs';
import { Controller } from "@hotwired/stimulus"
import moment from 'moment';

export default class extends Controller {
  static targets = ["startDate", "endDate", "field"]
  static values = {
    ranges: String // 'past' or default
  }

  async connect() {
    await import('daterangepicker');

    $(this.fieldTarget).daterangepicker({
      autoUpdateInput: false,
      autoApply: true,
      alwaysShowCalendars: true,
      ranges: this.buildRanges()
    }, (start, end, label) => {
      this.startDateTarget.value = start.format('YYYY-MM-DD');
      this.endDateTarget.value = end.format('YYYY-MM-DD');
      Rails.fire($(this.element).closest('form')[0], 'submit');
    });

    this.setDate();

    $(this.element).find('.btn-filter-reset').on('click', () => {
      $(this.fieldTarget).data('daterangepicker').setStartDate(undefined);
      $(this.fieldTarget).data('daterangepicker').setEndDate(undefined);
      this.startDateTarget.value = '';
      this.endDateTarget.value = '';
      $(this.fieldTarget).val('');
      Rails.fire($(this.element).closest('form')[0], 'submit');
    })
  }

  getStartDate() {
    const val = this.startDateTarget.value;
    return val == "" ? undefined: moment(val)
  }

  getEndDate() {
    const val = this.endDateTarget.value
    return val == "" ? undefined : moment(val)
  }

  setDate() {
    let start = this.getStartDate();
    let end = this.getEndDate();
    if(start && end) {
      $(this.element).toggleClass('filter-active');
      $(this.fieldTarget).data('daterangepicker').setStartDate(start);
      $(this.fieldTarget).data('daterangepicker').setEndDate(end);
      if(this.startDateTarget.value == this.endDateTarget.value) {
        $(this.fieldTarget).val($(this.fieldTarget).data('daterangepicker').startDate.format('MMM D, YYYY'))
      } else {
        $(this.fieldTarget).val($(this.fieldTarget).data('daterangepicker').startDate.format('MMM D, YYYY') + ' - ' + $(this.fieldTarget).data('daterangepicker').endDate.format('MMM D, YYYY'));
      }

    }
  }

  buildRanges(){
    if(this.rangesValue == 'past'){
      return {
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      }
    } else {
      return {'Today': [moment(), moment()],
      'Tomorrow': [moment().add(1, 'day'), moment().add(1, 'day')],
      'This week': [moment().startOf('week'), moment().endOf('week')],
      'Next 30 days': [moment(), moment().add(30, 'days')],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      }
    }
  }
}



// export default class extends Controller {
//   connect() {
//     const ranges = {
//       'All': [undefined, undefined],
//       'Today': [moment(), moment()],
//       'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
//       'This week (Sun - Today)': [moment().startOf('week'), moment()],
//       'This week (Mon - Today)': [moment().startOf('week').add(1, 'days'), moment()],
//       'Last 7 Days': [moment().subtract(6, 'days'), moment()],
//       'Last week (Sun - Sat)': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
//       'Last week (Mon - Sun)': [moment().subtract(1, 'week').startOf('week').add(1, 'days'), moment().subtract(1, 'week').endOf('week').add(1, 'days')],
//       'Last business week (Mon - Fri)': [moment().subtract(1, 'week').startOf('week').add(1, 'days'), moment().subtract(1, 'week').startOf('week').add(5, 'days')],
//       'Last 14 days': [moment().subtract(13, 'days'), moment()],
//       'This Month': [moment().startOf('month'), moment().endOf('month')],
//       'Last 30 Days': [moment().subtract(29, 'days'), moment()],
//       'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
//     }

//     this.filter = $(this.element)

//     this.picker = this.filter.daterangepicker({ ranges: ranges }, this.apply.bind(this));

//     // if(autosubmit) {
//       this.filter.on('apply.daterangepicker',  ()=> {
//         this.filter.closest('form').submit();
//       });
//     // }


//     if(this.filter.find('#start_date').val() == '') {
//       this.apply(null, null, 'All');
//     } else if(this.filter.find('#start_date').val() == 'overdue') {
//       this.apply(null, null, 'Overdue');
//     } else {
//       var start = this.getStartDate();
//       var end = this.getEndDate();
//       this.filter.data('daterangepicker').setStartDate(start);
//       this.filter.data('daterangepicker').setEndDate(end);
//       this.apply(start, end);
//     }
//   }

//   apply(start, end, label) {
//     if(label == 'All') {
//       this.filter.find('span').html('All');
//       this.filter.find('#start_date').val('');
//       this.filter.find('#end_date').val('');
//       this.filter.data('daterangepicker').setStartDate(undefined);
//       this.filter.data('daterangepicker').setEndDate(undefined);
//     } else if(label == 'Overdue') {
//       this.filter.find('span').html('Overdue');
//       this.filter.find('#start_date').val('overdue');
//       this.filter.find('#end_date').val('overdue');
//       this.filter.data('daterangepicker').setStartDate(undefined);
//       this.filter.data('daterangepicker').setEndDate(undefined);
//       // $('.ranges li[data-range-key="Overdue"]').addClass('active');

//     } else {
//       this.filter.find('span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
//       this.filter.find('#start_date').val(start.format('YYYY-MM-DD'));
//       this.filter.find('#end_date').val(end.format('YYYY-MM-DD'));
//       this.filter.find('#drn').val(label);
//     }
//   }

//   getStartDate() {
//     const val = this.filter.find('#start_date').val()
//     return val == "" ? moment() : moment(val)
//   }

//   getEndDate() {
//     const val = this.filter.find('#end_date').val()
//     return val == "" ? moment() : moment(val)
//   }
// }

