import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["customer", "invoice",
                    "amount", "tipAmount", "tipOption",
                    "parentObjectParamName", "parentObjectParamValue",
                    "paylinkControl"]

  updateTipAmount(event) {
    if(!this.hasTipAmountTarget) return

    let amount = parseFloat(this.amountTarget.value.replaceAll(',', ''))
    if( isNaN(amount) ) amount = 0.0

    let tipOption = this.tipOptionTargets.find(o => o.checked).value

    this.tipAmountTarget.value = (amount * tipOption / 100).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  onChangeCustomer() {
    let customerId = null

    if(this.customerTarget.value.includes("_")) {
      customerId = parseInt(this.customerTarget.value.split("_").at(-1))
    } else {
      customerId = parseInt(this.customerTarget.value)
    }

    this.__clearInvoiceSelect()

    if( !isNaN(customerId) ) {
      this.__loadCustomerInvoices(customerId)
    }
  }

  // toggle Paylink & QR links visibility and set correct url depends on chosen invoice
  onChangeInvoice() {
    let invoiceId = parseInt(this.invoiceTarget.value)

    if( isNaN(invoiceId) ) {
      $(this.paylinkControlTargets).addClass('d-none')
    } else {
      this.paylinkControlTargets.forEach(control => {
        let url = control.getAttribute('data-url-template').replace("INVOICEID", invoiceId)
        let href = control.querySelector('a')
        href.href = url
      })
      $(this.paylinkControlTargets).removeClass('d-none')
    }
  }

  __clearInvoiceSelect() {
    this.invoiceTarget.querySelectorAll('option').forEach( item => item.remove() )
  }

  __loadCustomerInvoices(customerId) {
    let filterParams = {
      status: ['unpaid']
    }

    if(this.hasParentObjectParamNameTarget && this.hasParentObjectParamValueTarget) {
      filterParams[this.parentObjectParamNameTarget.value] = this.parentObjectParamValueTarget.value
    }

    $.ajax({
      url: `/customers/${customerId}/invoices`,
      type: 'GET',
      dataType: 'json',
      data: filterParams,
      success: (invoices) => {
        let option = document.createElement("option")
        option.text = 'Select Invoice'
        option.value = ''
        this.invoiceTarget.add(option)

        invoices.forEach((invoice) => {
          option = document.createElement("option")
          option.text = invoice.number
          option.value = invoice.id
          this.invoiceTarget.add(option)
        })
      }
    });
  }
}
