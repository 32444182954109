import { Controller } from "@hotwired/stimulus"

var facebookScriptLoaded = false;

export default class extends Controller {
  initialize(){
    if(facebookScriptLoaded){
      this._init()
    } else {
      this._loadScript()
    }
  }

  login() {
    let endpointUrl = this.data.get('url')
    // used for existed review source objects. If omitted new review source will be created
    let sourceId = this.data.get('sourceId')

    // sample successful response:
    // {
    //     "authResponse": {
    //         "accessToken": "foobarbaz",
    //         "userID": "4242424242",
    //         "expiresIn": 6796,
    //         "signedRequest": "foobarbaz",
    //         "graphDomain": "facebook",
    //         "data_access_expiration_time": 1629230804
    //     },
    //     "status": "connected"
    // }
    FB.login((response) => {
      if (response.authResponse && response.status == 'connected') {
        let token = response.authResponse.accessToken
        let userId = response.authResponse.userID

        $.post(endpointUrl, { token: token, user_id: userId, source_id: sourceId }).
          done((data) => {
            Notify.success('Facebook has been connected')
            Turbo.visit(location.toString())
          }).
          fail((data) => {
            Notify.error(data) // Show a failure message
          })

      } else {
        // User cancelled login or did not fully authorize. Do nothing.
      }
    }, {scope: 'public_profile,pages_show_list,pages_read_user_content'})
  }

  _init() {
    facebookScriptLoaded = true

    let self = this

    FB.init({
      appId: this.data.get('appId'),
      autoLogAppEvents: true,
      xfbml: false,
      version: 'v10.0'
    })
  }

  _loadScript() {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    script.onload = () => this._init()
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
