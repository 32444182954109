import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input"]

  sync(event) {
    event.preventDefault()

    this.inputTarget.value = event.target.dataset.value
    this.inputTarget.dispatchEvent(new Event('change'))
  }
}
