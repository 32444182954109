import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

import Client from 'braintree-web/client'
import HostedFields from 'braintree-web/hosted-fields'

export default class extends Controller {
  connect(){
    let self = this

    this.$form = $(this.element)
    this.$tokenInput = $('#braintree_token')

    // handle submit via flag to keep original handler (ajax/regular)
    this.readyToSubmit = false

    Client.create({
      authorization: this.data.get('client-token')
    }).then(clientInstance => {
      return HostedFields.create({
        client: clientInstance,
        fields: {
          cardholderName: {
            selector: '#credit_card_name',
            placeholder: 'Name as it appears on your card'
          },
          number: {
            selector: '#credit_card_number',
            placeholder: '4111 1111 1111 1111'
          },
          expirationDate: {
            selector: '#credit_card_expiration',
            placeholder: 'MM / YY'
          },
          cvv: {
            selector: '#credit_card_cvc',
            placeholder: '123'
          }
        }
      })
    }).then(hostedFieldsInstance => { // Use the Hosted Fields instance here to tokenize a card
      hostedFieldsInstance.on('validityChange', self.__onFieldValidityChange);

      self.$form.submit(event => {
        if(!self.readyToSubmit) {
          self.$tokenInput.val('')

          let formIsInvalid = false
          let state = hostedFieldsInstance.getState()

          // Loop through the Hosted Fields and check
          // for validity, apply the is-invalid class
          // to the field container if invalid
          Object.keys(state.fields).forEach(field => {
            if (!state.fields[field].isValid) {
              $(state.fields[field].container).addClass('is-invalid')
              formIsInvalid = true
            }
          });

          // skip tokenization request if any fields are invalid
          if(formIsInvalid) {
            return false
          }

          hostedFieldsInstance.tokenize().then(payload => {
            self.$tokenInput.val(payload.nonce)

            self.readyToSubmit = true

            // if the form "remote" submit it with the Rails default handler
            // otherwise use regular submit method
            if(self.$form.data("remote")) {
              Rails.fire(self.$form.get(0), 'submit')
            } else {
              self.$form.get(0).submit()
            }
          }).catch(err => {
            self.readyToSubmit = false

            // show alert only when the error is not related with user input
            if(err.code != 'HOSTED_FIELDS_FIELDS_EMPTY' &&
               err.code != 'HOSTED_FIELDS_FIELDS_INVALID') {
              Notify.error(err.message)
            }
          })
        }

        if(!self.readyToSubmit) {
          return false
        }
      })
    }).catch(err => { // Handle error in component creation
      Notify.error(err.message)
    })
  }

  __onFieldValidityChange(event) {
    let field = event.fields[event.emittedBy]

    // Remove any previously applied error or warning classes
    $(field.container).removeClass('is-valid')
    $(field.container).removeClass('is-invalid')

    if (field.isValid) {
      $(field.container).addClass('is-valid')
    } else if (field.isPotentiallyValid) {
      // skip adding classes if the field is
      // not valid, but is potentially valid
    } else {
      $(field.container).addClass('is-invalid')
    }
  }
}
