import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    currency: { type: String, default: 'USD' }
  }

  static targets = ["planCost", "paymentTypeLabel",
                    "paymentType",
                    "billingOptionBtn"]

  initialize() {
    this.currencyFormatter = Intl.NumberFormat('en', {style: 'currency', currency: this.currencyValue})
  }

  setBillingOption(event) {
    event.preventDefault()

    $(this.billingOptionBtnTargets).removeClass('active')

    let button = event.target.closest('button')

    let amount = button.dataset.billingAmount
    let type = button.dataset.billingType
    let typeLabel = button.dataset.billingLabelAbbr

    this.__setPlanCost(amount, type, typeLabel)

    $(button).addClass('active')
  }

  __setPlanCost(amount, type, typeLabel) {
    let formattedAmount = this.currencyFormatter.format(amount)
    $.each(this.planCostTargets, function() { return this.innerHTML = formattedAmount })

    typeLabel = `/${typeLabel}`
    $.each(this.paymentTypeLabelTargets, function() { return this.innerHTML = typeLabel })

    this.paymentTypeTarget.value = type
  }
}
