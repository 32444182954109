import { Controller } from "@hotwired/stimulus"

// set target.value based on event source data-value
export default class extends Controller {
  static targets = ['target']

  update(event) {
    this.targetTarget.value = event.currentTarget.dataset.value;
    this.targetTarget.dispatchEvent(new Event('change'));
  }

}
