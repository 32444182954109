import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    let $element = $(this.element)
    $element.on('change', "input[type='checkbox']", this.validate.bind(this));

    // change custom validity message
    $element.find("input[type='checkbox']").each(function() {
      $(this).on('invalid', function() {
        this.setCustomValidity('Please select at least one option.')
      })
    })

    this.validate()
  }

  validate() {
    let $checkboxes = $(this.element).find("input[type='checkbox']")
    let $checked = $checkboxes.filter(':checked')

    if($checked.length == 0) {
      $checkboxes.each(function() {
        $(this).prop('required', true)
      })
    } else {
      $checkboxes.each(function() {
        $(this).prop('required', false)
        // remove custom validity
        this.setCustomValidity('')
      })
    }
  }
}
