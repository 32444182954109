import { Controller } from "@hotwired/stimulus"

// Disable a button then related controls values are blank
export default class extends Controller {
  static targets = ['button', 'control']

  connect() {
    this.updateState();
  }

  changed(event) {
    this.updateState()
  }

  updateState() {
    let disabled = true;
    this.controlTargets.forEach(control => disabled &&= this._isEmpty(control.value) )
    this.buttonTarget.disabled = disabled;
  }

  _isEmpty(value) {
    return (!value || value.length === 0 );
  }
}
