import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  onAddCustomer(e) {
    e.preventDefault();

    RemoteModal.show('customer-form', `/customers/new?nested=true`, {
      nested: true,
      success: (data) => {
        this.addCustomer(data.value);
      }
    })
  }

  onSelectCustomer(e) {
    this.addCustomer(e.detail.value);
  }

  addCustomer(customer_id) {
    $.ajax({
      url: this.urlValue,
      type: 'POST',
      data: { customer_id: customer_id }
    })
  }
}
