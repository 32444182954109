import {Controller} from "@hotwired/stimulus"
import ProgressBar from '../app/components/progress-bar'

/*
load details on list/details show layout via ajax
this prevent list part from reset on click and keep filter query

change table.table-clickable with table.table-pseudo-clickable
tbody data-list-details-target="list"
data-list-details-target="details"
*/
export default class extends Controller {
  static targets = ["list", "details"]
  static values = {
    url: String,
    path: String
  }

  initialize() {
    this.progressBar = new ProgressBar;
  }

  connect() {
    if(this.hasListTarget) {
      this.listTarget.addEventListener('click', this._onListClick.bind(this))
    }
  }

  visit(e) {
    this._load(e.target.href)
    e.preventDefault();
  }

  _onListClick(e) {
    const tr = $(e.target).closest('tr');
    this._load(tr.data('url'), tr[0])
  }

  open(e) {
    this._load(e.target.closest('.card').dataset.path)
  }

  _load(url, makeActiveListEl) {
    this.progressBar.show()
    fetch(url, {headers: {'X-Details-Load': 'true'}})
        .then(response => response.ok ? response.text() : Promise.reject(response.statusText))
        .then(html => {
          this.detailsTarget.innerHTML = html
          history.pushState(null, document.title, url)
          if( makeActiveListEl ) this._switchActive(makeActiveListEl)
          this.progressBar.hide()
        }).catch(error => this.progressBar.hide());
  }

  _switchActive(makeActiveListEl) {
    this.listTarget.querySelectorAll('.active').forEach(el => el.classList.remove('active'))
    makeActiveListEl.classList.add('active')
  }
}
