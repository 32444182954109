// @selector .table-loader
//
// optimized table loaded for paging and sorting
// instead of rely on turbolink, request and load only _table partial

import ProgressBar from "./progress-bar";

const progressBar = new ProgressBar;

class TableLoader {
  constructor() {
    // Collapse card
    let tableLoader = $('.table-loader')
    tableLoader.on('click', 'th.sorting, th.sorting_desc, th.sorting_asc, .pagination .page-item', this.loadTable);

    // Put sort param value to filter form
    tableLoader.on('click', 'th.sorting, th.sorting_desc, th.sorting_asc', function () {
      let sortValue = $(this).data('sort-value')
      if(sortValue) {
        if( $("input[name='sort']").length === 0 ){
          $('.filters-form').append('<input type="hidden" name="sort">')
        }
        $("input[name='sort']").val(sortValue)
      }
    })
  }

  loadTable(event) {
    const link = $(this).find('a')[0];
    if( !link ){ return true }

    progressBar.show()

    $.ajax({
      url: link.href,
      method: 'GET',
      headers: {'X-Table-Load': '1'},
      dataType: 'html'
    })
    .done(tableHtml => {
      $('.table-loader').html(tableHtml);
      history.pushState(null, document.title, link.href)
      progressBar.hide()
    })
    .fail(() => {
      progressBar.hide()
      Notify.error('Something went wrong!')
    });
    return false;
  }

}

export default TableLoader;
