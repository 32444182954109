import Map from './map';
import MapItem from './map-item';
import MapList from './map-list';

class MapPage {
  constructor() {
    this.dateFormat = "YYYY-MM-DD HH:mm";
    this.dataURL = '/map/data.json'
    this.date = new Date()

    this.list = new MapList({
      clickItem: (index) => { this.map.openMarker(index); }
    });

    this.restore()

    this.datePicker = flatpickr("#filter-date-picker", {
      defaultDate: this.date,
      monthSelectorType: 'static',
      yearSelectorType: 'static',
      animate: true,
      dateFormat: 'M d, Y',
      altDateFormat: 'M d, Y',
      onChange: (selectedDates, dateStr, instance) => {
        this.loadData();
        this.setDate(selectedDates[0])
      }
    });

    $("#filter-user, #filter-customer, #filter-service").on('change', (event) => this.loadData() )

    let map = this
    $('#filter-user').on('change', function () {
      let options = document.getElementById('filter-user').selectedOptions
      map.setFilteredUsers(Array.from(options).map(({ value }) => value))
    })
    $('#filter-service').on('change', function () {
      let options = document.getElementById('filter-service').selectedOptions
      map.setFilteredServices(Array.from(options).map(({ value }) => value))
    })
    $('#filter-customer').on('change', function () {
      let options = document.getElementById('filter-customer').selectedOptions
      map.setFilteredCustomersWithLabels(Array.from(options).map(({ text, value }) => [text, value]))
    })
  }

  initMap() {
    this.map = new Map();
    this.loadData();
  }

  loadData() {
    var start = moment(this.datePicker.selectedDates[0]).startOf('day').format(this.dateFormat);
    var end = moment(this.datePicker.selectedDates[0]).endOf('day').format(this.dateFormat);
    var userId = Array.from(document.querySelectorAll('#filter-user :checked')).map(({ value }) => parseInt(value))
    var customerId = Array.from(document.querySelectorAll('#filter-customer :checked')).map(({ value }) => parseInt(value))
    var serviceId = Array.from(document.querySelectorAll('#filter-service :checked')).map(({ value }) => parseInt(value))

    fetch(this.dataURL,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': $("[name='csrf-token']").attr('content')
        },
        credentials: 'same-origin',
        body: JSON.stringify({start: start, end: end, user_id: userId, customer_id: customerId, price_list_item_id: serviceId})
      })
      .then(response => response.json())
      .then(json => {
        var items = this.responseToItems(json);
        this.list.buildList(items)
        this.map.buildMarkers(items)
        // this.map.drawDirections(items)
      })
  }

  responseToItems(json) {
    var items = []
    json.data.forEach((data) => {
      items.push(new MapItem(data.attributes.item.data))
    })

    return items;
  }

  setDate(value) {
    localStorage.setItem('map-date', value.toISOString())
  }

  restoreDate() {
    let value = moment(localStorage.getItem('map-date'))
    if(value.isValid()) this.date = value.toDate()
  }

  setFilteredUsers(value) {
    localStorage.setItem('map-filtered-users', value)
  }

  restoreFilteredUsers() {
    let value = localStorage.getItem('map-filtered-users')
    if (value) {
      $('#filter-user').val(value.split(','))
    }
  }

  setFilteredServices(value) {
    localStorage.setItem('map-filtered-services', value)
  }

  restoreFilteredServices() {
    let value = localStorage.getItem('map-filtered-services')
    if (value) {
      $('#filter-service').val(value.split(','))
    }
  }

  setFilteredCustomersWithLabels(value) {
    localStorage.setItem('map-filtered-customers', JSON.stringify(value))
  }

  restoreFilteredCustomers() {
    let value = localStorage.getItem('map-filtered-customers')
    if (!value) {
      return
    }

    let customerFilter = $("#filter-customer")[0]
    try {
      value = JSON.parse(value)
      for (let c of value) {
        let option = document.createElement('option')
        option.text = c[0]
        option.value = c[1]
        option.selected = true
        customerFilter.add(option)
      }
    }
    catch (e) {
      console.log(`Unparseable string: ${value}`)
      localStorage.removeItem('map-filtered-customers')
    }
  }

  restore() {
    this.restoreDate()
    this.restoreFilteredUsers()
    this.restoreFilteredServices()
    this.restoreFilteredCustomers()
  }
}

export default MapPage;
