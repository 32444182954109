import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["title", "nonTaxableItem", "fieldTaxRateId"]

  initialize() {

  }

  connect() {
    this.element[this.identifier] = this
  }

  onSelectTaxRate(e) {
    this.chooseTaxRate(e.currentTarget)
  }

  onNewTaxRate(e) {
    e.preventDefault();

    RemoteModal.show('tax-rate-form', e.currentTarget.getAttribute('href'), {
      success: (data) => {
        this.addTaxRate(data);
      },
    })
  }

  remove(e) {
    this.fieldTaxRateIdTarget.value = 'destroy'
    $(this.fieldTaxRateIdTarget).trigger('change')
  }

  addTaxRate(itemHtml) {
    let item = $(itemHtml)
    $(this.element).find('.dropdown-item').removeClass('active')
    $(this.element).find('.dropdown-menu .dropdown-divider').insertBefore(item)

    this.chooseTaxRate(item[0])
  }

  chooseTaxRate(el) {
    this.titleTarget.innerText = el.innerText;
    this.fieldTaxRateIdTarget.value = el.dataset.taxRateId || 'destroy'
    $(this.fieldTaxRateIdTarget).trigger('change')
  }

  chooseNonTaxable() {
    this.chooseTaxRate(this.nonTaxableItemTarget)
  }
}
