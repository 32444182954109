import {initializeApp} from "firebase/app";
import { getMessaging } from "firebase/messaging";

let appMessaging = null;

// Initialize Firebase Cloud Messaging and get a reference to the service
export const getFirebaseMessaging = () => {
  if( !window.gFirebaseConfig ) return;

  return appMessaging = getMessaging(initializeApp(gFirebaseConfig));
};
