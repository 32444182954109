import { Controller } from "@hotwired/stimulus"

// used for the forms with the group of inputs switched by tabs (represented as links)
// inputs inside tabs became disabled on tab switching to avoid override in submitted params
export default class extends Controller {
  connect() {
    // do nothing if the form disabled
    if(this.controllerDisabled) {
      return
    }

    this.$element = $(this.element)

    let self = this
    this.$element.on('shown.bs.tab', 'a[data-toggle="tab"]', function() { self.__updateInputs() })

    // set initial state
    this.__updateInputs()
  }

  get controllerDisabled() {
    // when the attr set to false it just removed from the DOM so need to check only for existence here
    return this.data.has('disabled')
  }

  __updateInputs() {
    this.$element.find('.tab-pane:visible :input').each(function() { this.disabled = false })
    this.$element.find('.tab-pane:hidden :input').each(function() { this.disabled = true })
  }
}
