import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // static targets = [] // future: "show" "hide" like display_switch_controller
  static classes = [ "self" ] // future: "show" "hide"

  toggle() {
    this.element.classList.toggle(this.selfClass)
  }

}
