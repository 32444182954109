import { Controller } from "@hotwired/stimulus"

let easyAutocompleteModule = undefined;

export default class extends Controller {
  static targets = ["description", "discountType", "value", "applyBeforeTax"]

  initialize() {
    easyAutocompleteModule ||= import('../app/components/jquery.easy-autocomplete');
    this.url = this.data.get('url')
  }

  connect() {
    var $element = $(this.descriptionTarget)

    var self = this

    let options = {
      adjustWidth: false,

      url: function(query) {
        return `${self.url}?q=${query}`
      },

      getValue: function(element) {
				return element.description.replace(/</g, "&lt;").replace(/>/g, "&gt;"); //
      },

      requestDelay: 500,

      list: {
        onChooseEvent: function() {
			    let itemData = $element.getSelectedItemData()

          self.descriptionTarget.value = itemData.description
          self.discountTypeTarget.value = itemData.discount_type
          self.valueTarget.value = itemData.value
          self.applyBeforeTaxTarget.checked = itemData.apply_before_tax
		    }
	    }
    }

    easyAutocompleteModule.then(() => $element.easyAutocomplete(options))
  }
}
