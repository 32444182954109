import { Controller } from "@hotwired/stimulus"

// disable other related checkboxes when this is selected
export default class extends Controller {

  connect() {
    const self = this;
    $('input[data-action*=disable-related-checkbox]').each(function(){ self._disableRelated(this) })
  }

  disable(event){
    this._disableRelated(event.target)
  }

  _disableRelated(checkbox){
    const related = checkbox.dataset.related.split(',');
    for(let id of related){
      document.getElementById(id).disabled = checkbox.checked
    }
  }

}
