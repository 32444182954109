import { Controller } from "@hotwired/stimulus"
import '../../app/components/direct-upload';

export default class extends Controller {
  static targets = []
  connect() {
    $('#import_file').on('change', this.onFileUploadChanged);
  }

  onFileUploadChanged(e) {
    let file = this.files[0];
    let name = file.name.split('.')[0];
    let size = file.size;

    // a size must be less than 150MB
    if(size > 157286400) {
      Notify.error('The file size must be less than 150 MB');
      this.value = ''

      return false;
    }

    if($('#import_name').val() == '') $('#import_name').val(name);
  }
}
