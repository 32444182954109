import {Controller} from "@hotwired/stimulus"

// data-chart-data="{categories:, amounts:, counts:}", data-count-label, data-value-label
// data-value-currency="optional|$|CA$"
export default class extends Controller {
  async connect() {
    const {Highcharts} = await import("../../imports/highcharts")

    // need to set thousandsSep to correctly format numbers
    Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    })

    const dataset = this.element.dataset;
    const chartData = JSON.parse(dataset.chartData)
    const currency = this.element.dataset.valueCurrency || '$'

    Highcharts.chart(this.element, {
      title: {
        text: null,
      },
      xAxis: {
        categories: chartData.categories
      },
      yAxis: [{ // Amount Axis
        labels: {
          format: `${currency}{value}`,
        },
        title: {
          text: null,
        },
        opposite: true
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: null,
        }
      }],
      credits: {
        enabled: false
      },
      plotOptions: {
        column: {
          opacity: 0.9
        }
      },
      legend: {
        itemStyle: {
          color: '#9b9b9b',
          fontWeight: 'normal'
        }
      },
      series: [
        {
          name: dataset.countLabel || 'Count',
          type: "spline",
          data: chartData.counts,
          color: '#3796F7',
          yAxis: 1,
          zIndex: 2
        },
        {
          name: dataset.valueLabel || 'Value',
          type: "column",
          data: chartData.amounts,
          color: '#44AEFF',
          zIndex: 1,
          tooltip: {
            pointFormat: `{series.name}: <b>${currency}{point.y:,.2f}</b>`
          }
        }
      ]
    });
  }
}
