import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';
import 'bootstrap-select';


export default class extends Controller {
  static targets = []

  connect() {
    const actionTemplate = `<div class="dropdown-custom-actions btn-block apply-button-group">
        <a href='#' class='dropdown-custom-action action-dont-import'>Don't import</a>
      </div>`;

    // <a href='#' class='dropdown-custom-action action-new-field'>Create a new field</a>

    this.importTarget = $('#import-mapping-form').data('import-target');

    $('.import-field-select').selectpicker({
      liveSearch: true,
      liveSearchPlaceholder: 'Search a Field'
    });

    $('.import-field-select').on('shown.bs.select', function() {
      if(!$(this).find(".dropdown-custom-actions")[0]) {
        $(this).find("div.dropdown-menu").append(actionTemplate);
      }
    });

    $('.import-field-select').on('changed.bs.select', this.onFieldChanged.bind(this));

    $('#import-mapping').on('click', '.action-new-field', this.onAddNewField.bind(this));
    $('#import-mapping').on('click', '.action-dont-import', this.onDontImportField.bind(this));
    $('#import-mapping').on('click', '.action-import-again', this.onRestoreImportField);
    $('#import-mapping-form').on('click', '#submit-import-mapping', this.onSubmit.bind(this));
  }

  onSubmit(e) {
    // validate existing required fields for related an import target
    let fields = this.getSelectedFields();

    if(this.importTarget == 'customers') {
      if(fields.indexOf('first_name') == -1 && fields.indexOf('last_name') == -1 && fields.indexOf('company') == -1) {
        Notify.error("First name or Last name or Company fields must be mapped");
        return false;
      }
    }

    Rails.fire($('#import-mapping-form')[0], 'submit');
  }

  onFieldChanged(e, clickedIndex, isSelected, previousValue) {
    let el = $(e.target);
    let value = el.val();

    if(value == "") {
      el.closest('tr').removeClass('matched');
    } else if(this.isFieldIncluded(value)) {
      let label = el.find('option:selected').text();

      el.selectpicker('val', previousValue)
      Notify.error(`${label} field already selected. You can select every field only once.`)
    } else {
      el.closest('tr').addClass('matched');
    }
  }

  onDontImportField(e) {
    e.preventDefault();

    let dropdown = $(e.target).closest('.dropdown');
    dropdown.hide();

    let select = dropdown.find('select');
    select.selectpicker('val', null);

    $(e.target).closest('td').append(`<div><input type='hidden' name='${dropdown.find('select').attr('name')}' value='!-*ignore' /><strong>Will not be imported</strong>&nbsp;&nbsp;<a href='#' class='action-import-again'>Edit</a></div>`)
  }

  onRestoreImportField(e) {
    e.preventDefault();

    $(e.target).closest('td').find('.import-field-select').show()
    $(e.target).closest('div').remove();
  }

  onAddNewField(e) {
    e.preventDefault();

    let select = $(e.target).closest('.dropdown').find('select');
    RemoteModal.show('field-form', $(select).data('new-field-url'));
  }

   assignNewField(field) {
    $('#import-mapping select.import-field-select').each(function(){
      $(this).append(`<option value='${field.name}'>${field.label}</option>`).selectpicker('refresh');
    });

    $(`tr[data-column='${field.import}']`).find('select').selectpicker('val', field.name);
  }

   isFieldIncluded(field) {
    let counter = 0;
    let fields = this.getSelectedFields();

    for (var i = 0; i < fields.length; i++) {
      if(fields[i] == field) counter++;
      if(counter == 2) return true;
    }

    return false;
  }

  getSelectedFields() {
    return $('select.import-field-select').map(function(){return $(this).val()}).toArray();
  }
}
