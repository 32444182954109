import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "table"]
  static timerId;

  connect() {
    this.boundHandleInput = this.handleInput.bind(this)
    this.inputTarget.addEventListener('input', this.boundHandleInput)
  }

  disconnect() {
    this.inputTarget.removeEventListener('input', this.boundHandleInput)
  }

  clearFilter() {
    this.inputTarget.value = '';
    this.handleInput();
  }

  // class "row-name" have to be assigned to element that is used to match filter text
  handleInput() {
    clearTimeout(this.timerId);
    const filterString = this.inputTarget.value.toLowerCase();
    this.timerId = setTimeout(() => {
      this.tableTargets.forEach(table => {
        const rows = Array.from(table.querySelectorAll('tr:has(td)'));
        const hiddenRows = rows.filter(row => !row.querySelector('.row-name')?.textContent.toLowerCase().includes(filterString));
        rows.forEach(row => row.classList.toggle('d-none', hiddenRows.includes(row)));
        table.classList.toggle('d-none', rows.length === hiddenRows.length);
      });
    }, 200);
  }
}
