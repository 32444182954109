import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]

  connect() {
    this.setValue()
  }

  saveToLocalStorage() {
    localStorage.setItem(this.fieldTarget.id, this.fieldTarget.value)
  }

  setValue() {
    let value = localStorage.getItem(this.fieldTarget.id)
    if(value) {
      this.fieldTarget.value = localStorage.getItem(this.fieldTarget.id)
    }
  }
}
