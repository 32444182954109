import { Controller } from "@hotwired/stimulus"

// copy value of one input to another on change, and vice versa
export default class extends Controller {
  static targets = ["one", "two"]

  oneChanged() {
    this.twoTarget.value = this.oneTarget.value
  }

  twoChanged() {
    this.oneTarget.value = this.twoTarget.value
  }

}
