
class Services {
  constructor(onChange) {
    this.CLASS_TYPE = 'class'
    this.SERVICE_TYPE = 'service'

    const elementName = '#booking_service'

    this.element = $(elementName)
    if(this.element.length == 0) return;

    this.onChangeOption = onChange


    this.element.selectpicker({container: '.booking-page'});
    this.element.on('changed.bs.select', this.onChange.bind(this));

    // remove prompt option
    this.element.find("[value='']").remove();
    this.element.selectpicker('refresh');
  }

  get selectedIds() {
    return this.element.find(`option:selected`).map((_,el) => el.dataset.id).toArray().join(',')
  }

  get selectedType() {
    return this.element.find(`option:selected`).data('type')
  }

  get selectedName() {
    return this.element.find(`option:selected`).text();
  }

  get selectedDescription() {
    return this.element.find(`option:selected`).data('description')
  }

  get selectedFullDescription() {
    return this.element.find(`option:selected`).data('full-description')
  }

  get selectedOptions() {
    return this.element.find(`option:selected`)
  }

  get values() {
    const val = this.element.val()
    if(val instanceof Array) return val
    return [val]
  }

  get isSelected() {
    return this.values.length > 0
  }

  // User from first selected option
  get users() {
    if(this.isSelected) {
      const users = this.selectedOptions.get().map((option) => $(option).data('users'))
      return this.intersectionOptions(users)
    }
    return []
  }

  get isMultiple() {
    return $(this.element).prop('multiple')
  }

  deselectAll(exceptIndex) {
    this.element.find('option').each((index, option) => {

      if(index != exceptIndex) {
        $(option).prop('selected', false)
      }
    })

    this.element.selectpicker('refresh')
  }

  deselectClasses() {
    this.element.find(`option[data-type='${this.CLASS_TYPE}']`)
        .each((_, option) => $(option).prop('selected', false))

    this.element.selectpicker('refresh')
  }

  deselectAllNonMultiple() {
    this.element.find("option[data-multiple='false']")
        .each((_, option) => $(option).prop('selected', false))

    this.element.selectpicker('refresh')
  }

  onChange(e, clickedIndex, isSelected, previousValue) {
    let selectedOption = this.element.find('option')[clickedIndex]

    if(this.isMultiple) {
      // disable multiple choice option per element (services with subscription for example)
      if(selectedOption.dataset.type == this.CLASS_TYPE || selectedOption.dataset.multiple == 'false') {
        this.deselectAll(clickedIndex)
      }

      // currently classes cannot be selected with multiple, so function below will deselect them too
      if(selectedOption.dataset.multiple == 'true') {
        this.deselectAllNonMultiple()
      }

      if(selectedOption.dataset.type == this.SERVICE_TYPE) {
        // this.deselectClasses()

        if(!this.validateUsersForMultiple()){
          Notify.notice("Please select services from the same employee")
          selectedOption.selected = false
          this.element.selectpicker('refresh')
        }
      }
    }

    if(this.onChangeOption) this.onChangeOption(e);
  }

  validateUsersForMultiple() {
    const users = this.selectedOptions.get().map((option) => {
      return JSON.parse(option.dataset.users).map((user) => user.value)
    })

    if(users.length <= 1) return true

    return this.intersection(users).length > 0
  }

  intersectionOptions(arrays) {
    const intersection = arrays[0].filter(function(element) {
      for (let i = 1; i < arrays.length; i++) {
        if (!arrays[i].some((other) => other.value === element.value)) return false
      }
      return true;
    });

    return intersection;
  }

  intersection(arrays) {
    const intersection = arrays[0].filter(function(element) {
      for (let i = 1; i < arrays.length; i++) {
        if (arrays[i].indexOf(element) === -1) return false;
      }
      return true;
    });

    return intersection;
  }
}

export default Services;
