import {isSupported, onMessage} from "firebase/messaging";
import {getFirebaseMessaging} from "./messaging";

export const handleFirebaseForegroundMessages = () => {
  (async () => {
    const hasFirebaseMessagingSupport = await isSupported();
    if (!hasFirebaseMessagingSupport) return;

    const messaging = getFirebaseMessaging();
    if( !messaging ) return;
    if( messaging.onMessageHandler ) return;

    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.onBackgroundMessage` handler.
    onMessage(messaging, (payload) => {
      const link = payload.fcmOptions?.link;

      if( document.hasFocus() ) {
        Notify.push(payload.notification.title, payload.notification.body, link)
      } else {
        const n = new Notification(payload.notification.title, {body: payload.notification.body})
        if( link ){
          n.addEventListener('click', () => window.location = link)
        }
      }
    });
  })()
};
