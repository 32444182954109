import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  async connect() {
    await import('daterangepicker');

    let elPicker = $('#task_due_at');
    elPicker.daterangepicker({
      singleDatePicker: true,
      timePicker: true,
      autoUpdateInput: false,
      parentEl: '#remote-modal',
      locale: {
        format: 'MMM D, YYYY hh:mm A'
      }
    });

    elPicker.on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('MMM D, YYYY hh:mm A'));
    })

    if(elPicker.val() == '') {
      elPicker.data('daterangepicker').setStartDate(elPicker.data('default-value'))
    }

    let fetchUrl = $('#task_task_association_ids').data('url');
    $('#task_task_association_ids').selectize({
      plugins: ['remove_button'],
      maxItems: null,
      persist: false,
      closeAfterSelect: true,
      dropdownParent: 'body',
      load: function(query, callback) {
        if (!query.length) return callback();
        $.ajax({
          url: fetchUrl,
          type: 'GET',
          data: { q: query },
          error: () => { callback() },
          success: (res) => { callback(res) }
        });
      },
      render: {
        option: function(item, escape) {
            let icon;

             if(item.type == 'Customer') {
               icon = 'account'
             } else if(item.type == 'Job') {
               icon = 'briefcase'
             } else if(item.type == 'Estimate') {
               icon = 'timetable'
             } else if(item.type == 'WorkRequest') {
               icon = 'inbox'
             } else if(item.type == 'Appointment') {
               icon = 'calendar-check'
             } else if(item.type == 'CommunicationThread') {
               icon = 'comment-text'
             } else if(item.type == 'CustomerInvoice') {
               icon = 'receipt'
             }

             if(icon) {
               icon = `<i class="mdi mdi-${icon} mr-2"></i>`
             } else {
               icon = ''
             }

            return '<div class="option">' +
              icon + '<span>' + escape(item.text) + '</span>' +
            '</div>';
          }.bind(this)
      }
    });
  }
}
