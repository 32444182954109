import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['switch', 'element'];
  static classes = [ "checked" ];

  connect() {
    this._update();
  }

  handleToggle() {
    this._update();
  }

  _update() {
    this.elementTarget.classList.toggle(this.checkedClass, !this.switchTarget.checked);
  }
}