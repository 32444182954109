import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.$element = $(this.element)
    let body = $('body')
    let self = this

    this.$element.on('keyup', function () {
      self.formatCurrency($(this))
    })

    this.$element.on('blur', function () {
      self.formatCurrency($(this), true)
    })
  }

  formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  formatCurrency(input, isBlur) {
    // validates decimal side
    // and puts cursor back in right position.

    // get input value
    let inputValue = input.val()

    // don't validate empty input
    if (inputValue.length === 0) {
      return
    }

    // original length
    let originalLength = inputValue.length

    // initial caret position
    let caretPositions = input.prop("selectionStart")

    // check for decimal
    if (inputValue.indexOf('.') >= 0) {

      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      let decimalPosition = inputValue.indexOf(".")

      // split number by decimal point
      let leftSide = inputValue.substring(0, decimalPosition)
      let rightSide = inputValue.substring(decimalPosition)

      // add commas to left side of number
      leftSide = this.formatNumber(leftSide)

      // validate right side
      rightSide = this.formatNumber(rightSide)

      // On blur make sure 2 numbers after decimal
      if (isBlur) {
        rightSide += '00'
      }

      // Limit decimal to only 2 digits
      rightSide = rightSide.substring(0, 2)

      // join number by .
      inputValue = `${leftSide}.${rightSide}`

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      inputValue = this.formatNumber(inputValue)

      // final formatting
      if (isBlur) {
        inputValue += '.00'
      }
    }

    // send updated string to input
    input.val(inputValue)

    // put caret back in the right position
    if (!isBlur)  {
      let updated_len = inputValue.length
      caretPositions = updated_len - originalLength + caretPositions
      input[0].setSelectionRange(caretPositions, caretPositions)
    }
  }
}
