import {Controller} from "@hotwired/stimulus"
import flatpickr from 'flatpickr';

export default class extends Controller {

  connect() {
    if(this.element.type == 'time') return; // only if input[type=time] is not supported (safari)

    // prevent conflict with modal ESC handler
    // https://github.com/flatpickr/flatpickr/issues/1730#issuecomment-619233853
    $('.modal[role=dialog]').removeAttr('tabindex')

    flatpickr(this.element, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "H:i",
      altInput: true,
      altFormat: "h:i K"
    });
  }

}
