import { Controller } from "@hotwired/stimulus"

var paypalScriptLoaded = false;

export default class extends Controller {
  static values = {
    currency: { type: String, default: 'USD' }
  }

  initialize(){
    if(paypalScriptLoaded){
      this.init()
    } else {
      this._loadPayPalScript()
    }
  }

  init() {
    paypalScriptLoaded = true

    let ordersUrl = this.data.get('ordersUrl')
    this.tipOption = this.data.get('tipOption')

    this.tipCustomAmount = parseFloat(this.data.get('tipCustomAmount'))
    if( isNaN(this.tipCustomAmount) || this.tipCustomAmount < 0 ) {
      this.tipCustomAmount = null
    }

    paypal.Buttons({
      style: {
        color: 'blue'
      },

      onShippingChange: (data, actions) => {
        // PayPal render their actions inside the iframe by default, but it's not responsive at all on mobile devices
        // use an "empty-method" hack to force PayPal use the popup window in favor of iframe
        //
        // do nothing
      },

      // Call the server to set up the transaction
      createOrder: (data, actions) => {
        return fetch(ordersUrl, {
          method: 'post',
          body: this.__postData()
        }).then(function(res) {
          if(res.status == 500) {
            throw new Error("Something goes wrong. Please Try again later.")
          } else {
            return res.json()
          }
        }).then(function(orderData) {
          return orderData.id
        })
      },

      // Call the server to finalize the transaction
      onApprove: (data, actions) => {
        let captureUrl = `${ordersUrl}/${data.orderID}/capture`

        return fetch(captureUrl, {
          method: 'post',
          body: this.__postData()
        }).then(function(res) {
           if(res.status == 500) {
            throw new Error("Something goes wrong. Please Try again later.")
          } else {
            return res.json()
          }
        }).then(function(orderData) {
          // Three cases to handle:
          //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
          //   (2) Other non-recoverable errors -> Show a failure message
          //   (3) Successful transaction -> Show confirmation or thank you

          // This example reads a v2/checkout/orders capture response, propagated from the server
          var errorDetail = Array.isArray(orderData.details) && orderData.details[0]

          if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
            return actions.restart() // Recoverable state, per:
            // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
          }

          if (errorDetail) {
             var msg = 'Sorry, your transaction could not be processed.'
             if (errorDetail.description) msg += '\n\n' + errorDetail.description
             if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')'

             return Notify.error(msg) // Show a failure message
           }

           // Show a success message & reload the page
           Notify.success('Payment has been processed')
           Turbo.visit(location.toString())
         })
      },

      onError: (error) => {
        return Notify.error(error)
      }
    }).render(this.element)
  }

  _loadPayPalScript() {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.onload = () => this.init()
    script.src = `https://www.paypal.com/sdk/js?client-id=${this.data.get('clientId')}&currency=${this.currencyValue}`
    document.getElementsByTagName('head')[0].appendChild(script)
  }

  __postData() {
    return new URLSearchParams({
      tip_option: this.tipOption,
      tip_custom_amount: this.tipCustomAmount
    })
  }
}
