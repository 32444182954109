import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["classService", "user", "date", "time", "duration", "description", "seats", "amount", "submit"]


  initialize() {
    this.timeSlots = {};
  }

  connect() {
    this.datePicker = flatpickr('#class_session_date_part', {
      // minDate: 'today',
      onChange: this.onDateSelect.bind(this),
      onMonthChange: this.onMonthChange.bind(this),
    });

    this.initTimeSlots();
  }

  onChangeClassService(e) {
    this.durationTarget.value = e.detail.duration;
    this.seatsTarget.value = e.detail.seats;
    this.amountTarget.value = e.detail.price;
    this.descriptionTarget.value = e.detail.description;

    this.initTimeSlots();
    this.enableFormFields();
  }

  initTimeSlots() {
    this.loadTimeSlots(() => {
      let initialDate = this.datePicker.selectedDates[0];
      if(initialDate) {
        initialDate = moment(this.datePicker.selectedDates[0]).format('Y-MM-DD');
      }

      let dates = $.map(this.timeSlots, function(_, key){ return key; }).sort();

      if(dates.length > 0) {
        if(initialDate && this.timeSlots[initialDate]) {
          this.datePicker.setDate(moment(initialDate).format(), true);
          this.datePicker.jumpToDate(initialDate, true);
        } else {
          this.datePicker.setDate(moment(dates[0]).format(), true);
          this.datePicker.jumpToDate(dates[0], true);
        }
      }

      this.enableFormFields();
    });
  }

  onMonthChange(selectedDates, dateStr, instance) {
    this.loadTimeSlots();
  }

  onDateSelect(selectedDates, dateStr, instance) {
    this.selectedDate = dateStr;
    this.renderTimes();
  }

  loadTimeSlots(callback) {
    if(!this.classServiceTarget.value) {
      if(callback) { callback() }
      return;
    }

    let monthDate = `${this.datePicker.currentYear}-${this.datePicker.currentMonth+1}-01`

    $.ajax({
      url: '/class_sessions/time_slots',
      type: 'GET',
      data: {
        month_date: monthDate,
        service: this.classServiceTarget.value,
        user: this.userTarget.value,
        class_session: this.timeTarget.dataset.classSession,
        today: moment().format()},
      success: (times) => {
        this.timeSlots = times;
        times = $.map(times, function(_, key){ return key; });
        this.datePicker.set('enable', times);

        if(callback) callback();
      }
    });
  }

  renderTimes() {
    if(!this.selectedDate) return false;
    let options = ""
    let times = this.timeSlots[this.selectedDate]
    if(times) {
      for(let i = 0; i < times.length; i++) {
        if(times[i] == this.timeTarget.value) {
          options += `<option value="${times[i]}" selected>${times[i]}</option>`;
        } else {
          options += `<option value="${times[i]}">${times[i]}</option>`;
        }
      }
    } else {
      options += `<option value="">No times available</option>`;
    }
    $(this.timeTarget).html(options);
  }

  enableFormFields() {
    if(this.classServiceTarget.value && this.userTarget.value) {
      this.dateTarget.removeAttribute('disabled')
      this.timeTarget.removeAttribute('disabled')
    } else {
      this.dateTarget.setAttribute('disabled', true)
      this.timeTarget.setAttribute('disabled', true)
    }

    if(this.dateTarget.value && this.timeTarget.value) {
      this.submitTarget.removeAttribute('disabled')
    } else {
      this.submitTarget.setAttribute('disabled', true)
    }
  }
}
