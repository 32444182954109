import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["freq", "dayInterval", "dayEndDate", "weekInterval", "weekDay", "weekEndDate", "monthDay", "monthEndDate", "month",  "yearMonthDay", "yearEndDate", "fieldInterval", "fieldEndDate", "fieldMonthDay", "fieldMonth"]

  connect() {
    flatpickr('.recurring-end-date', {
      minDate: 'today',
      onChange: this.onDateSelect.bind(this),
    });

    const yearEndDatePicker = flatpickr('.recurring-year-end-date', {
      minDate: 'today',
      onChange: this.onDateSelect.bind(this),
    });
    $(yearEndDatePicker.calendarContainer).addClass('show-year-selector')

    $(this.element).find('.recurring-interval').val(this.fieldIntervalTarget.value || 1);
  }

  onChangeFreq(e) {
    e.preventDefault();
    $(e.currentTarget).parent().find('[role="tab"]').removeClass('active');
    $(e.currentTarget).tab('show');

    let freqEl = $(e.currentTarget).find('input').prop('checked', true);
    switch(freqEl.val()) {
      case '':
        this.fieldIntervalTarget.value = ''
        this.fieldEndDateTarget.value = ''
        this.fieldMonthTarget.value = ''
        this.fieldMonthDayTarget.value = ''
        $(this.weekDayTargets).prop('checked', false)
        break;
      case 'daily':
        this.fieldIntervalTarget.value = this.dayIntervalTarget.value
        this.fieldEndDateTarget.value = this.dayEndDateTarget.value
        this.fieldMonthTarget.value = ''
        this.fieldMonthDayTarget.value = ''
        $(this.weekDayTargets).prop('checked', false)
        break;
      case 'weekly':
        this.fieldIntervalTarget.value = this.weekIntervalTarget.value
        this.fieldEndDateTarget.value = this.weekEndDateTarget.value
        this.fieldMonthTarget.value = ''
        this.fieldMonthDayTarget.value = ''
        break;
      case 'monthly':
        this.fieldIntervalTarget.value = ''
        this.fieldEndDateTarget.value = this.monthEndDateTarget.value
        this.fieldMonthTarget.value = ''
        this.fieldMonthDayTarget.value = this.monthDayTarget.value
        $(this.weekDayTargets).prop('checked', false)
        break;
      case 'yearly':
        this.fieldIntervalTarget.value = ''
        this.fieldEndDateTarget.value = this.yearEndDateTarget.value
        this.fieldMonthTarget.value = this.monthTarget.value
        this.fieldMonthDayTarget.value = this.yearMonthDayTarget.value
        $(this.weekDayTargets).prop('checked', false)
        break;
    }
  }

  onChangeInterval(e) {
    this.fieldIntervalTarget.value = e.currentTarget.value;
  }

  onChangeMonthDay(e) {
    this.fieldMonthDayTarget.value = e.currentTarget.value;
  }

  onChangeMonth(e) {
    this.fieldMonthTarget.value = e.currentTarget.value;
  }

  onDateSelect(selectedDates, dateStr, instance) {
    this.fieldEndDateTarget.value = dateStr;
  }
}
