import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.el = $('#notifications-widget');
    this.list = this.el.find('#notifications-widget--items');
    this.markAllSeenBtn = this.el.find('#notifications-widget--mark-all-seen');
    this.boundMarkRead = this.markRead.bind(this);
    this.boundHandleToggleUnread = this.handleToggleUnread.bind(this);
    this.readCheckbox = this.element.querySelector('.custom-switch input');

    // wait for notifications to be loaded before filtering them
    this.load().then(() => {
      const checked = localStorage.getItem('unreadOnlyNotifications') === 'true'; // get check state from local storage
      this.element.querySelector('#unread-only-btn').checked = checked; // init switch
      this.toggleUnreadOnly(checked); // filter notifications
    });

    this.element.addEventListener("click", this.boundMarkRead);

    // we need to listen to this.element click to prevent modal closing if switch is clicked
    this.element.addEventListener('click', this.boundHandleToggleUnread, true);

    ServerMessage.on('notification', (e)=> {
      this.add(e.message.data)
    });
    ServerMessage.on('refresh-notifications', (e)=> {
      this.load(true);
    });

    // this.el.on('hidden.bs.dropdown', this.removeUnseenCssClass.bind(this));
 }

  disconnect() {
    this.element.removeEventListener("click", this.boundMarkRead);
    this.element.removeEventListener("click", this.boundHandleToggleUnread, true);
  }

  load(clear = false) {
    return $.getJSON('/notifications', (data) => {
      if(clear) {
        this.list.html('');
      }
      data.data.forEach((item) => this.add(item))
      this.toggleUnseenBadge();
      this.toggleEmptyView();
    });
  }

  add(item, updateUnseen = true) {
    const attr = item.attributes
    const seen_class = attr.seen ? "seen-state" : "unseen unseen-state"

    // prevent render duplicates
    if(this.list.find(`#notification-${attr.token}`)[0]) return;

    const html = `<li id="notification-${attr.token}" class="dropdown-item notification-item ${seen_class}" data-id="${attr.token}">
                  <div class="d-flex align-items-center">
                    ${attr.icon}
                    <a href="${attr.url}" class="d-block mr-3 ml-2">${attr.message}</a>
                  </div>
                  <div class="small date text-right">${attr.date}</div>
                </li>`
    this.list.append(html);

    if(updateUnseen) {
      this.toggleUnseenBadge();
      this.toggleEmptyView();
    }
  }

  toggleUnseenBadge() {
    this.toggleFavicon();

    if(this.list.find('.notification-item').hasClass('unseen-state')) {
      this.el.find('.nav-unread').show()
    } else {
      this.el.find('.nav-unread').hide()
    }
  }

  toggleFavicon() {
    let favicon = $('#favicon')
    let icon = favicon.attr('href');
    let iconNew = favicon.attr('data-href');
    favicon.attr('data-href', icon);
    favicon.attr('href', iconNew);
  }

  toggleEmptyView() {
    if(!this.readCheckbox.checked && this.list.find('.notification-item').length
      || this.readCheckbox.checked && this.list.find('.notification-item.unseen-state').length
    ) {
      this.list.find('.empty-view').hide();
    } else {
      this.list.find('.empty-view').show();
    }
  }

  markAllRead(e) {
    e.preventDefault();

    const elems = this.list.find('.notification-item.unseen-state');
    const ids = elems.map((_, el) => el.dataset.id).get();

    if(ids.length) {
      e.stopPropagation();
      $.ajax('/notifications/seen', { data: { ids: ids}, type: 'PATCH' })
        .done(()=> {
          elems.addClass('seen-state').removeClass('unseen unseen-state');
          this.toggleUnseenBadge();
          this.toggleEmptyView();
          this.toggleUnreadOnly(this.readCheckbox.checked);
        });
    }
    return false;
  }

  markRead(e) {
    const row = e.target.closest('.notification-item');

    if (row) {
      e.stopPropagation();
      $.ajax('/notifications/seen', { data: { ids: [row.dataset.id]}, type: 'PATCH' })
        .done(() => {
          row.classList.add('seen-state');
          row.classList.remove('unseen', 'unseen-state');
          this.toggleUnseenBadge();
          this.toggleEmptyView();
          this.toggleUnreadOnly(this.readCheckbox.checked);
      });
    }
  }

  handleToggleUnread(e) {
    const input = e.target.closest('.custom-switch')?.querySelector('input');
    if (input) {
      e.stopPropagation();
      this.toggleUnreadOnly(input.checked); // filter notifications
    }
  }

  toggleUnreadOnly(checked) {
    localStorage.setItem('unreadOnlyNotifications', checked);
    [...this.element.querySelectorAll('.notification-item.seen-state')].map(el => {
      el.style.display = checked ? 'none' : '';
    });
    this.toggleEmptyView();
  }
}
