import { Controller } from "@hotwired/stimulus"

// toggle visibility class and remember state in local storage
export default class extends Controller {
  static classes = [ "collapsed" ]
  static values = {
    localKey: String,
  }

  connect() {
    const isSmallScreen = window.innerWidth < 1200;
    this.isCollapsed = isSmallScreen || this.element.classList.contains(this.collapsedClass) || localStorage.getItem(this.localKeyValue) == 'true'
    this.updateView()
  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();

    this.isCollapsed = !this.isCollapsed
    localStorage.setItem(this.localKeyValue, this.isCollapsed)

    this.updateView()
  }

  // expand if collapsed
  expand(event) {
    if( this.isCollapsed ){
      this.toggle(event)
    }
  }

  updateView(){
    this.element.classList.toggle(this.collapsedClass, this.isCollapsed)
  }
}
