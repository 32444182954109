// NOT USING
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    message: String,
    type: String
  }

  connect() {
    alert('hi', this.typeValue);
    switch(this.typeValue) {
      case 'notice':
        Notify.notice(this.messageValue)
        break;
      case 'success':
        Notify.success(this.messageValue)
        break;
      case 'alert':
        Notify.error(this.messageValue)
        break;
    }
  }

}
