import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.autosaveInProgress = false
    this.pauseAutosave = false

    this.$element = $(this.element)

    this.__initializeListeners()
  }

  __initializeListeners() {
    var self = this

    let $document = $(document)

    // allow set autosubmit on pause from outside the controller: $(document).trigger('autosubmit:pause')
    $document.on('autosubmit:pause', function() { self.pauseAutosave = true; })
    $document.on('autosubmit:continue', function() { self.pauseAutosave = false; })

    // make sure you wrap HTML replacement (if any) in js.erb with setTimeout(function () { REPLACEMENT });
    // it delay html replacement to the next time tick that happens after the ajax:* events
    this.$element.on('ajax:complete', 'form', function() {
      self.autosaveInProgress = false;
    })

    this.$element.on('change', 'form :input:not(:button,.autosubmit-disable):visible, form :input.autosubmit', function() {
      // disable form replacing on response for rich-editor, otherwise it will lost focus on every change
      let formRenderingFlagEl = self.__getFormRenderingFlagEl()
      if(this.classList.contains('rich-editor')) {
        formRenderingFlagEl.value = true
      } else {
        formRenderingFlagEl.value = false
      }

      self.autosave(this.form)
    })

    // autosubmit on child change
    // useful for side-page forms changing some content of a parent form with a JS response
    // example of usage in js.erb response template:
    // var event = new CustomEvent('autosubmit:child-change', {bubbles: true})
    // $('<%= "form##{dom_id(galleriable_model)}" %>')[0].dispatchEvent(event)
    //
    // part with dom_id - should be replaced with the actual parent form selector
    this.$element.on('autosubmit:child-change', 'form', function() {
      let formRenderingFlagEl = self.__getFormRenderingFlagEl()
      formRenderingFlagEl.value = false

      self.autosave(this)
    })

    // daterangepicker events are not bubled up at all, listen for custom related event
    this.$element.on('daterangepicker:changed', 'form', function() {
      let formRenderingFlagEl = self.__getFormRenderingFlagEl()
      formRenderingFlagEl.value = false

      self.autosave(this)
    })
  }

  autosave(form_element) {
    // avoid multiple submits
    if(this.autosaveInProgress || this.pauseAutosave) {
      return false
    } else {
      this.autosaveInProgress = true
    }

    Rails.fire(form_element, 'submit')
  }

  // we use this hidden parameter to say controller to not replace entire form in response erb.js
  __getFormRenderingFlagEl() {
    let hiddenInputId = "stop_form_rendering"
    let hiddenInput = document.getElementById(hiddenInputId)

    if(!hiddenInput) {
      hiddenInput = document.createElement('input')
      hiddenInput.setAttribute('type', 'hidden')
      hiddenInput.setAttribute('name', hiddenInputId)
      hiddenInput.setAttribute('id', hiddenInputId)

      this.$element.find('form').append(hiddenInput)
    }

    return hiddenInput
  }
}
