import consumer from "./../../channels/consumer"
import Rails from "@rails/ujs";

export default class {
  constructor() {
    consumer.subscriptions.create("CommunicationMessageChannel", {
          received: (data) => {
            this.updateThread(data)
          }
        }
    )

    // submit on file chosen
    $(document).on('change', '.communication_message_form_file', function () {
      Rails.fire(this.form, 'submit')
      // reset form and keep text
      this.form.setAttribute('data-file-sent', 'true')
      const textEl = this.form.elements["communication_message[message]"];
      let text = textEl.value;
      this.form.reset()
      textEl.value = text;
    })

    $(document).on('keydown', '#communication_message_message', function (e) {
      if(e.which == 13 && e.ctrlKey) {
          Rails.fire(this.form, 'submit')
          e.preventDefault()
        }
    })

    $(document).on('click', '.messages-load-thread', function () {
      $('.messages-contact-list > .messages-contact.active').removeClass('active')
      const el$ = $(this);
      el$.addClass('active')
      showProgressBarAfterDelay()

      const url = el$.data('url')
      history.pushState(null, document.title, url);
      $.get(`/html${url}`, (html) => {
        $('.messages-column-right').html(html)
        hideProgressBar()
        if( $('.messages-contact-list > .messages-contact.active')[0] == el$[0] ){ // only if active not changed to other
          window.CommunicationMessage.readActive()
        }
        window.CommunicationMessage.scrollToBottom()
      })
    })
  }

  updateThread(obj) {
    if(!document.querySelector('.messages-contact-list')) return;

    const threadEl = this._findThreadEl(obj);
    if(!threadEl){
      return $.get(`/communication_threads/${obj.thread_id}.json`, function (data) {
        document.getElementById('inbox_unread_count').setAttribute('data-count', data.inbox_unread_count)

        if(document.getElementById('communication_thread_' + data.thread_id)) return;
        $('.messages-contact-list').prepend(data.html)
      })
    }

    const te = $(threadEl);
    te.find('.message-thread-time').text(obj.time)
    te.find('.message-thread-text').text(obj.message)
    te.find('.message-thread-badge.unread').attr('data-count', obj.unread_count)

    $.get({url: `/communication_threads/${obj.thread_id}/unread_counts`}, function (data) {
      te.find('.message-thread-badge.unread').attr('data-count', data.unread_count)
      document.getElementById('inbox_unread_count').setAttribute('data-count', data.inbox_unread_count)
    })

    if(threadEl.classList.contains('active')){
      $.get('/communication_messages', {
        thread_id: obj.thread_id,
        last_message_id: document.getElementById('last_message_id').value
      })
    }
  }

  addMessages(obj) {
    $('.messages-message-list .list-empty-message').remove();
    const messagesListEl = $('.messages-message-list');
    for (let item of obj.new_messages) {
      if(!document.getElementById(item[0])){
        messagesListEl.append(item[1]);
      }
    }
    const messageEl = document.querySelector('.messages-message-list').lastElementChild;
    $('#last_message_id').val(messageEl.getAttribute('data-id'));
    this.scrollToBottom();
    setTimeout(() => this.scrollToBottom(), 300) // allow image to load
  }

  read(obj) {
    const threadEl = this._findThreadEl(obj);
    if(!threadEl || threadEl.getAttribute('data-thread-id') != obj.thread_id) return;

    $(threadEl).find('.message-thread-badge.unread').attr('data-count', 0)
    $.ajax({url: `/communication_threads/${obj.thread_id}/read`, type: "PATCH"}).done((data) => {
      if(data.changed){
        document.getElementById('inbox_unread_count').setAttribute('data-count', data.inbox_unread_count)
      }
    })
  }

  // mark active thread as read after some delay
  readActive() {
    var threadEl = $('.messages-contact-list > .messages-contact.active')[0]
    if(!threadEl) return;

    var unreadCount = $(threadEl).find('.message-thread-badge.unread').attr('data-count')
    if(unreadCount == 0) return;

    setTimeout(() => {
      var currentActiveId = $('.messages-contact-list > .messages-contact.active').attr('data-thread-id');
      if(currentActiveId == threadEl.getAttribute('data-thread-id')){
        this.read({thread_id: currentActiveId})
      }
    }, 1000)
  }

  messageSent(obj) {
    this.addMessages(obj)
    // obj.type = outgoing | comment
    if(obj.type == 'outgoing'){
      $('.messages-contact.active .message-thread-time').html(obj.time)
      $('.messages-contact.active .message-thread-text').html(obj.message)
    }
    const form = document.getElementById(`new-${obj.type}-form`);
    if(form.getAttribute('data-file-sent')){
      // not reset form if file was sent
      form.removeAttribute('data-file-sent')
    } else {
      form.reset();
    }
    $(`#new-${obj.type}-form textarea`).focus();
  }

  scrollToBottom() {
    const bottomEl = document.getElementById('messages-bottom-scroll-to')
    bottomEl.parentNode.scrollTop = bottomEl.offsetTop;
  }

  _findThreadEl(obj) {
    return document.getElementById('communication_thread_' + obj.thread_id)
  }
}


var progressBarTimeout = null;
function showProgressBarAfterDelay() {
  Turbo.navigator.adapter.progressBar.setValue(0);
  progressBarTimeout = window.setTimeout(_showProgressBar, Turbo.session.progressBarDelay)
}

function _showProgressBar() {
  Turbo.navigator.adapter.progressBar.show();
}

function hideProgressBar() {
  Turbo.navigator.adapter.progressBar.hide();
  Turbo.navigator.adapter.progressBar.setValue(100);

  if (progressBarTimeout != null) {
    window.clearTimeout(progressBarTimeout)
    progressBarTimeout = null;
  }
}
