import Rails from "@rails/ujs"

const RemoteModal = {
  init: () => {
    $(document).on('click', '.remote-modal', function(e) {
      // do not open the modal and use default behaviour
      if(e.target.classList.contains("ignore-remote-modal")) {
        return;
      }

      e.preventDefault()

      let $this = $(this)

      let confirmText = $this.data('confirm-message')
      if(!confirmText || confirm(confirmText)) {
        const url = $this.attr("href") || $this.data("url")
        RemoteModal.show($this.data('target'), url, { nested: this.hasAttribute('data-nested'), fullscreen: this.hasAttribute('data-fullscreen'), right: $this.data('right'), cssClass: $this.data('css-class') })
      }

      return false
    });

    // for every nested modal add an unique class name for .modal-backdrop
    $(document).on('shown.bs.modal', '.modal-remote', function (e) {
      let backdrop = $('.modal-backdrop');
      $(backdrop[backdrop.length - 1]).addClass(`modal-backdrop-${backdrop.length}`)
      $(e.currentTarget).addClass(`modal-count-${backdrop.length}`)
    })

    $(document).on('hide.bs.modal', '.modal-remote', function (e) {
      var form = $(e.currentTarget).find('form')[0]
      if (form && form.hasAttribute('data-changed') && !form.hasAttribute('data-ignore-changes-confirm')) {
        e.preventDefault();
        Rails.confirm("Are you sure you want to exit? All entered data will be lost.", null, function(){
          form.removeAttribute('data-changed')
          $('.modal-remote').modal('hide')
        })
      }
    })

    $(document).on('hidden.bs.modal', '.modal-remote', function (e) {
      $(e.currentTarget).modal('dispose').remove();
    })
  },

  // options:
  //   nested [boolean] - show a modal as nested
  //   fullscreen [boolean] - show a modal as fullscreen
  //   cssClass [string] - add a css class to .modal element
  //   loaded() -> call callback after a modal content is loaded
  //   success() -> call callback after a modal's form submitted and server has returned a data
  //   hidden() -> call callback after a modal hidden
  show: (target, url, options) => {
    options = options || { nested: null, loaded: null, success: null, hidden: null, right: null, cssClass: ''}

    let modalClass = "modal modal-remote"
    if(options['cssClass']) modalClass += ` ${options['cssClass']}`;
    if(options['nested']) modalClass += " modal-nested";
    if(options['fullscreen']) modalClass += " modal-fullscreen";
    if(options['right'] !== false) modalClass += " modal-right";
    if(!window.$sbTestEnv) modalClass += " fade"; // don't add 'fade' transition in test env, capybara fail with it

    let modal = $(`<div id='remote-modal' class='${modalClass}' tabindex='-1' role='dialog' data-target='${target}'></div`)
    $('body').append(modal); // body instead of #page-main to make modal work on map. maybe cause problems with reload

    $.ajax(url, {
      dataType: 'html',
      headers: {
        'X-Modal': '1'
      }
    }).done((html, status, xhr) => {
      modal.html(html).modal('show')

      // call callback after a modal content is loaded
      if(options['loaded']) options['loaded']();

      // call callback after a modal returns some data
      if(options['success']) {
        $(`.modal-remote[data-target='${target}']`).on('success', function(event, data){
          options['success'](data);
        });
      }

      // call callback after a modal has been hidden
      if(options['hidden']) {
        $(`.modal-remote[data-target='${target}']`).on('hidden.bs.modal', function(e){
           options['hidden']();
        });
      }
    }).fail((xhr)=>{
      modal.remove()
      if(xhr.getResponseHeader('Content-Type') == 'text/plain; charset=utf-8'){
        Notify.error(xhr.responseText);
      } else {
        Notify.error('Something went wrong, try again later');
      }
    })
  },

  // a main modal can subscribe on nested modal to get successful data from him
  // example: contact's modal can get company_id from a nested company modal
  hideNested: (target, data) => {
    var modal = $(`.modal-nested[data-target='${target}']`)
    var form = modal.find('form')[0]

    if (form && form.hasAttribute('data-changed')) form.removeAttribute('data-changed');

    modal.trigger('success', [data]).modal('hide');
  },

  // set target(ex.: customer-form) and data to trigger 'success' callback
  // don't check a form's data-changed attribute on 'hide.bs.modal' event
  hide: (target, data) => {
    if(target && data) {
      var modal = $(`.modal-remote[data-target='${target}']`)
      var form = modal.find('form')[0]

      if (form && form.hasAttribute('data-changed')) form.removeAttribute('data-changed');

      modal.trigger('success', [data]).modal('hide');
    } else {
      var modal = $('.modal-remote')
      var form = modal.find('form')[0]
      if (form && form.hasAttribute('data-changed')) form.removeAttribute('data-changed');

      $('.modal-remote').modal('hide')
    }
  }
}

export default RemoteModal;
