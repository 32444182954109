import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.container = document.querySelector('#page-main>.column>#dashboard-container');
    this.boundHandleOut = this.handleOut.bind(this);
    this.boundHandleOver = this.handleOver.bind(this);
    this.boundHandleStart = this.handleStart.bind(this);
    this.boundHandleEnd = this.handleEnd.bind(this);
    this.boundHandleMoveStart = this.handleMoveStart.bind(this);
    this.boundHandleMoveClone = this.handleMoveClone.bind(this);
    this.boundHandlePreventEvent = this.handlePreventEvent.bind(this);
    this.modal = this.element.closest('#remote-modal');
    this.element.querySelectorAll('.dashboard-widget-container').forEach(wc => {
      const dragHandle = wc.querySelector('.drag-handle');
      if (dragHandle.offsetWidth && dragHandle.offsetHeight) { // check if dragHandle is visible (mobile version)
        dragHandle.addEventListener('pointerdown', this.boundHandleStart);
        wc.addEventListener('gotpointercapture', this.handleGotPointerCapture);
        dragHandle.addEventListener('click', e => {
          e.target.parentElement.querySelector('[type=submit]').click();
          this.scrollToWidget();
        });
      }
      else
        wc.addEventListener('pointerdown', this.boundHandleStart);
    });
  }

  scrollToWidget() {
    setTimeout(() => {
      this.container.lastChild.scrollIntoView({ block: 'end', inline: "end" });
    }, 3000);
  }

  disconnect() {
    this.element.querySelectorAll('.dashboard-widget-container .drag-handle').forEach(wc => {
      wc.removeEventListener('pointerdown', this.boundHandleStart);
      // wc.removeEventListener('gotpointercapture', this.handleGotPointerCapture); // this.boundHandleGotPointerCapture
      // wc.removeEventListener('click', this.bound...);
    });
  }

  handleStart(e) {
    e.preventDefault();
    this.draggedForm = e.target.closest('.dashboard-widget-container').querySelector('form');
    window.addEventListener('pointermove', this.boundHandleMoveStart, { once: true });
    this.modal.addEventListener('pointerup', this.boundHandleEnd, { once: true });
    window.addEventListener('contextmenu', this.boundHandlePreventEvent, { once: true });
  }

  handleMoveStart(e) {
    this.modal.addEventListener('pointerout', this.boundHandleOut);
    this.modal.addEventListener('pointerover', this.boundHandleOver);
    window.addEventListener('pointermove', this.boundHandleMoveClone);
    this.draggedForm.style.pointerEvents = 'none';

    // draggable clone
    const { x, y } = this.draggedForm.getBoundingClientRect();
    this.dragDx = e.clientX - x;
    this.dragDy = e.clientY - y;
    this.draggedFormClone = this.draggedForm.parentNode.cloneNode(true);
    this.draggedFormClone.classList.add('clone');
    this.draggedFormClone.style.width = this.draggedForm.offsetWidth + 'px';
    document.body.appendChild(this.draggedFormClone);
  }

  handleMoveClone(e) {
    this.draggedFormClone.style.transform = 'translate(' + (e.clientX - this.dragDx) + 'px, ' + (e.clientY - this.dragDy) + 'px)';
  }

  handleEnd(e) {
    this.draggedForm.style.pointerEvents = '';
    this.draggedFormClone?.remove();
    window.removeEventListener('contextmenu', this.boundHandlePreventEvent, { once: true });
    this.modal.removeEventListener('pointerout', this.boundHandleOut);
    this.modal.removeEventListener('pointerover', this.boundHandleOver);
    window.removeEventListener('pointermove', this.boundHandleMoveClone);
    window.removeEventListener('pointermove', this.boundHandleMoveStart, { once: true });
    this.container.classList.remove('over');
    if (e.target === this.modal) {
      e.stopPropagation();
      this.draggedForm?.querySelector('[type=submit]').click();
      this.scrollToWidget();

       // prevent modal close
      this.modal.addEventListener('click', this.boundHandlePreventEvent, { capture: true });
      setTimeout(() => this.modal.removeEventListener('click', this.boundHandlePreventEvent, { capture: true }));
    }
  }

  handlePreventEvent(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleOut(e) {
    if (e.target === this.modal)
      this.container.classList.remove('over');
  }

  handleOver(e) {
    if (e.target === this.modal)
      this.container.classList.add('over');
  }

  // release pointer capture on mobile
  handleGotPointerCapture(e) {
    e.target.releasePointerCapture(e.pointerId);
  }
}