import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['show', 'hide', 'timer']
  static values = {
    until: Number, // unix time
  }

  connect() {
    this.endAt = new Date(this.untilValue * 1000);
    this.timer = setInterval(this.update.bind(this), 1000)
    this.update()
  }

  disconnect() {
    this.stopTimer()
  }

  update() {
    let secondsLeft = Math.floor((this.endAt - new Date()) / 1000)
    if(secondsLeft <= 0){
      this.complete()
      this.stopTimer()
      secondsLeft = 0;
    }
    this.timerTarget.innerHTML = new Date(secondsLeft * 1000).toISOString().substr(14, 5)
  }

  complete() {
    $(this.showTargets).removeClass('d-none');
    $(this.hideTargets).addClass('d-none');
  }

  stopTimer() {
    if(this.timer){
      clearInterval(this.timer)
      this.timer = null
    }
  }
}
