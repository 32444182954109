import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.$form = $(this.element)
  }

  changePeriod(event) {
    if(event.target.checked) {
      this.$form.find('.monthly-price').addClass('d-none')
      this.$form.find('.annual-price').removeClass('d-none')
    } else {
      this.$form.find('.annual-price').addClass('d-none')
      this.$form.find('.monthly-price').removeClass('d-none')
    }
  }

  changePlan(event) {
    this.$form.find('.billing-plan').removeClass('active')
    $(event.target).parent('.billing-plan').addClass('active')
  }
}
