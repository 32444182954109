import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    connect() {
        let $element = $(this.element)

        const url = this.data.get('url')
        const preload = !!this.data.get('preload')
        const nested = this.data.get('nested') || true // send to nested form. This allow properly form response
        const multiple = this.data.get('multiple')
        const create = this.data.get('create') || true
        const removeButton = this.data.get('remove')
        const disableItems = this.data.get('disableItems') || false // selected item will be not added to selectize. This is useful when you want your selectize be always empty but selected item will be showed in another place

        let selectOptions = {
            persist: false,
            maxItems: (multiple ? null: 1),
            closeAfterSelect: true,
            dropdownParent: 'body',
            preload: preload,
            highlight: false,

            // add data attribute to option
            onInitialize: function () {
                var s = this;
                this.revertSettings.$children.each(function () {
                    $.extend(s.options[this.value], $(this).data());
                });
            },

            onChange: (value) => {
                const event = new CustomEvent("change", { detail: this.select.options[value] })
                this.element.dispatchEvent(event)

                if(disableItems) this.select.clear(true)
            },

            load: function(query, callback) {
                if ( !preload ) {
                    if (!query.length) return callback()
                }

                $.ajax({
                    url: url,
                    type: 'GET',
                    data: { q: query },
                    error: () => { callback() },
                    success: (res) => { callback(res) }
                })
            },
        }

        if(removeButton) selectOptions.plugins = ['remove_button'];

        if(create) {
            selectOptions.create = function(input, callback) {
                RemoteModal.show('employee-form', `/employees/new?nested=${encodeURIComponent(nested)}&name=${encodeURIComponent(input)}`, {
                    nested: nested,
                    success: (data) => {
                        this.$dropdown.hide();
                        callback(data)
                    },
                    hidden: () => {
                        this.$dropdown.hide();
                        callback([]);
                    }
                })
            }
        }


        this.select = $element.selectize(selectOptions)[0].selectize;
    }
}
