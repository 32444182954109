import { Controller } from "@hotwired/stimulus"

// update target.value when input is changed
export default class extends Controller {
  static targets = ['target']
  // static values = {
  //   reset: Boolean
  // }

  update(event) {
    this.targetTarget.value = event.target.value;
    event.target.value = '';
  }

}
