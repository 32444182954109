import Rails from "@rails/ujs"

// Custom Turbo confirmation dialog
Turbo.setConfirmMethod((message, element) => {
  let $element = $(element);

  let $dialog = $('#modal-confirmation')
  let $msg = $dialog.find('#modal-conf-msg')
  let $ok = $dialog.find('#modal-ok-btn')

  $dialog.find('#modal-cancel-btn').text($element.data('cancel') || 'Cancel')

  $msg.text(message)

  if($element.data('method') == 'delete') {
    $('#modal-confirm-delete-icon').show();
    $ok.text($element.data('ok') || 'DELETE');
    if($element.data('ok') == 'Yes') {
      $ok.removeClass('btn-danger').addClass('btn-primary');
    }
  } else {
    $('#modal-confirm-delete-icon').hide();
    $ok.text($element.data('ok') || 'YES')
      .removeClass('btn-danger').addClass('btn-primary');
  }

  if( $element.data('cancel-only') ){
    $ok.hide()
  } else {
    $ok.show()
  }

  $dialog.modal("show")

  return new Promise((resolve, reject) => {
    $ok.one('click', function(event) {
      event.preventDefault()

      resolve(true)

      $dialog.modal("hide")
    });
  })
})

// Custom Rails confirmation dialog
Rails.confirm = function(message, element, callback) {
  if(!element) {
    element = document.createElement('a')
  }

  let $element = $(element);

  $('.modal-confirm-cloned-element').empty()
  // Fix: copy element to dom. it allow click on deleted element.
  // it's happens when click on an element inside a tooltip(calendar, map)
  if($element.data('confirm-clone')) {
    $('.modal-confirm-cloned-element').empty().append(element.cloneNode())
    $element = $($('.modal-confirm-cloned-element').children()[0]);
  }


  let $dialog = $('#modal-confirmation')
  let $msg = $dialog.find('#modal-conf-msg')
  let $ok = $dialog.find('#modal-ok-btn')

  $dialog.find('#modal-cancel-btn').text($element.data('cancel') || 'Cancel')

  $msg.text(message)

  if($element.data('method') == 'delete') {
    $('#modal-confirm-delete-icon').show();
    $ok.text($element.data('ok') || 'DELETE');
    if($element.data('ok') == 'Yes') {
      $ok.removeClass('btn-danger').addClass('btn-primary');
    }
  } else {
    $('#modal-confirm-delete-icon').hide();
    $ok.text($element.data('ok') || 'YES')
      .removeClass('btn-danger').addClass('btn-primary');
  }

  if( $element.data('cancel-only') ){
    $ok.hide()
  } else {
    $ok.show()
  }

  // remove previous click events
  $ok.off('click')

  $ok.one('click', function(event) {
    event.preventDefault()
    let old = Rails.confirm
    Rails.confirm = function() { return true }

    if(callback) {
      callback()
    } else {
      $element.get(0).click()
    }

    Rails.confirm = old
    $dialog.modal("hide")
  });

  $dialog.modal("show")
  return false;
}
