import {Controller} from "@hotwired/stimulus"

// data-chart-data="{won:, lost:}"
export default class extends Controller {
  async connect() {
    const {Highcharts} = await import("../../imports/highcharts")

    const data = JSON.parse(this.element.dataset.chartData)

    const total = data.won + data.lost;
    const wonPercent = total == 0 ? 0 : parseInt(data.won * 100 / total)

    if (data.won == 0 && data.lost == 0) {
      data.lost = 1;  // chart fail when both is zero
    }

    Highcharts.chart(this.element, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: wonPercent + '%<br>won',
        align: 'center',
        verticalAlign: 'middle',
        y: 20
      },
      credits: {
        enabled: false
      },
      tooltip: {
        pointFormat: '{point.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: '%'
        }
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
        },
        series: {
          colorByPoint: true,
          colors: ['#4fb21f', "#d62162"]
        }
      },
      series: [{
        // colorByPoint: true,
        innerSize: '50%',
        data: [{
          name: 'Won',
          y: data.won,
        }, {
          name: 'Lost',
          y: data.lost
        }]
      }]
    });

  }
}
