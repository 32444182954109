import consumer from "./../../channels/consumer"

class ServerMessage {
  constructor() {
    consumer.subscriptions.create("ServerMessageChannel", {
        received: (message) => {
          message = JSON.parse(message)
          this.eventDOMElement(message.type).trigger({type: 'server-message:' + message.type, message: message.data})
        }
      }
    )
  }

  // Subscribe on a message type
  // Example:
  //   ServerMessage.on('notification', function(e){
  //     alert(e.message);
  //   })
  on(type, callback) {
    this.eventDOMElement(type).on('server-message:' + type, callback)
  }

  eventDOMElement(type) {
    switch (type) {
        // use 'document' for libraries that reloaded on page refresh
      case 'notification':
      case 'refresh-notifications':
      case 'reload':
        return $(document);
        break;
      // use '#page-main' for libraries that reloaded on turbo
      case 'import_progress':
      // case ServerMessage.CONTACT_ACTIVITY:
        return $('#page-main');
        break;
    }
  }
}

export default ServerMessage;
