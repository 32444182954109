import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['configButton']
  isEditing = false
  items = []

  async connect() {
    this.boundStartEditMenu = this.startEditMenu.bind(this)
    this.configButtonTarget.addEventListener('click', this.boundStartEditMenu)
    this.boundHandleAddSubItem = this.handleAddSubItem.bind(this)
    this.boundHandlePointerDown = this.handlePointerDown.bind(this)
    this.boundPreventNavigation = e => e.preventDefault()
    if (!this.items.length) {
      try {
        this.items = await (await fetch('/menu_items', {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': $("[name='csrf-token']").attr('content')
          }
        })).json()
      }
      catch (e) { }
    }

    [...this.element.querySelectorAll('#main-menu .sub-menu-item')]
      .forEach(this.addRemoveButton.bind(this))

      this.isTouch = window.matchMedia("(any-hover: none)").matches;
  }

  disconnect() {
    this.configButtonTarget.removeEventListener('click', this.boundStartEditMenu)
    this.stopEditMenu()
  }

  async startEditMenu() {
    this.element.classList.toggle('is-editing', this.isEditing = true)
    const mainMenuItemsEls = [...this.element.querySelectorAll('.menu-left-link:has(.menu-left-item)')]
    mainMenuItemsEls.forEach(itemEl => {
      const itemId = itemEl.getAttribute('itemid')
      const switchEl = this.createSwitch(itemId, this.items.find(item => item.id === itemId)?.visible !== false)
      switchEl.addEventListener('click', e => e.stopPropagation())
      itemEl.querySelector('.menu-left-item').append(switchEl)
    });

    [...this.element.querySelectorAll('[itemid]')].forEach(itemEl => {
      itemEl.addEventListener('click', this.boundPreventNavigation);
      itemEl.addEventListener('gotpointercapture', this.handleGotPointerCapture);
    })

    window.addEventListener('pointerdown', this.boundHandlePointerDown)

    const subMenuItems = [...this.element.querySelectorAll('.sub-menu-group--items .sub-menu-item')]
    subMenuItems.forEach(itemEl => {
      const btn = itemEl.appendChild(this.createButton(itemEl.getAttribute('itemid'), 'add'))
      btn.addEventListener('click', this.boundHandleAddSubItem)
    })

    if (this.isTouch)
      [...this.element.querySelectorAll('#main-menu [itemid]')].forEach(this.addDragIcon)
  }

  handlePointerDown(se) {
    const el = this.isTouch ? se.target.closest('#main-menu [itemid] .mdi-menu') : se.target.closest('#main-menu [itemid]')
    if (!el) return;

    el.classList.add('touched');
    window.addEventListener('contextmenu', handleContextMenu, { once: true });
    window.addEventListener('pointerup', handleStopDrag, { once: true });
    this.startDrag(se);

    function handleStopDrag() {
      window.removeEventListener('contextmenu', handleContextMenu, { once: true });
      el.classList.remove('touched');
    }

    function handleContextMenu(e) {
      e.preventDefault(); e.stopPropagation()
    }
  }

  startDrag(se) {
    se.preventDefault()

    const el = se.target.closest('#main-menu [itemid]')
    const spacerEl = document.createElement('div')
    spacerEl.style.height = el.offsetHeight + 'px'
    el.insertAdjacentElement('afterend', spacerEl)
    const startY = el.offsetTop
    el.style.top = startY + 'px'
    el.style.width = el.offsetWidth + 'px'
    el.classList.add('dragging')

    window.addEventListener('pointermove', handleMove)
    window.addEventListener('pointerup', () => {
      window.removeEventListener('pointermove', handleMove)
      el.style.top = ''
      el.style.width = ''
      el.classList.remove('dragging', 'touched')
      spacerEl.replaceWith(el)
      spacerEl.remove()
    }, { once: true })

    const itemsEls = [...this.element.querySelectorAll('#main-menu [itemid]')].filter(d => d !== el)
    function handleMove(me) {
      el.style.top = startY + me.clientY - se.clientY + 'px'
      for(const curEl of itemsEls) {
        const dst = el.offsetTop - curEl.offsetTop
        if (Math.abs(dst) < el.offsetHeight / 2) {
          curEl.insertAdjacentElement(dst < 0 ? 'afterend' : 'beforebegin', spacerEl)
          break
        }
      }
    }
  }

  handleGotPointerCapture(e) {
    e.target.releasePointerCapture(e.pointerId);
  }

  updateItems() {
    if (this.items.length) {
      const itemsElems = [...this.element.querySelectorAll('#main-menu .menu-left-link')]
      itemsElems.forEach(el => {
        el.classList.toggle('hidden-item', !(this.items.find(item => item.id === el.getAttribute('itemid'))?.visible ?? true))
      });

      const subItemsElems = [...this.element.querySelectorAll('.sub-menu-group--items .sub-menu-item')]
        .filter(subItemEl => {
          const itemId = subItemEl.getAttribute('itemid')
          return this.items.some(item => item.id === itemId)
        })
        .map(this.addSubItem.bind(this))

      const sortedMainMenuItems = itemsElems.concat(subItemsElems).sort((a, b) =>
        this.items.findIndex(item => item.id === a.getAttribute('itemid')) -
        this.items.findIndex(item => item.id === b.getAttribute('itemid'))
      );
      this.element.querySelector('#main-menu').replaceChildren(...sortedMainMenuItems);
    }
  }

  stopEditMenu() {
    this.element.classList.toggle('is-editing', this.isEditing = false);
    const switches = [...this.element.querySelectorAll('.menu-left-item>.custom-control.custom-switch')]
    switches.forEach(switchEl => switchEl.remove())

    const subItemsBtns = [...this.element.querySelectorAll('.sub-menu-group--items .btn')]
    subItemsBtns.forEach(subItemBtn => {
      subItemBtn.removeEventListener('click', this.boundHandleAddSubItem) // it only removes event listener for sub items in the not-selected list
      subItemBtn.remove()
    });

    [...this.element.querySelectorAll('[itemid]')].forEach(itemEl => {
      itemEl.removeEventListener('click', this.boundPreventNavigation)
      itemEl.removeEventListener('gotpointercapture', this.handleGotPointerCapture)
    })
    if (this.isTouch)
      [...this.element.querySelectorAll('#main-menu [itemid]')].forEach(el => {
        el.querySelector('.mdi-menu').remove()
      })

    window.removeEventListener('pointerdown', this.boundHandlePointerDown)

    this.updateItems()
  }

  async saveMenu() {
    const itemsElems = [...this.element.querySelectorAll('#main-menu [itemid]')]
    this.items = itemsElems.map(el => ({
      id: el.getAttribute('itemid'),
      visible: el.querySelector('input')?.checked/*main menu item*/ ?? true/*sub menu item always visible*/
    }))

    await fetch('/menu_items', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $("[name='csrf-token']").attr('content')
      },
      body: JSON.stringify({ menu_items: this.items })
    });
    this.stopEditMenu()
  }

  createSwitch(itemId, checked) {
    const switchEl = document.createElement('div')
    switchEl.classList.add('custom-control')
    switchEl.classList.add('custom-switch')
    const checkbox = document.createElement('input')
    checkbox.type = 'checkbox'
    checkbox.checked = checked
    checkbox.classList.add('custom-control-input')
    const label = document.createElement('label')
    label.classList.add('custom-control-label')
    checkbox.id = 'switch_' + itemId
    label.setAttribute('for', 'switch_' + itemId)
    switchEl.addEventListener('pointerdown', e => e.stopPropagation())
    switchEl.append(checkbox, label)

    return switchEl
  }

  createButton(itemId, type) {
    const btn = document.createElement('div')
    btn.classList.add('btn')
    btn.itemId = itemId
    const icon = document.createElement('span')
    btn.append(icon)
    icon.classList.add('mdi', type === 'add' ? 'mdi-plus' : 'mdi-minus')

    return btn;
  }

  addDragIcon(el) {
    const dragIcon = document.createElement('div')
    dragIcon.classList.add('mdi', 'mdi-menu')
    el.appendChild(dragIcon)
  }

  handleAddSubItem(e) {
    e.preventDefault();
    const origEl = this.addSubItem(e.target.closest('.sub-menu-item'))
    this.addDragIcon(origEl)
  }

  addSubItem(origEl) {
    const cloneEl = origEl.cloneNode(true)
    cloneEl.classList.remove('d-none')
    this.element.querySelector('#main-menu').append(cloneEl)
    cloneEl.querySelector('.btn')?.remove()
    this.addRemoveButton(cloneEl)
    origEl.classList.add('d-none')

    return cloneEl;
  }

  addRemoveButton(subitemEl) {
    const itemId = subitemEl.getAttribute('itemid');
    const removeButton = this.createButton(itemId, 'remove')
    removeButton.addEventListener('pointerdown', e => e.stopPropagation())
    removeButton.addEventListener('click', e => {
      e.stopPropagation()
      e.preventDefault()
      subitemEl.remove()

      this.element.querySelector('.settings-menu-left [itemid="' + itemId + '"]')
        ?.classList.remove('d-none')
    })
    subitemEl.querySelector('.btn')?.remove()
    subitemEl.append(removeButton)
  }
}
