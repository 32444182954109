import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  reload(event) {
    if( event.target.dataset.populateOnly ) {
      this.element.action += "?populate_only=true"
    }
    Rails.fire(this.element, 'submit')
  }

  toggleCompleted(event) {
    let $container = $(event.target.closest(".visit"))

    if(event.target.checked) {
      $container.addClass('completed-true')
    } else {
      $container.removeClass('completed-true')
    }
  }
}
