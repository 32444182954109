import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["links", "content"]
  static values = {
    url: String,
  }

  connect() {
    if( !this.hasLinksTarget ) return;

    $(this.linksTarget).on('click', 'a', this._linkClick.bind(this))
  }

  _linkClick(e){
    e.preventDefault();

    let href = this.urlValue
    href += href.includes('?') ? '&' : '?'
    href += e.target.href.split('?')[1] // without ?
    href += '&FILTER=1'

    for(let a of Array.from(e.target.parentNode.children)){
      a.classList.toggle('active', a == e.target)
    }

    fetch(href)
        .then(response => response.text())
        .then(html => {
          this.contentTarget.innerHTML = html
        })
  }

}
