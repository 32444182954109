import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import Dropzone from "dropzone"

Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = [ "input", "preview" ]

  connect() {
    this.dropZone = createDropZone(this)
    this.hideFileInput()
    this.bindEvents()
  }

// Private
  hideFileInput() {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      setTimeout(() => { file.accepted && createDirectUploadController(this, file).start() }, 500)

      let ext = file.name.split('.').pop();
      let img = ['png', 'jpg', 'jpeg', 'gif', 'tiff']
      if(img.indexOf(ext) == -1) {
        $(file.previewTemplate).find('.attachment-inner img').remove();
        $(file.previewTemplate).find('.attachment-inner').prepend("<span class='doc'><i class='mdi mdi-file-document'></i></span>");
      }
    })

    this.dropZone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput)
    })

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort()
    })

    this.dropZone.on("processing", (file) => {
      if(this.submitButton) {
        this.submitButton.disabled = true
      }
    })

    this.dropZone.on("queuecomplete", (file) => {
      if(this.submitButton) {
        this.submitButton.disabled = false
      }

    })
  }

  get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }

  get url() { return this.inputTarget.getAttribute("data-direct-upload-url") }

  get maxFiles() { return this.data.get("maxFiles") || 1 }

  get maxFileSize() { return this.data.get("maxFileSize") || 256 }

  get acceptedFiles() { return this.data.get("acceptedFiles") }

  get addRemoveLinks() { return this.data.get("addRemoveLinks") || false }

  get attachUrl() { return this.data.get("attachUrl") || false }

  get form() { return this.element.closest("form") }

  get submitButton() { return findElement(this.form, "input[type=submit], button[type=submit]") }

}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    // this.hiddenInput = this.createHiddenInput()
    this.directUpload.create((error, attributes) => {
      if (error) {
        // removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        // this.hiddenInput.value = attributes.signed_id
        this.attachFileToObject(attributes.signed_id, (error) => {
          if(error) {
            this.emitDropzoneError(error)
          } else {
            this.emitDropzoneSuccess()
          }
        })

      }
    })
  }

// Private
  // createHiddenInput() {
  //   const input = document.createElement("input")
  //   input.type = "hidden"
  //   input.name = this.source.inputTarget.name
  //   insertAfter(input, this.source.inputTarget)
  //   return input
  // }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = event.loaded / event.total * 100
    findElement(this.file.previewTemplate, ".dz-upload").style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }

  // attach file to job, customer, ...
  attachFileToObject(signed_id, callback) {
    var self = this
    $.post(this.source.attachUrl, { signed_id: signed_id })
      .done((data)=>{
        $(this.file.previewTemplate).replaceWith(data)
        self.source.element.dispatchEvent(new Event('fileAdded', {bubbles: true}))
        callback();
      })
      .fail((data) => {
        let errorText = 'Something went wrong! File is not saved'
        if( data.status === 422  ){ errorText = data.responseText }
        callback(errorText)
      });
  }
}

// Helpers

function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`)
  if (element) {
    return element.getAttribute("content")
  }
}

function findElement(root, selector) {
  if(!root) return null

  if (typeof root == "string") {
    selector = root
    root = document
  }
  return root.querySelector(selector)
}

function toArray(value) {
  if (Array.isArray(value)) {
    return value
  } else if (Array.from) {
    return Array.from(value)
  } else {
    return [].slice.call(value)
  }
}

function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

// Top level...
function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    previewTemplate: dropZonePreviewContainer(),
    previewsContainer: controller.previewTarget
  })
}

function dropZonePreviewContainer() {
  return `
    <div class="dz-preview dz-file-preview attachment">
      <div class="attachment-inner">
        <img data-dz-thumbnail />
        <span class="file-name" data-dz-name></span>
        <div class="dz-progress">
          <span class="dz-upload" data-dz-uploadprogress></span>
        </div>
        <div class="dz-error-message">
          <span data-dz-errormessage></span>
        </div>
        <a class="dz-remove btn btn-icon btn-remove" href="javascript:undefined;" data-dz-remove=""><i class="mdi mdi-close"></i></a>
      </div>
    </div>
  `;
}
