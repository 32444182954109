import { Controller } from "@hotwired/stimulus"

let easyAutocompleteModule = undefined;

export default class extends Controller {
  static targets = ["input"]

  initialize() {
    this.url = this.data.get('url')
  }

  connect() {
    this.$element = $(this.element)
    this.$input = $(this.inputTarget)
  }

  searchableIcon(item) {
    let icon = null;

    switch(item.searchable_type) {
      case 'Customer':
        icon = `account-supervisor`
        break;
      case 'Appointment':
        icon = `calendar-check`
        break;
      case 'Job':
        icon = `briefcase-check`
        break;
      case 'Estimate':
        icon = `table-clock`
        break;
      case 'ClassSession':
        icon = `google-classroom`
        break;
      case 'CustomerInvoice':
        icon = `receipt`
        break;
      case 'Event':
        icon = `calendar-today`
        break;
      case 'Task':
        icon = `format-list-checks`
        break;
      case 'User':
        icon = `account-tie`
        break;
      case 'Service':
        icon = `hammer-wrench`
        break;
      case 'Product':
        icon = `package-variant`
        break;
    }

    return `<i class='mdi mdi-${icon}'></i>`;
  }

  chooseSearchItem() {
    let url = null;
    let item = this.$input.getSelectedItemData()

    switch(item.searchable_type) {
      case 'Customer':
        url = `/customers/${item.searchable_id}`
        break;
      case 'Appointment':
        url = `/appointments/${item.searchable_id}`
        break;
      case 'Job':
        url = `/jobs/${item.searchable_id}`
        break;
      case 'Estimate':
        url = `/estimates/${item.searchable_id}`
        break;
      case 'ClassSession':
        url = `/class_sessions/${item.searchable_id}`
        break;
      case 'CustomerInvoice':
        url = `/invoices/${item.searchable_id}`
        break;
      case 'Event':
        RemoteModal.show('event-form', `/events/${item.searchable_id}/edit`);
        break;
      case 'Task':
        RemoteModal.show('task-form', `/tasks/${item.searchable_id}/edit`);
        break;
      case 'User':
        url = `/employees/${item.searchable_id}`
        break;
      case 'PriceListItem':
        RemoteModal.show('service-form', `/price_list_items/${item.searchable_id}/edit`);
        break;
      case 'Product':
        RemoteModal.show('product-form', `/products/${item.searchable_id}/edit`);
        break;
    }

    if(url) Turbo.visit(url);
  }

  preInit(){
    easyAutocompleteModule ||= import('../app/components/jquery.easy-autocomplete');
  }

  showSearch(e) {
    easyAutocompleteModule ||= import('../app/components/jquery.easy-autocomplete');

    var self = this

    let options = {
      url: function(query) {
        return `${self.url}?q=${query}`
      },
      getValue: 'title',
      highlightPhrase: false,
      template: {
        type: "custom",
        method: (value, item) => {
          return this.searchableIcon(item) + "  " + value.replace(/</g, "&lt;").replace(/>/g, "&gt;");
        }
      },

      list: {
        onChooseEvent: this.chooseSearchItem.bind(this)
	    }
    }

    this.$input.toggleClass('d-none')

    easyAutocompleteModule.then(() => {
      self.$input.easyAutocomplete(options);
      self.$input.focus();
    })
  }

}
