//using to fill header-secondary list of buttons and rest of them move to dropdown in "More" button based on available space
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    count: { type: Number, default: 5 }
  }

  connect() {
    this.boundLayoutButtons = this.layoutButtons.bind(this);
    window.addEventListener('resize', this.boundLayoutButtons);
    this.layoutButtons();
  }

  disconnect() {
    window.removeEventListener('resize', this.boundLayoutButtons);
  }

  layoutButtons() {
    const headerTitle = this.element.querySelector('.header-title');
    const headerActions = this.element.querySelector('.header-actions');

    if (!headerTitle || !headerActions) return;

    const buttons = headerActions.querySelectorAll(':scope > .btn-action');
    const leftOffset = headerTitle.getBoundingClientRect().right - this.element.getBoundingClientRect().left;
    const headerWidth = window.innerWidth - this.element.getBoundingClientRect().left;
    const allButtonsCount = Math.floor((headerWidth - leftOffset) / 120);
    const maxVisibleButtonsCount = Math.min(this.countValue, allButtonsCount >= buttons.length ? allButtonsCount : allButtonsCount - 1);
    const dropdownItems = this.element.querySelectorAll('.dropdown-menu .dropdown-item');
    const more = this.element.querySelector('.dropdown');
    buttons.forEach((b, i) => b.style.display = i < maxVisibleButtonsCount ? '' : 'none');
    dropdownItems.forEach((it, i) => it.style.display = i >= maxVisibleButtonsCount ? '' : 'none');
    more.style.display = dropdownItems.length > maxVisibleButtonsCount ? '' : 'none';
  }
}