import {Controller} from "@hotwired/stimulus"

// set value of .google-map-route-arrive-time - instead of target
// remove .d-none from .google-map-route-visible-on-route - instead of target
export default class extends Controller {
  static values = {
    lat: Number, // customer coords
    lng: Number,
    visitorLat: Number, // worker coords
    visitorLng: Number,
  }

  connect() {
    if(document.documentElement.hasAttribute("data-turbo-preview")) return

    if(typeof (google) != "undefined") this.initMap()
  }

  initMap() {
    if( this.map ) return;

    let lat = this.latValue
    let lng = this.lngValue

    let mapOptions = {
      center: {lat: lat, lng: lng},
      zoom: 15,
      disableDefaultUI: true
    }

    this.map = new google.maps.Map(this.element, mapOptions)

    const homeSvgIcon = '<svg viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><defs><circle id="a" cx="20" cy="20" r="20"/><filter id="b" x="-25%" y="-20%" width="150%" height="150%"><feOffset dx="0" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/><feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3"/><feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0.192156863   0 0 0 0 0.215686275   0 0 0 0 0.239215686  0 0 0 0.4 0"/></filter></defs><g fill="none" fill-rule="evenodd"><g transform="translate(8 8)"><use fill="black" filter="url(#b)" xlink:href="#a"/><use fill="#31373D" fill-rule="evenodd" xlink:href="#a"/><path d="m20 20.179c-1.4793 0-2.6786-1.1992-2.6786-2.6786 0-0.7104 0.28221-1.3917 0.78454-1.894s1.1836-0.78454 1.894-0.78454c1.4793 0 2.6786 1.1992 2.6786 2.6786 0 0.7104-0.28221 1.3917-0.78454 1.894s-1.1836 0.78454-1.894 0.78454m0-10.179c-4.1421 0-7.5 3.3579-7.5 7.5 0 5.625 7.5 13.929 7.5 13.929s7.5-8.3036 7.5-13.929c0-4.1421-3.3579-7.5-7.5-7.5z" fill="#fff" fill-rule="nonzero"/></g></g></svg>';
    const homeSvgSize = 56
    new google.maps.Marker({
      position: mapOptions.center,
      map: this.map,
      icon: { // https://developers.google.com/maps/documentation/javascript/examples/icon-complex
        anchor: new google.maps.Point(homeSvgSize / 2, homeSvgSize / 1.5),
        url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(homeSvgIcon),
        scaledSize: new google.maps.Size(homeSvgSize, homeSvgSize)
      }
    })

    if(this.visitorLatValue){
      this._drawRoute({lat: this.visitorLatValue, lng: this.visitorLngValue})
    }
  }

  // visitor location is updated, and event.details is new coords
  // handle event from direct_entry_channel_controller.js
  move(event) {
    const precision = 0.001
    if( Math.abs((this.visitorLatValue || 0) - event.detail.lat) >= precision || Math.abs((this.visitorLngValue || 0) - event.detail.lng) >= precision ){
      this.visitorLatValue = event.detail.lat
      this.visitorLngValue = event.detail.lng
      this._drawRoute(event.detail)
    }
  }

  _drawRoute(coord) {
    if(!this.map) return;

    this.directionsRenderer ||= new google.maps.DirectionsRenderer({suppressMarkers: true, map: this.map});
    this.directionsService ||= new google.maps.DirectionsService();

    const self = this;

    this.directionsService
        .route({
          origin: coord,
          destination: {lat: this.latValue, lng: this.lngValue},
          travelMode: google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          self.directionsRenderer.setDirections(response);
          let leg = null;
          if(response.status === google.maps.DirectionsStatus.OK && (leg = response?.routes[0]?.legs[0])){
            self._setWorkerMarker(leg.start_location)
            self._setArriveTime(leg.duration.text)
            self._showHidden()
          }
        }).catch((e) => console.error("Directions request failed"));
    // direction custom color and markers
    // https://developers.google.com/maps/documentation/javascript/examples/directions-complex
    // http://www.geocodezip.com/v3_directions_custom_iconsC.html
  }

  _setWorkerMarker(position) {
    if(this.workerMarker){
      this.workerMarker.setPosition(position)
      return
    }
    const workerSvgIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="44" height="44"><defs><filter x="-37.5%" y="-37.5%" width="175.0%" height="175.0%" id="A"><feOffset dy="2" in="SourceAlpha"/><feGaussianBlur stdDeviation="2"/><feColorMatrix values="0 0 0 0 0.192156863   0 0 0 0 0.215686275   0 0 0 0 0.239215686  0 0 0 0.3 0" result="C"/><feMerge><feMergeNode in="C"/><feMergeNode in="SourceGraphic"/></feMerge></filter><linearGradient x1="100%" y1="-18.26701%" x2="0%" y2="80.7861144%" id="B"><stop stop-color="#5bb5d0" offset="0%"/><stop stop-color="#509cb3" offset="100%"/></linearGradient></defs><g filter="url(#A)" transform="translate(6 6)" fill-rule="evenodd"><circle stroke="#509cb3" stroke-width="2" fill-opacity=".9" fill="url(#B)" cx="16" cy="16" r="15"/><path d="M8.584 17.625L8.016 16.5h3.979l-.455-1.125H7.826l-.568-1.125h5.912l-.455-1.125H7.152L6.5 12h2.842c0-.828.679-1.5 1.516-1.5h9.095v3h2.274l2.274 3v3.75h-1.516c0 1.243-1.018 2.25-2.274 2.25s-2.274-1.007-2.274-2.25h-3.032c0 1.243-1.018 2.25-2.274 2.25s-2.274-1.007-2.274-2.25H9.342v-2.625h-.758m12.126 3.75a1.13 1.13 0 0 0 1.137-1.125 1.13 1.13 0 0 0-1.137-1.125 1.13 1.13 0 0 0-1.137 1.125 1.13 1.13 0 0 0 1.137 1.125m1.137-6.75h-1.895V16.5h3.38l-1.485-1.875m-8.716 6.75a1.13 1.13 0 0 0 1.137-1.125 1.13 1.13 0 0 0-1.137-1.125 1.13 1.13 0 0 0-1.137 1.125 1.13 1.13 0 0 0 1.137 1.125z" fill="#fff" fill-rule="nonzero"/></g></svg>';
    const workerSvgSize = 44

    this.workerMarker = new google.maps.Marker({
      position: position,
      map: this.map,
      icon: {
        url: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(workerSvgIcon),
        anchor: new google.maps.Point(workerSvgSize / 2, workerSvgSize / 1.5),
        // scaledSize: new google.maps.Size(workerSvgSize, workerSvgSize)
      }
    })
  }

  _setArriveTime(timeText){
    for(let el of document.querySelectorAll('.google-map-route-arrive-time')){
      el.innerText = `Arrives in ${timeText}`
    }
  }
  _showHidden(){
    for(let el of document.querySelectorAll('.google-map-route-visible-on-route')){
      el.classList.remove('d-none')
    }
  }

}
