import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// query url with q param from searchInput
// render result in the results target
export default class extends Controller {
  static values = {
    url: String,
    method: { type: String, default: 'GET' }
  }
  static targets = ["results", "searchInput"]
  
  connect() {
    $(this.searchInputTarget).on('keyup', this.inputEvent.bind(this))
    $(this.searchInputTarget).on('keydown', (event)=>{if(event.keyCode == 13) event.preventDefault()})
  }
  
  inputEvent(event) {
    if(event.keyCode == 13) {
      event.preventDefault()
      this.debounce(300, this.query.bind(this))
      return false
    } else {
      this.debounce(300, this.query.bind(this))
    }
  }

  debounce(delay, callback) {
    if(this.abortController) {
      this.abortController.abort("debounced");
    }
    if(this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(callback, delay);
  }

  async readResponse(response){
    let content = await response.text()
    this.resultsTarget.innerHTML = content
  }
  
  query() {
    self = this
    const queryBody = this.queryData()
    this.abortController = new AbortController();
    fetch(this.queryUrl(queryBody), {
      method: 'POST',
      body: queryBody,
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      signal: this.abortController.signal
    }).catch(error => {
      if (error != 'debounced') {
        this.resultsTarget.innerHTML = "<div class='error'>Something went wrong, try again.</div>"
      }
    }).then(response => {
        this.abortController = null
        if(response && response.ok) {
          self.readResponse(response)
        }
      }
    )
  }

  queryData() {
    let data = new FormData()
    data.set('_method', this.methodValue)
    data.set('q', this.searchInputTarget.value)
    return data
  }

  queryUrl(data) {
    let url = this.urlValue;
    url += url.includes('?') ? '&' : '?'
    url += new URLSearchParams(data).toString()
    return url
  }
}
