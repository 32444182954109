import { Controller } from "@hotwired/stimulus"

const RADIUS = 54;
const CIRCUMFERENCE = 2 * Math.PI * RADIUS;

// https://css-tricks.com/building-progress-ring-quickly/
// https://codepen.io/xgad/post/svg-radial-progress-meters
export default class extends Controller {
  static targets = ["value"]
  static values = {
    value: Number,
  }

  connect() {
    if( this.element.querySelector('.progress-circle--progress') ) return;

    this.element.insertAdjacentHTML("afterBegin", '<svg class="progress-circle--progress" style="width: 100%; height: 100%;" width="120" height="120" viewBox="0 0 120 120">' +
        '<circle class="progress-circle--meter" cx="60" cy="60" r="54" stroke-width="3"></circle>' +
        '<circle data-progress-circle-target="value" class="progress-circle--value" cx="60" cy="60" r="54" stroke-width="3"></circle>' +
        '</svg>');
    this.valueTarget.style.strokeDasharray = CIRCUMFERENCE;
    this.update(this.valueValue);
  }

  update(value) {
    let progress = value / 100;
    let dashoffset = CIRCUMFERENCE * (1 - progress);
    this.valueTarget.style.strokeDashoffset = dashoffset;
  }
}
