import {Controller} from "@hotwired/stimulus"
import {isSupported, getToken} from "firebase/messaging";
import {getFirebaseMessaging} from '../app/components/firebase/messaging';

// Ask user for permission about browser notification
// Get token from firebase and save it
export default class extends Controller {
  // controls to show/hide depending is browser support web push
  static targets = ["supported", "disable", "notSupported"]

  static values = {
    vapidKey: String, // https://stackoverflow.com/questions/54996206/firebase-cloud-messaging-where-to-find-public-vapid-key
  }

  connect() {
    (async () => {
      if( await isSupported() ){
        let deviceId = localStorage.getItem('ntf-device-id')
        if ( deviceId )
          this.disableTarget.classList.remove('d-none')
        else
          this.supportedTarget.classList.remove('d-none')
      } else {
        this.notSupportedTarget.classList.remove('d-none')
      }
    })();
  }

  enable() {
    Notification.requestPermission().then((permission) => {
      if(permission === "granted"){
        this.fetchToken()
      }
    });
  }

  disable() {
    let deviceId = localStorage.getItem('ntf-device-id')
    $.post(`/devices/${deviceId}`, {_method: 'delete'}).done(()=>{
      localStorage.removeItem('ntf-device-id')
      Turbo.visit(location.toString())
    })
  }

  fetchToken() {
    getToken(getFirebaseMessaging(), {vapidKey: this.vapidKeyValue}).then((currentToken) => {
      if(currentToken){
        $.post('/devices', {token: currentToken}).done(()=>{
          localStorage.setItem('ntf-device-id', currentToken)
          Turbo.visit(location.toString())
        })
      } else {
        // Show permission request UI
      }
    }).catch((err) => {
      console.error('An error occurred while retrieving token. ', err);
    });
  }
}
