import Inputmask from "inputmask";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let $element = $(this.element)

    let mask_options = {
      autoUnmask: true,
      removeMaskOnSubmit: true
    }

    let $number = $element.find('.cc-number')
    Inputmask('9999 9999 9999 9999', mask_options).mask($number)

    let $cvc = $element.find('.cc-cvc')
    Inputmask('9999', mask_options).mask($cvc)
  }
}
