import {Controller} from "@hotwired/stimulus"

// replace `target` with result of ajax
export default class extends Controller {
  static targets = ["target"]

  replace(event) {
    $.get(
        this.element.dataset.replaceOnInputUrlValue,
        {value: event.target.value}
    ).done((html) => {
      this.targetTarget.innerHTML = html;
    })
  }

}
