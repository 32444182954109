import {Controller} from "@hotwired/stimulus"
import Quill from 'quill';

let Embed = Quill.import('blots/embed');

var gTemplateVarsCache = {};

class FieldBlot extends Embed {
  static create(value) {
    let node = super.create();
    node.setAttribute('class', 'field-control');
    node.setAttribute('data-table', value.table);
    node.setAttribute('data-name', value.name);
    node.setAttribute('data-label', value.label);
    node.innerHTML = value.label;

    return node;
  }

  static value(node) {
    return {
      table: node.getAttribute('data-table'),
      name: node.getAttribute('data-name'),
      label: node.getAttribute('data-label')
    };
  }

  // this fix allows to insert a span tag
  static formats() {
    return true;
  }
}

FieldBlot.blotName = 'Field';
FieldBlot.tagName = 'span';
FieldBlot.className = 'field-control';
Quill.register(FieldBlot);

export default class extends Controller {
  static targets = ["subject", "subjectEditor", "body", "bodyEditor", "toolbar", "fieldsDropdown", 'preview']
  static values = {
    mode: String,
  }

  connect() {
    if(this.hasSubjectTarget){
      this.connectSubject()
    }
    this.connectBody()
    if(this.hasPreviewTarget){
      this.connectPreview()
      this.updatePreview()
    }

    $(this.bodyTarget.form).bind('submit', this._onSave.bind(this))
  }

  connectSubject() {
    this.subjectEditor = new Quill(this.subjectEditorTarget, {
      modules: {
        keyboard: {
          bindings: {
            enter: {
              key: 13, // disable an enter key
              handler: function () {
                return false;
              }
            }
          }
        }
      }
    });

    this.subjectEditor.on('editor-change', () => this.lastEditor = this.subjectEditor);
    $(this.subjectEditorTarget).on('click', () => this.lastEditor = this.subjectEditor);
  }

  connectBody() {
    this.bodyEditor = new Quill(this.bodyEditorTarget, {
      theme: 'snow',
      modules: {
        toolbar: this.toolbarTarget,
      }
    });

    this.bodyEditor.on('editor-change', () => this.lastEditor = this.bodyEditor);

    this.lastEditor = this.bodyEditor;


    const fieldDropdown$ = $(this.fieldsDropdownTarget);

    fieldDropdown$.find('.field-item').on('click', this._onFieldSelect.bind(this))
    fieldDropdown$.on('hide.bs.dropdown', ev => !(ev.clickEvent && $(ev.target).has(ev.clickEvent.target).length))

    // insert select here after editor created, since if Quill find select tag, it replace it with one
    const selectHtml = fieldDropdown$.find('.select-fields-class > template').html()
    fieldDropdown$.find('.select-fields-class').html(selectHtml)

    fieldDropdown$.find('.select-fields-class').find('select').on('change', function () {
      let value = $(this).val();
      fieldDropdown$.find('.field-items').removeClass('active');
      fieldDropdown$.find(`.field-items[data-group="${value}"]`).addClass('active');
    });
  }

  connectPreview() {
    this.bodyEditor.on('text-change', () => {
      this.updatePreview()
      $(this.bodyTarget.form).trigger('change')
    })
    if(this.hasSubjectTarget) {
      this.subjectEditor.on('text-change', () => {
        this.updatePreview()
        $(this.subjectTarget.form).trigger('change')
      })
    }
  }

  _onSave() {
    if(this.hasSubjectTarget){
      this.subjectTarget.value = this.subjectEditor.root.innerHTML;
    }
    this.bodyTarget.value = this.bodyEditor.root.innerHTML;
  }

  _onFieldSelect(ev) {
    ev.preventDefault();

    let value = {
      table: $(ev.target).data('table'),
      name: $(ev.target).data('name'),
      label: $(ev.target).data('label')
    };

    let range = this.lastEditor.getSelection(true);

    this.lastEditor.insertEmbed(range.index, 'Field', value);
    //Take the cursor to the end of the inserted field
    this.lastEditor.setSelection(range.index + 1, 0);
    this.lastEditor.insertText(range.index + 1, ' ');

    $(this.fieldsDropdownTarget).find('.dropdown-menu').removeClass('show');
  }

  updatePreview() {
    var html = this.bodyEditor.root.innerHTML
    if(this.hasSubjectTarget){
      html = this.subjectEditor.root.innerHTML + "<hr>" + html
    }
    this.previewTarget.innerHTML = html

    if(!this.loadVars()){
      return
    }

    for (const field of this.previewTarget.querySelectorAll('span.field-control')) {
      const set = gTemplateVarsCache[this.modeValue][field.dataset.table]
      if(set){
        field.outerHTML = set[field.dataset.name]
      }
    }

  }

  loadVars() {
    const mode = this.modeValue;
    if(gTemplateVarsCache[mode] == 'loading'){
      return // wait till load
    } else if(gTemplateVarsCache[mode] == null){
      gTemplateVarsCache[mode] = 'loading';
      $.ajax('/text_templates/preview_vars', {
        data: {mode: mode}
      }).done((json, status, xhr) => {
        gTemplateVarsCache[mode] = json;
        this.updatePreview();
      }).fail(() => {gTemplateVarsCache[mode] = null})
      return
    }
    return true;
  }

  reset(ev) {
    ev.preventDefault();
    ev.target.classList.add('disabled')
    $.ajax('/text_templates/defaults', {
      data: {id: this.element.dataset.templateId}
    }).done((json, status, xhr) => {
      if(this.hasSubjectTarget){
        this.subjectEditor.root.innerHTML = json.subject;
      }
      this.bodyEditor.root.innerHTML = json.body;
    }).always(() => ev.target.classList.remove('disabled'))
  }

}
