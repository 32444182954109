import {Controller} from "@hotwired/stimulus"

// data-chart-data="[[date,count]]"
// data-chart-title="optional"
export default class extends Controller {
  async connect() {
    const {Highcharts} = await import("../../imports/highcharts")

    const data = JSON.parse(this.element.dataset.chartData)
    const title = this.element.dataset.chartTitle

    const time = new Highcharts.Time()
    const dataCategories = data.map(x => time.dateFormat("%b %e", new Date(x[0])))
    const dataCounts = data.map(x => x[1])

    Highcharts.chart(this.element, {
      chart: {
        type: 'column',
        height: 200
      },
      title: {
        text: title,
        align: 'left',
        style: {fontSize: '14px'}
      },
      xAxis: [{
        categories: dataCategories
      }],
      yAxis: {
        allowDecimals: false,
        title: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Count',
        data: dataCounts,
        color: '#BBE1ED'
      }]
    });
  }
}
