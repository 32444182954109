import { Controller } from "@hotwired/stimulus"

// form line can be removed(hide) and added back
// boolean hidden field (visible,enabled) will be set to false or true
export default class extends Controller {
  static targets = [ "field", "line", "add" ]

  connect(){
    this.display()
  }

  remove(event){
    event.preventDefault();
    this.fieldTarget.value = 'false';
    this.display()
  }

  add(event){
    event.preventDefault();
    this.fieldTarget.value = 'true';
    this.display()
  }

  display(){
    if(this.fieldTarget.value == 'true') {
      this.lineTarget.classList.remove('d-none')
      this.addTarget.classList.add('d-none')
    } else {
      this.lineTarget.classList.add('d-none')
      this.addTarget.classList.remove('d-none')
    }
  }

}
