import Rails from '@rails/ujs';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]

  initialize() {
    this.form = $(this.element).closest('form')[0]
    this.input = $(this.element).find('input')
    this.originFieldName = this.fieldTarget.getAttribute('name')
  }

  connect() {
    if(this.input.val() == "") {
      $(this.element).find('.input-icon-addon .mdi').attr('class', 'mdi mdi-magnify')
      this.fieldTarget.setAttribute('name', '')
    } else {
      $(this.element).find('.input-icon-addon .mdi').attr('class', 'mdi mdi-magnify-close')
      this.fieldTarget.setAttribute('name', this.originFieldName)
    }

    $(this.element).find('.input-icon-addon .mdi-magnify-close').on('click', () => {
      this.input.val('');
      this.fieldTarget.setAttribute('name', '')
      Rails.fire(this.form, 'submit');
    })
  }

  change() {
    this.fieldTarget.setAttribute('name', this.originFieldName)
    Rails.fire(this.form, 'submit');
  }
}
