require.context('../images', true)

import Rails from "@rails/ujs"

import "@hotwired/turbo-rails";
import './../app/components/turbo_actions';
import "controllers"

import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import '../app'

