import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["submitBtn",
                    "confirmationCode", "confirmationCodePart",
                    "countdownTimer", "resendAction",
                    "updatePhoneNumberFlag",
                    "changePhoneNumberForm"
                   ]

  connect() {
    this.partsCount = this.confirmationCodePartTargets.length
    this.submitBtnTarget.disabled = true
    this.interruptCountdownTimer = false

    this.element.addEventListener('paste', this.__clipboardPasteHandler.bind(this))

    this.confirmationCodePartTargets.forEach((el, index) => {
      let previousEl = index != 0 ? this.confirmationCodePartTargets[index - 1] : null
      let isLastEl = index == this.partsCount - 1
      let nextEl = isLastEl ? null : this.confirmationCodePartTargets[index + 1]
      let submitBtn = this.submitBtnTarget

      el.addEventListener('keydown', function(event) {
        if( event.ctrlKey || event.metaKey ) {
          return false
        } else if(event.key == "Backspace") {
          el.value = ''
          if(isLastEl) submitBtn.disabled = true
          if(previousEl) previousEl.focus()
        } else {
          if(isLastEl) submitBtn.disabled = false

          if(isLastEl && el.value != '') {
            return true
          } else if (event.keyCode > 47 && event.keyCode < 58) { // numbers
            el.value = event.key
            if(nextEl) nextEl.focus()
            event.preventDefault()
          } else if (event.keyCode > 64 && event.keyCode < 91) { // letters
            el.value = String.fromCharCode(event.keyCode)
            if(nextEl) nextEl.focus()
            event.preventDefault()
          }
        }
      })
      el.addEventListener('keyup', this.__collectCodeParts.bind(this))
    })

    this.__countdown(this.data.get('resendInterval'))
  }

  showPhoneNumberForm(e) {
    e.preventDefault()

    let action = $(e.target)

    this.interruptCountdownTimer = true

    // hide countdown, controls and show phone number input
    action.addClass('d-none')
    $(this.resendActionTarget).addClass('d-none')
    $(this.countdownTimerTarget).addClass('d-none')
    $(this.changePhoneNumberFormTarget).removeClass('d-none')

    // set hidden value to update phone number instead of checking code and enable submit button
    this.updatePhoneNumberFlagTarget.value = "yes"
    this.submitBtnTarget.value = "Send Code"
    this.submitBtnTarget.disabled = false
  }

  __clipboardPasteHandler(event) {
    event.preventDefault()

    let code = (event.clipboardData || window.clipboardData).getData('text')

    if(code.length != 6) return

    let codeParts = code.split('')

    this.confirmationCodePartTargets.forEach((el, index) => {
      el.value = codeParts[index]
    })

    this.confirmationCodeTarget.value = code
    this.submitBtnTarget.disabled = false
  }

  __collectCodeParts() {
    let code = this.confirmationCodePartTargets.map(el => el.value).join('')
    this.confirmationCodeTarget.value = code
  }

  __countdown(remaining) {
    if(this.interruptCountdownTimer) return

    let minutes = Math.floor(remaining / 60)
    let seconds = remaining % 60

    minutes = minutes < 10 ? "0" + minutes : minutes
    seconds = seconds < 10 ? "0" + seconds : seconds

    this.countdownTimerTarget.innerHTML = `Resend: (${minutes}:${seconds})`

    remaining -= 1
    if(remaining >= 0) {
      setTimeout(function () {
        this.__countdown(remaining)
      }.bind(this), 1000)
    } else {
      $(this.countdownTimerTarget).addClass('d-none')
      $(this.resendActionTarget).removeClass('d-none')
    }
  }
}
