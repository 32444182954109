import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    content: String,
    lessText: { type: String, default: "less" },
    moreText: { type: String, default: "more" }
   }
  static targets = ["content", "button"]

  initialize() {
    this.showMore = false;

  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();

    // for card expand/collapse animation returns text height
    const updateHeight = () => this.contentTarget.parentNode.style.height = this.contentTarget.offsetHeight + 'px'

    updateHeight()

    this.showMore = !this.showMore;

    let text = this.contentTarget.innerText
    this.contentTarget.innerText = this.contentValue
    this.contentValue = text

    updateHeight()

    this.buttonTarget.innerText = this.showMore ? this.lessTextValue : this.moreTextValue;

    return false
  }
}
