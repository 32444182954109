import { Controller } from "@hotwired/stimulus"

// controller fits image to parent frame.
// Image is hidden in html until its layout is calculated
// to prevent displaying it being incorrectly positioned.
export default class extends Controller {
  static targets = [ "image" ]

  connect() {
    if (!this.hasImageTarget) return;
    this.fitImage()
    this.boundFitImage = this.fitImage.bind(this)
    window.addEventListener('resize', this.boundFitImage)
  }

  disconnect() {
    if (!this.hasImageTarget) return;
    window.removeEventListener('resize', this.boundFitImage)
  }

  fitImage() {
    const parent = this.element
    const img = new Image()
    img.src = this.imageTarget.src
    img.onload = () => {
      const { width: pw, height: ph } = parent.getBoundingClientRect()
      this.imageTarget.classList.toggle('vertical', pw / ph > img.width / img.height)
      this.imageTarget.classList.remove('d-none')
    };
  }
}