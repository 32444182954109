import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["street", "unit", "city", "state", "zip", "country", "lat", "lng", "map", "coord"]

  initialize() {
    this.mapOptions = {
      center: {lat: 0, lng: 0},
      zoom: 0
    };

    if(this.coordTarget.dataset.lat && this.coordTarget.dataset.lng) {
      this.mapOptions.center.lat = parseFloat(this.coordTarget.dataset.lat);
      this.mapOptions.center.lng = parseFloat(this.coordTarget.dataset.lng);
      this.mapOptions.zoom = 10;
    }
  }

  connect() {
    if (typeof(google) != "undefined") this.initMap()
  }

  initMap() {
    this.map = new google.maps.Map(this.mapTarget, this.mapOptions);
    this.marker = new google.maps.Marker({map: this.map});

    if(this.coordTarget.dataset.lat) {
      this.marker.setPosition(this.mapOptions.center)
    }

    this.autocomplete = new google.maps.places.Autocomplete(this.streetTarget);
    this.autocomplete.setComponentRestrictions({ country: ["us", "ca"] });
    this.autocomplete.addListener('place_changed', this.__onPlaceChanged.bind(this));

    // prevent autocomplete for the address field. Override autocomplete='off'.
    setTimeout(() => {
      this.streetTarget.setAttribute('autocomplete', 'new-password')
    }, 300);
  }

  checkAsBilling(e) {
    document.querySelectorAll('.address-form-billing').forEach((item,_) => { item.checked = false })
    e.target.checked = true
  }

  __onPlaceChanged() {
    let place = this.autocomplete.getPlace();

    if (place.geometry) {
      this.map.panTo(place.geometry.location);
      this.map.setZoom(10);

      this.marker.setPosition( place.geometry.location );

      this.streetTarget.value = ""
      this.unitTarget.value = ""
      this.cityTarget.value = ""
      this.stateTarget.value = ""
      this.zipTarget.value = ""
      this.countryTarget.value = null

      this.latTarget.value = place.geometry.location.lat()
      this.lngTarget.value = place.geometry.location.lng()

      place.address_components.forEach((el, index) => {
        // state
        if(el.types.includes("administrative_area_level_1")) {
          this.stateTarget.value = el.long_name
        } // city
        else if(el.types.includes("locality") || el.types.includes("sublocality")) {
          this.cityTarget.value = el.long_name;
        } // zip
        else if(el.types.includes("postal_code")) {
          this.zipTarget.value = el.long_name;
        }
        else if(el.types.includes("country")) {
          this.countryTarget.value = el.short_name;
        }
      });

      // set street
      let result = place.adr_address.match(/<span class="street-address">(.*?)<\/span>/)
      if(result) this.streetTarget.value = result[1]
    }
  }
}
