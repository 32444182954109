class ProgressBar {

  show() {
    this._clearTimeout()
    Turbo.navigator.adapter.progressBar.setValue(0);
    this.timeout = window.setTimeout(showProgressBar, Turbo.session.progressBarDelay)
  }

  hide() {
    this._clearTimeout()
    Turbo.navigator.adapter.progressBar.hide();
    Turbo.navigator.adapter.progressBar.setValue(100);
  }

  _clearTimeout(){
    if(this.timeout){ // if we call .hide() before delay is over
      window.clearTimeout(this.timeout)
      this.timeout = null;
    }
  }

}

function showProgressBar(){
  Turbo.navigator.adapter.progressBar.show()
}

export default ProgressBar;
