import { Controller } from "@hotwired/stimulus"

// disable input on some select value
export default class extends Controller {
  static targets = [ "disabled" ]

  update(event) {
    const values = this.data.get('values').split(",")
    const disabled = values.indexOf(event.target.value) >= 0
    this.disabledTarget.disabled = disabled;
  }

}
