import {Controller} from "@hotwired/stimulus"
// import Sortable from 'sortablejs/modular/sortable.core.esm.js';
import Sortable from 'sortablejs';

// save item by [data-id], pass to url as param[:order] = [1,2,3] if [data-sortable-url] is set
// set [value] of .sortable-position hidden fields to position value
export default class extends Controller {

  static targets = ['itemsContainer']

  connect() {
    this.$element = this.hasItemsContainerTarget ? this.itemsContainerTarget : this.element;
    const isTouch = window.matchMedia("(any-hover: none)").matches;

    Sortable.create(this.$element, {
      animation: 250,
      handle: isTouch ? '.drag-handle' : undefined,
      store: {
        set: this._save.bind(this)
      }
    })
  }

  disconnect() {
    Sortable.get(this.$element).destroy()
  }

  sort() {
    this._save(this.$element)
  }

  _save(sortable) {
    $(this.$element).find('.sortable-position').each((i, el) => el.value = i)

    const url = this.data.get('url')
    if(url){
      $.ajax({
        url: url,
        type: "PATCH",
        data: {order: sortable.toArray()}
      })
    }
  }
}
