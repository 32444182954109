import flatpickr from 'flatpickr'; // select date calendar
import weekSelect from 'flatpickr/dist/plugins/weekSelect/weekSelect'; // select date calendar
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'; // select date calendar

class CalendarFilters {
  constructor(options={}) {
    this.options = options
    this.calendar = null
    this.filteredUsers = []
    this.filteredCustomers = []
    this.getFilteredUsers()
    this.getFilteredCustomers()

    $("#calendar-user-filter").on('change', (event) => {
      this.getFilteredUsers()
      if(this.options.onFilterUser) this.options.onFilterUser()
    })

    $("#calendar-customer-filter").on('change', (event) => {
      this.getFilteredCustomers()
      if(this.options.onFilterCustomer) this.options.onFilterCustomer()
    })

    this.filterPicker = flatpickr("#calendar-filter-picker", this.getFilterDateOptions());
  }

  // get filtered users and cached to filteredUsers variable
  getFilteredUsers(){
    var options = document.getElementById('calendar-user-filter').selectedOptions;
    this.filteredUsers = Array.from(options).map(({ value }) => parseInt(value));
    return this.filteredUsers;
  }

  // get filtered customers and cached to filteredCustomers variable
  getFilteredCustomers(){
    var options = document.getElementById('calendar-customer-filter').selectedOptions;
    this.filteredCustomers = Array.from(options).map(({ value }) => parseInt(value));
    return this.filteredCustomers;
  }

  getFilteredCustomersWithLabels(){
    let options = document.getElementById('calendar-customer-filter').selectedOptions
    return Array.from(options).map(({ text, value }) => [text, value])
  }

  showEventsPresence(selectedDates, dateStr, instance) {
    $.getJSON('calendar/events_presence.json', {current_month: instance.currentMonth, current_year: instance.currentYear}, (eventsDates) => {
      $('.flatpickr-day').each(function () {
        let dayDate = new Date(this.dateObj)
        dayDate.setMinutes(this.dateObj.getMinutes() - this.dateObj.getTimezoneOffset())
        dayDate = dayDate.toISOString()
        if (eventsDates.includes(dayDate.split("T")[0]))
          this.classList.add('events-present')
      })
    })
  }

  getFilterDateOptions() {
    let options = {
      defaultDate: 'today',
      monthSelectorType: 'static',
      showMonths: 1,
      animate: true,
      disableMobile: true,
      dateFormat: 'M d, Y',
      altDateFormat: 'M d, Y',
      onChange: (selectedDates, dateStr, instance) => {
        this.options.onFilterDate(selectedDates[0])
        if(this.calendar && this.calendar.view.type == 'timeGridWeek') {
          instance.input.value = [
            instance.weekStartDay,
            instance.weekEndDay,
          ].map(d => instance.formatDate(d, 'M d, Y')).join(' - ');
        } else if(this.calendar && this.calendar.view.type == 'dayGridMonth') {
        }
      },
      onOpen: this.showEventsPresence,
      onMonthChange: this.showEventsPresence,
      onYearChange: this.showEventsPresence,
      onDayCreate: (dObj, dStr, fp, dayElem) => {
        if (!calendarData.businessDays.includes(dayElem.dateObj.getDay()))
          dayElem.classList.add('day-off')
      }
    }

    if(this.calendar && this.calendar.view.type == 'dayGridMonth' ) {
      options.plugins = [
        new monthSelectPlugin({
          shorthand: true, //defaults to false
          // dateFormat: "m.y", //defaults to "F Y"
          altFormat: "F Y", //defaults to "F Y"
          // theme: "dark" // defaults to "light"
        })];
    } else if(this.calendar && this.calendar.view.type == 'timeGridWeek') {
      options.plugins = [new weekSelect({})];
    }

    return options;
  }

  setDate(date, view_type) {
    this.filterPicker.destroy()
    this.filterPicker = flatpickr("#calendar-filter-picker", this.getFilterDateOptions());

    this.filterPicker.setDate(date, true)
  }
}

export default CalendarFilters;
