import {Controller} from "@hotwired/stimulus"

// data-chart-data="{
//   dates: [...],
//   payments: [NN, NN, NN, ...],
//   refunds: [NN, NN, NN, ...],
// }"
// data-value-currency="optional|$|CA$"
export default class extends Controller {
  async connect() {
    const {Highcharts} = await import("../../imports/highcharts")

    const data = JSON.parse(this.element.dataset.chartData)
    const chartTitle = this.element.dataset.chartTitle || 'Transactions'
    const valueCurrency = this.element.dataset.valueCurrency || '$'
    const time = new Highcharts.Time()

    let dates = data.dates.map(x => new Date(x).getTime())
    let paymentsSerie = data.payments.map((x, index) => ({x: dates[index], y: parseFloat(x)}))
    let refundsSerie = data.refunds.map((x, index) => ({x: dates[index], y: Math.abs(parseFloat(x))}))

    Highcharts.chart(this.element, {
      chart: {
        type: 'line',
        numberFormatter: (number, decimals) => number % 1 ? Highcharts.numberFormat(number, decimals) : number,
      },
      title: {
        text: chartTitle,
        useHTML: true,
        align: 'left',
        style: {fontSize: '14px'}
      },
      xAxis: [{
        lineWidth: 0,
        tickWidth: 0,
        type: 'datetime',
        labels: {
          format: "{value:%b %e}"
        }
      }],
      yAxis: {
        allowDecimals: false,
        title: {
          enabled: false
        },
        labels: {
          format: `${valueCurrency}{value}`
        }
      },
      tooltip: {
        valuePrefix: valueCurrency,
        valueDecimals: 2
      },
      legend: {
        enabled: true,
        align: 'right',
        verticalAlign: 'top'
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Payments',
        data: paymentsSerie,
        color: 'green'
      }, {
        name: 'Refunds',
        data: refundsSerie,
        color: 'red'
      }]
    });
  }
}
