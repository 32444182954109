import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let $element = $(this.element)
    let url = this.data.get('url')
    let create = this.data.get('create')

    let selectOptions = {
      persist: false,
      create: create,
      closeAfterSelect: true,
      dropdownParent: 'body',
      load: function(query, callback) {
        if (!query.length) return callback()

        $.ajax({
          url: url,
          type: 'GET',
          data: { q: query },
          error: () => { callback() },
          success: (res) => { callback(res) }
        })
      }
    }

    if( $element.attr('multiple') == 'multiple' ) {
      selectOptions.maxItems = null
      selectOptions.plugins = ['remove_button']
    } else {
      selectOptions.maxItems = 1
    }

    $element.selectize(selectOptions);
  }
}
