import { Controller } from "@hotwired/stimulus"

// This controller is used to check if the password and password confirmation inputs match
export default class extends Controller {
  static targets = [ "password", "confirmation" ]

  check() {
    if (this.passwordTarget.value === this.confirmationTarget.value) {
      this.confirmationTarget.setCustomValidity("")
    } else {
      this.confirmationTarget.setCustomValidity("Passwords do not match")
    }

    this.passwordTarget.classList.remove('is-invalid')
    this.confirmationTarget.classList.remove('is-invalid')
  }

}
