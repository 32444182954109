import { StreamActions } from "@hotwired/turbo"

// @see ruby companions in config/initializers/turbo_stream_actions.rb

// turbo_stream.reload
StreamActions.reload = function() {
  Turbo.visit(location.toString())
}

// Update an input value with the content of a template
// turbo_stream_action_tag 'update_input_value', target: 'input', template: 'input_value'
StreamActions.update_input_value = function () {
  this.targetElements.forEach((target) => {
    target.value = this.templateContent.textContent
  });
}

// reload a remote turbo frame
// turbo_stream_action_tag 'reload_frame', target: 'frame'
StreamActions.reload_frame = function () {
  this.targetElements.forEach((target) => {
    target.reload()
  });
}

// render a flash message
// turbo_stream_action_tag 'render_flash', message: 'message', type: 'notice|alert|success'
StreamActions.flash_message = function () {
  switch (this.getAttribute('type')) {
    case 'notice':
      Notify.notice(this.templateContent.textContent)
      break
    case 'alert':
      Notify.error(this.templateContent.textContent)
      break
    case 'success':
      Notify.success(this.templateContent.textContent)
      break
  }
}

