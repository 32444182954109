import moment from 'moment-timezone';

class TimeSlots {
  constructor(parentElement, type, timeSlots, onChange) {
    this.elementEmpty = $('#book-time-list--empty')
    this.element = $('#book-time-list')
    this.setValues(type, timeSlots)
    this.onChangeOption = onChange

    $(parentElement).on('click', '.btn-choose-time', this.onChange.bind(this));
  }

  get value() {
    return $('.btn-choose-time.active').data('value')
  }

  setValues(type, timeSlots) {
    this.type = type
    this.timeSlots = timeSlots
    this.dates = Object.keys(this.timeSlots).sort()
  }

  onChange(e) {
    const value = e.target.getAttribute('data-value');

    $('.btn-choose-time').removeClass('active');
    $(e.target).addClass('active');

    if(this.onChangeOption) this.onChangeOption(value);
  }

  get isExists() {
    return this.dates.length > 0
  }

  hasTimesForDate(date) {
    return !!this.timeSlots[date]
  }

  disable() {
    this.element.html('')
    this.elementEmpty.show();
  }

  enable() {
    // this.element.html('')
    this.elementEmpty.hide();
  }

  render(selectedDate, timezone) {
    const times = this.timeSlots[selectedDate]
    let html = ""
    if(times) {
      html = this.renderTimeSlot('Morning', times[0], timezone) +
             this.renderTimeSlot('Afternoon', times[1], timezone) +
             this.renderTimeSlot('Evening', times[2], timezone);
    } else {
      html = "<span class='time-slot-name'>All Booked</span>"
    }

    this.element.html(html)
  }

  renderTimeSlot(name, times, timezone) {
    if(times.length == 0) return "";

    let timesButtons = ""

    if(this.type == 'service') {
      for(let i = 0; i < times.length; i++) {
        var time = moment(times[i]).tz(timezone, false).format('hh:mm A')
        timesButtons += `<button type='button' class='btn btn-outline-light btn-choose-time' data-value="${times[i]}">${time}</button>`;
      }
    } else if(this.type == 'class') {
      for(let i = 0; i < times.length; i++) {
        var time = moment(times[i][0]).tz(timezone, false).format('hh:mm A')
        timesButtons += `<button type='button' class='btn btn-outline-light btn-choose-time' data-value="${times[i][1]}">${time}</button>`;
      }
    }

    return `<div class='time-slot-name ${name.toLowerCase()}'><span>${name}</span></div><div class='book-time-slot'>${timesButtons}</div>`
  }
}

export default TimeSlots;
