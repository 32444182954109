import { Controller } from "@hotwired/stimulus"

// show elements (one or many) specified by css selector if checkbox is checked
export default class extends Controller {
  toggle(event) {
    let control = event.target
    let target = $(control.dataset.targetElement)

    if(control.checked) {
      target.removeClass('d-none');
    } else {
      target.addClass('d-none');
    }
  }
}
