class Map {
  constructor() {
    this.mapContainer = document.getElementById('map-container')
    this.unassignedIconURL = this.mapContainer.dataset.unassignedIconUrl

    this.businessCoordinates = JSON.parse(this.mapContainer.dataset.businessCoordinates);

    this.mapOptions = this.defaultCoordinates();

    // Create the shared infowindow with three DIV placeholders
    // One for a text string, oned for the html content from the xml, one for the StreetView panorama.
    let infoWindowContent = document.createElement("DIV");
    this.infoWindowTitle = document.createElement("DIV");
    infoWindowContent.appendChild(this.infoWindowTitle);
    this.streetViewContainer = document.createElement("DIV");
    this.streetViewContainer.style.width = "330px";
    this.streetViewContainer.style.height = "150px";
    infoWindowContent.appendChild(this.streetViewContainer);
    this.infoWindowHtmlContent = document.createElement("DIV");
    infoWindowContent.appendChild(this.infoWindowHtmlContent);


    this.map = new google.maps.Map(this.mapContainer, this.mapOptions);
    this.infoWindow = new google.maps.InfoWindow({
      size: new google.maps.Size(360,470),
      maxWidth: 360,
      content: infoWindowContent
    });

    this.markers = []
    this.polylines = []
  }

  defaultCoordinates() {
    if(this.businessCoordinates) {
      return {
        center: {lat: parseFloat(this.businessCoordinates.lat), lng: parseFloat(this.businessCoordinates.lng)},
        zoom: 11,
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
    } else {
      // US coordinates
      return {
        center: {lat: 37.1, lng: -95.7},
        zoom: 5,
        streetViewControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
    }
  }

  buildMarkers(items) {
    this.removeMarkers();

    if(items.length == 0) {
      var options = this.defaultCoordinates();
      this.map.setCenter(options.center);
      this.map.setZoom(options.zoom);
      return;
    }

    this.index = 0
    this.markers = []

    for(var i = 0; i < items.length; i++) {
      if(items[i].hasAddress()) this.markers.push(this.buildMarker(items[i], i+1))
    }

    //center the map to the geometric center of all markers
    var bounds = new google.maps.LatLngBounds();
    this.markers.forEach(marker => bounds.extend(marker.getPosition()))
    google.maps.event.addListenerOnce(this.map, 'bounds_changed', function(event) {
      //remove one zoom level to ensure no marker is on the edge.
      // this.setZoom(this.getZoom() - 1);

      // set a minimum zoom
      // if you got only 1 marker or all markers are on the same address map will be zoomed too much.
      if(this.getZoom() > 15) this.setZoom(15);
    });

    this.map.setCenter(bounds.getCenter());
    this.map.fitBounds(bounds);
  }

  buildMarker(item, index) {
    let userColor = "red";
    let userInitials = "";
    if(item.attr.users[0]) {
      userColor = item.attr.users[0].color;
      userInitials = item.attr.users[0].initials;
    }

    const MAP_MARKER = "M17.0725327,2.91544556 C15.1836648,1.03548405 12.6721276,1.24344979e-13 10.0010119,1.24344979e-13 C7.32937464,1.24344979e-13 4.81835895,1.03548405 2.92949107,2.91544556 C-0.566113948,6.39404911 -1.00052227,12.9391388 1.98870762,16.9046015 L10.0010119,28.4210526 L18.0013217,16.9206917 C21.0025461,12.9391388 20.5681377,6.39404911 17.0725327,2.91544556 Z";

    let markerOptions = {
      position: item.addressPosition(),
      map: this.map,
      title: item.attr.title
    };

    if(item.attr.users[0]) {
      markerOptions.icon = {
        path: MAP_MARKER,
        fillColor: userColor,
        fillOpacity: 1,
        scale: 1.4,
        strokeColor: "white",
        strokeWeight: 2,
        anchor: new google.maps.Point(10, 28),
        size: new google.maps.Size(20, 28),
        labelOrigin: new google.maps.Point(10, 11),
      };
      markerOptions.label = {text: userInitials, color: "white", fontSize: '10'}
      if(item.isFinished()) {
        markerOptions.icon.strokeColor = "#A8D657";
        markerOptions.icon.strokeWeight = 3
        markerOptions.icon.fillOpacity = 0.75;
      }
    } else {
      markerOptions.icon = {
        url: this.unassignedIconURL,
        anchor: new google.maps.Point(10, 28),
        scaledSize: new google.maps.Size(28, 40),
      }
    }

    let marker = new google.maps.Marker(markerOptions);

    marker.item = item

    marker.addListener('click', () => {
      this.infoWindowHtmlContent.innerHTML = `<div class="info-content">${item.attr.card_html}</div>`;
      this.infoWindow.open(this.map, marker);
      setTimeout(()=>{ this.showStreetView(marker) }, 500)
    });

    return marker;
  }

  showStreetView(marker){
		var panorama = new google.maps.StreetViewPanorama(
			this.streetViewContainer, {
				position: marker.getPosition(),
				showRoadLabels: false,
        scrollwheel: false,
        addressControl: false,
				pov: {
          heading: 0,
					pitch: 0
			}
		});
		this.map.setStreetView(panorama);
  }

  openMarker(index) {
    google.maps.event.trigger(this.markers[index], 'click');
  }

  removeMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
  }

  // draw arrow directions between markers
  drawDirections(items) {
    for (var i = 0; i < this.polylines.length; i++) {
      this.polylines[i].setMap(null);
    }

    const lineSymbol = {
      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    };

    for (let i = 0; i < this.markers.length - 1; i++) {
      var polyline = new google.maps.Polyline({
        path: [
          { lat: this.markers[i].position.lat(), lng: this.markers[i].position.lng() },
          { lat: this.markers[i+1].position.lat(), lng: this.markers[i+1].position.lng() },
        ],
        strokeColor: '#005db5',
        strokeWeight: 2,
        icons: [ { icon: lineSymbol, offset: '100%' } ],
        map: this.map,
      });

      this.polylines.push(polyline);
    }
  }
}

export default Map;
