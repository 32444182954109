class MapList {
  constructor(options) {
    this.options = options;
    this.container = document.getElementById('map-list-container')
    this.titleEl = document.getElementById('map-list-title')
    this.itemsEl = document.getElementById('map-list-items')

    $('#map-list-items').on('click', '.list-item', this.onItemClick.bind(this));
  }

  buildList(items) {
    var index = 0;
    var itemsHtml = []
    if(items.length > 0) {
      items.forEach(i => {
        itemsHtml.push(this.buildItem(i, index++));
      });
      $(this.container).find('.no-records').addClass('d-none')
      this.itemsEl.innerHTML = itemsHtml.join('')
    } else {
      this.itemsEl.innerHTML = itemsHtml.join('')
      $(this.container).find('.no-records').removeClass('d-none')
    }
  }

  buildItem(item, index) {
    let editLink = '';

    if(item.data.links.self) {
      if(item.data.type == 'appointment') {
        editLink = `<a href="${item.data.links.self}" class="btn btn-sm btn-icon remote-modal ml-2" data-target="appointment-form"><i class="mdi mdi-pencil"></i></a>`
      } else {
        editLink = `<a href="${item.data.links.self}" class="btn btn-sm btn-icon ml-2"><i class="mdi mdi-pencil"></i></a>`
      }
    }

    let title = item.attr.title;

    if(!item.hasAddress()) {
      title = "<i class='mdi mdi-alert-circle text-danger'></i>" + title;
    }

    if(item.attr.is_recurring) {
      title += item.attr.is_recurring_stopped ? "<i class='mdi mdi-repeat-off ml-2'></i>" : "<i class='mdi mdi-repeat ml-2'></i>"
    }

    if(item.attr.is_confirmed_by_customer) {
      title += "<i class='mdi mdi-check-circle-outline text-success ml-2'></i>"
    }

    let total = item.attr.total_formatted;

    let html = `
      <tr class='list-item' data-index='${index}'>
        <td>
          <div class="list-line">
            <h6>${title}</h6>
            <h6 class="text-nowrap">${item.attr.total_formatted}</h6>
          </div>
          <div class="list-line mb-1">
            <div class="text-break truncate-multiline">${item.attr.customer_name}</div>
            ${item.attr.state_html}
          </div>
          <div class="list-line">
            <div class="text-muted">${moment(item.attr.start_at).format("MMM D, YYYY hh:mm a")}</div>
            ${editLink}
          </div>
        </td>
      </tr>
    `;

    return html;
  }

  onItemClick(e) {
    this.options.clickItem(e.currentTarget.dataset.index);
  }
}

export default MapList;
