
// Keep state of controls (view, filters, date) between page refresh
class CalendarSettings {
  constructor(calendar) {
    this.calendar = calendar;
    this.defaultView = 'resourceTimelineTenDay'
  }

  setView(value) {
    localStorage.setItem('cdr-view', value)
    this.calendar.changeView(value);
  }

  restoreView() {
    var value = localStorage.getItem('cdr-view') || this.defaultView;
    $("#calendar-select-view").selectpicker('val', value);
    this.calendar.changeView(value);
  }

  setStartDate(value) {
    localStorage.setItem('cdr-start-date', value.toISOString())
  }

  restoreStartDate() {
    var value = moment(localStorage.getItem('cdr-start-date'))
    if(value.isValid()) this.calendar.gotoDate(value.toDate());
  }

  setDisabledUsers(value) {
    localStorage.setItem('cdr-disabled-users', value)
  }

  restoreDisabledUsers() {
    var value = localStorage.getItem('cdr-disabled-users')
    if(value) {
      value.split(',').forEach((id) => $(`#calendar-filter-users li[data-user-id=${id}]`).removeClass('checked') )
    }
  }

  setFilteredUsers(value) {
    localStorage.setItem('cdr-filtered-users', value)
  }

  setFilteredCustomersWithLabels(value) {
    localStorage.setItem('cdr-filtered-customers', JSON.stringify(value))
  }

  restoreFilteredUsers() {
    var value = localStorage.getItem('cdr-filtered-users')
    if(value) {
      $("#calendar-user-filter")[0].controller.setOptions(value.split(','));
    }
  }

  restoreFilteredCustomers() {
    let value = localStorage.getItem('cdr-filtered-customers')
    if (!value) {
      return
    }

    let customerFilter = $("#calendar-customer-filter")[0]
    try {
      value = JSON.parse(value)
      for (let c of value) {
        let option = document.createElement('option')
        option.text = c[0]
        option.value = c[1]
        option.selected = true
        customerFilter.add(option)
      }
    }
    catch (e) {
      console.log(`Unparseable string: ${value}`)
      localStorage.removeItem('cdr-filtered-customers')
    }
  }

  restore() {
    this.restoreView()
    this.restoreStartDate()
    this.restoreFilteredUsers()
    this.restoreFilteredCustomers()
  }
}

export default CalendarSettings;
