import moment from 'moment-timezone';
import { Controller } from "@hotwired/stimulus"
import TimeSlots from './time_slots';
import Services from './services';
import Users from './users';
import Timezone from './timezone';
import Calendar from './calendar';

export default class extends Controller {
  static targets = ["description", "fullDescription", "fullDescriptionHeader", "fullDescriptionContent"]

  connect() {
    this.infoURL = this.element.dataset.infoUrl
    this.dataURL = this.element.dataset.dataUrl

    this.services = new Services(this.onServiceSelect.bind(this))
    this.users = new Users(this.element, this.services.users, this.loadTimeSlots.bind(this))
    this.timezone = new Timezone(this.element, this.element.dataset.defaultTimezone, this.loadTimeSlots.bind(this))
    this.timeSlots = new TimeSlots(this.element, 'service', {}, this.onTimeSelect.bind(this))
    this.calendar = new Calendar(this.onDateSelect.bind(this), this.onMonthChange.bind(this))

    this.onServiceSelect()
  }

  loadTimeSlots(callback) {
    let serviceIds = this.services.selectedIds
    let monthDate = `${this.calendar.currentYear}-${this.calendar.currentMonth+1}-01`
    if(!serviceIds) return;

    $.ajax({
      url: this.dataURL,
      type: 'GET',
      data: {
        service: serviceIds,
        service_type: this.services.selectedType,
        month_date: monthDate,
        tz: this.timezone.value,
        today: moment().format(),
        user: this.users.value
      },
      success: (data) => {
        this.timeSlots.setValues(data['type'], data['times'])

        if(this.timeSlots.isExists) {
          this.calendar.enableDates(this.timeSlots.dates)
        } else {
          // disable all days in calendar if no times in this month
          this.calendar.enableDates([moment(monthDate).add(100, 'days')._d])
        }

        if(callback) callback();
        this.renderTimeSlots();
      }
    });
  }

  onServiceSelect() {
    if(this.services.isSelected) {
      this.users.enable()
      this.users.setValues(this.services.users);
      this.calendar.enable()
      this.timezone.enable()
      this.timeSlots.enable()

      if(this.services.values.length == 1) {
        this.showDescription(this.services.selectedName, this.services.selectedDescription, this.services.selectedFullDescription)
      } else {
        this.hideDescription()
      }

      this.loadTimeSlots(() => {
        if(this.timeSlots.isExists) {
          this.calendar.setDateAndJump(this.timeSlots.dates[0])
        }
      });
    } else  {
      this.users.disable()
      this.timezone.disable()
      this.calendar.disable()
      this.timeSlots.disable()
      this.hideDescription()
    }
  }

  onMonthChange(selectedDates, dateStr, instance) {
    this.loadTimeSlots();
  }

  onDateSelect(selectedDates, dateStr, instance) {
    this.selectedDate = dateStr;
    this.renderTimeSlots();
  }

  onTimeSelect(time) {
    this.selectedTime = time
    this.redirectToInfoStep();
  }

  onShowFullDescription(e) {
    e.preventDefault();
    this.fullDescriptionTarget.classList.add('d-flex')
  }

  onCloseFullDescription(e) {
    e.preventDefault();
    this.fullDescriptionTarget.classList.remove('d-flex')
  }

  renderTimeSlots() {
    if(!this.selectedDate) return false;

    // select new date on the calendar when no times for the current selected date. Ex.: month has been changed.
    if(!this.timeSlots.hasTimesForDate(this.selectedDate) && this.timeSlots.isExists) {
      this.selectedDate = this.timeSlots.dates[0];
      this.calendar.setDate(this.selectedDate, false);
    }

    this.timeSlots.render(this.selectedDate, this.timezone.value)
  }

  showDescription(serviceName, description, fullDescription) {
    if(description == '') {
      this.hideDescription();
      return;
    }

    this.descriptionTarget.innerText = description
    if(description != fullDescription) {
      this.descriptionTarget.innerHTML = this.descriptionTarget.innerHTML + '<a href="#" class="text-primary" data-action="click->booking--schedule-step#onShowFullDescription">more</a>'
    }
    this.fullDescriptionHeaderTarget.innerText = serviceName
    this.fullDescriptionContentTarget.innerText = fullDescription
    this.descriptionTarget.classList.add('d-block')
  }

  hideDescription() {
    this.descriptionTarget.innerHTML = ''
    this.fullDescriptionHeaderTarget.innerHTML = ''
    this.fullDescriptionContentTarget.innerHTML = ''
    this.descriptionTarget.classList.remove('d-block')
  }

  redirectToInfoStep() {
    let params = {}
    let tz = this.timezone.value

    if(this.services.selectedType == 'class') {
      params = {
        class_session: this.timeSlots.value,
        tz: tz
      }
    } else {
      params = {
        service: this.services.selectedIds,
        user: this.users.value,
        start_at: moment(this.timeSlots.value).tz(tz, false).format(),
        tz: tz
      }
      let bookingForm = $('#booking_form')
      if(bookingForm.length > 0) {
        let formData = new FormData(bookingForm[0])
        formData.delete('authenticity_token')
        $.extend(params, Object.fromEntries(formData))
      }
    }
    let sourceParam = new URLSearchParams(window.location.search).get('source')
    if( sourceParam ) params['source'] = sourceParam

    Turbo.visit(this.infoURL + '?' + this.hashToQueryParams(params));
  }

  hashToQueryParams(obj) {
    var str = [];
    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  }
}
