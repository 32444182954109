import {Controller} from "@hotwired/stimulus"

// form single image upload, real upload happen on form submit
// will send form input as "" when image is removed, but rails will fail on "", so set if to null when it ""
export default class extends Controller {
  static targets = ["savedPreview", "newPreview", "emptyPreview", "file", "select", "remove"]
  static values = {
    uploaded: Boolean,
  }

  connect() {
    this.element[this.identifier] = this; // interact from another controller

    this.fileTarget.classList.add("d-none")
  }

  // preview photo on file select
  selected() {
    if(this.fileTarget.files[0]){
      const preview = this.newPreviewTarget.querySelector('img')
      const reader = new FileReader();
      reader.onload = function () {
        preview.src = reader.result
      }
      reader.readAsDataURL(this.fileTarget.files[0]);
      this.uploadedValue = true;

      this.hideInvalidMessage()
    }
  }

  remove() {
    this.uploadedValue = false
    this.fileTarget.value = null
    this.fileTarget.insertAdjacentHTML('beforebegin', `<input type="hidden" name="${this.fileTarget.name}" value="" />`)
  }

  // PRIVATE

  get isNewUpload(){
    return !!this.fileTarget.files[0]
  }

  // @event
  // show/hide elements
  uploadedValueChanged() {
    this.hasSavedPreviewTarget && this.savedPreviewTarget.classList.toggle('d-none', !this.uploadedValue || this.isNewUpload)
    this.newPreviewTarget.classList.toggle('d-none', !(this.uploadedValue && this.isNewUpload))
    this.hasEmptyPreviewTarget && this.emptyPreviewTarget.classList.toggle('d-none', !(!this.uploadedValue && !this.isNewUpload))
    this.removeTarget.classList.toggle('d-none', !this.uploadedValue)
  //  this.selectTarget.classList.toggle('d-none', this.uploadedValue)
  }

   hideInvalidMessage() {
    if(this.element.querySelector('.invalid-feedback')) {
      this.element.querySelector('.invalid-feedback').classList.toggle('d-none', true)
    }
   }
}
