import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

var stripeScriptLoaded = false;

export default class extends Controller {
  static targets = ["submitBtn",
                    "cardElement",
                    "cardErrors", "loadErrors"]

  initialize() {
    if(stripeScriptLoaded) {
      this.init()
    } else {
      this.__loadStripeScript()
    }
  }

  init() {
    try {
      var self = this

      this.$form = $(this.element)

      this.stripe = Stripe(this.data.get('stripeKey'))

      let returnUrl = this.data.get('returnUrl')

      let options = {
        clientSecret: this.data.get('setupKey'),
        appearance: {
          theme: 'stripe',
          labels: 'floating',
          variables: {
            colorTextPlaceholder: '#B3BCC7',
            colorText: '#071E3E'
          }
        }
      }

      this.stripeElements = this.stripe.elements(options)

      this.card = this.stripeElements.create('payment')
      this.card.mount(this.cardElementTarget)

      this.$form.submit((event) => {
        this.submitBtnTarget.disabled = true

        // save data first, then process stripe card
        $.ajax(this.element.action, {
          type: 'PATCH',
          dataType: 'script',
          data: this.$form.serialize()
        })
        .done((response, status) => {
          let submitOptions = {
            elements: self.stripeElements,
            confirmParams: {
              return_url: returnUrl
            }
          }

          self.stripe.confirmSetup(submitOptions).then((result) => {
            if(result.error) {
              // Inform the user if there was an error.
              self.cardErrorsTarget.innerText = result.error.message
            }
            self.submitBtnTarget.disabled = false
          })
        })
        .fail((response, status) => {
          this.submitBtnTarget.disabled = false
        })

        // disable html submit
        return false
      })

    } catch(e) {
      this.__loadError()
    }
  }

  __loadStripeScript() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => this.init();
    script.src = "https://js.stripe.com/v3/";
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  __loadError() {
    $(this.loadErrorsTarget).removeClass('d-none')
  }
}
