import {Controller} from "@hotwired/stimulus"

// data-chart-data="{avg_rating: X, total_reviews: Y, decimal_rating: Z}"
export default class extends Controller {
  async connect() {
    const {Highcharts} = (await import("../../imports/highcharts"))

    const data = JSON.parse(this.element.dataset.chartData)

    Highcharts.chart(this.element, {
      chart: {
        type: 'solidgauge',
        height: '118%',
      },
      title: {
        text: null,
        margin: 0
      },
      caption: {
        text: `<span style="font-size: 4em;">${data.avg_rating}</span><br>${this.__rating_stars(data.avg_rating)}<br><b>${data.total_reviews} reviews</b>`,
        style: {'text-align': 'center'},
        align: 'center',
        verticalAlign: 'middle',
        useHTML: true,
        x: -5
      },
      tooltip: {
        enabled: false
      },
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: {
          outerRadius: '118%',
          innerRadius: '107%',
          backgroundColor: Highcharts.color('#cfcfcf')
                           .setOpacity(0.3)
                           .get(),
          borderWidth: 0
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Move',
        data: [{
          color: '#7AC4DA',
          radius: '118%',
          innerRadius: '107%',
          y: data.decimal_rating
        }]
      }]
    });
  }

  // code copied from reviews_helper.rb
  __rating_stars(rating) {
    let stars = `<div class="rating-star-color text-nowrap" data-controller="tooltip" data-tooltip-text-value="${rating}">`

    this.__rating_value_to_stars(rating).forEach( (star_class) => {
      stars += `<i class="mdi ${star_class}"></i>`
    })
    stars += '</div>'

    return stars
  }

  __rating_value_to_stars(value) {
    let result = []
    for(let i = 1; i <= Math.floor(value); i++) {
      result.push('mdi-star')
    }

    if( value != Math.floor(value) ) {
      result.push('mdi-star-half-full')
    }

    for(let i = Math.ceil(value); i < 5; i++) {
      result.push('mdi-star-outline')
    }

    return result
  }
}
