import moment from 'moment-timezone';
import flatpickr from 'flatpickr';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['submitBtn']

  initialize() {
    this.dataURL = this.element.getAttribute('data-url')
    this.defaultTimezone = this.element.dataset.defaultTimezone
    this.timeSlots = {};
    this.calendar = null;
  }

  connect() {
    this.initTimezoneControl()

    this.calendar = flatpickr("#booking-calendar", {
      inline: true,
      minDate: 'today',
      monthSelectorType: 'static',
      onChange: this.onDateSelect.bind(this),
      onMonthChange: this.onMonthChange.bind(this),
    });

    $(this.element).on('change', '#booking-timezone', () => this.loadTimeSlots());
    $(this.element).on('click', '.btn-choose-time', this.onTimeSelect.bind(this));

    this.toggleProceedBtn();

    // // load time slots
    this.loadTimeSlots(() => {
      let dates = $.map(this.timeSlots, function(_, key){ return key; }).sort();

      if(dates.length > 0) {
        this.calendar.setDate(moment(dates[0]).format(), true);
        this.calendar.jumpToDate(dates[0], true);
      }
    });
  }


  initTimezoneControl() {
    let el = $('#booking-timezone')
    if(el.length == 0) return;

    let tz = moment.tz.names();

    for (var i = 0; i < tz.length; i++) {
      el.append(`<option value="${tz[i]}" data-offset="${moment.tz(tz[i]).utcOffset()}">GMT ${moment.tz(tz[i]).format('Z')} ${tz[i]}</option>`);
    }

    let value = el.data('value');
    if(!value) value = moment.tz.guess();

    el.selectpicker({ liveSearch: true });
    el.selectpicker('val', el.data('value'));
  }

  loadTimeSlots(callback) {
    let monthDate = `${this.calendar.currentYear}-${this.calendar.currentMonth+1}-01`
    let tz = this.getTimezoneValue();

    $.ajax({
      url: this.dataURL,
      type: 'GET',
      data: { month_date: monthDate, tz: tz, today: moment().format()},
      success: (times) => {
        this.timeSlots = times;

        times = $.map(times, function(_, key){ return key; });
        if(times.length > 0) {
          this.calendar.set('enable', times);
        } else {
          // disable all days in calendar if no times in this month
          this.calendar.set('enable', [moment().add(100, 'days')._d]);
        }

        if(callback) callback();
        this.renderTimes();
      }
    });
  }

  onTimezoneChange() {
    this.loadTimeSlots();
  }

  onMonthChange(selectedDates, dateStr, instance) {
    this.loadTimeSlots();
  }

  onDateSelect(selectedDates, dateStr, instance) {
    this.selectedDate = dateStr;

    this.renderTimes();
    this.toggleProceedBtn();
  }

  onTimeSelect(e) {
    this.selectedTime = e.target.getAttribute('data-value');
    $('.btn-choose-time').removeClass('active');
    $(e.target).addClass('active');

    let date = flatpickr.formatDate(this.calendar.selectedDates[0], 'Y-m-d');
    let time = $(this.element).find('.btn-choose-time.active').data('value');
    let tz = this.getTimezoneValue()

    // parse date and apply timezone(without changing datetime)
    date = moment(`${date} ${time}`, 'YYYY-MM-DD hh:mm A').tz(tz, true).format();
    $('#booking_start_at').val(date);

    this.toggleProceedBtn();
  }

  renderTimes() {
    if(!this.selectedDate) return false;

    let times = this.timeSlots[this.selectedDate]

    if(times) {
      $('#book-time-list').html("")
      this.renderTimesSlot('Morning', times[0])
      this.renderTimesSlot('Afternoon', times[1])
      this.renderTimesSlot('Evening', times[2])
    } else {
      $('#book-time-list').html("<span class='time-slot-name'>All Booked</span>")
    }
  }

  renderTimesSlot(name, times) {
    if(times.length == 0) return;

    let timesButtons = ""
    for(let i = 0; i < times.length; i++) {
      timesButtons += `<button type='button' class='btn btn-outline-light btn-choose-time' data-value="${times[i]}">${times[i]}</button>`;
    }
    $('#book-time-list').append(`<div class='time-slot-name ${name.toLowerCase()}'><span>${name}</span></div><div class='book-time-slot'>${timesButtons}</div>`)
  }

  toggleProceedBtn() {
    if(this.calendar.selectedDates && this.calendar.selectedDates[0] && $('.btn-choose-time.active')[0]) {
      $(this.submitBtnTarget).prop('disabled', false);
    } else {
      $(this.submitBtnTarget).prop('disabled', true);
    }
  }

  getTimezoneValue() {
    if($(this.element).find('#booking-timezone')[0]) {
      return $(this.element).find('#booking-timezone').selectpicker('val');
    } else {
      return this.defaultTimezone;
    }
  }
}
