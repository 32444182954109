import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    this.boundUpdateInitials = this.updateInitials.bind(this);
    const firstName = document.querySelector('#user_first_name');
    const lastName = document.querySelector('#user_last_name');
    firstName.addEventListener('input', this.boundUpdateInitials);
    lastName.addEventListener('input', this.boundUpdateInitials);
  }

  updateInitials() {
    const firstName = document.querySelector('#user_first_name');
    const lastName = document.querySelector('#user_last_name');
    this.element.firstChild.textContent = (firstName.value[0] ?? '') + (lastName.value[0] ?? '');
  }
}
