import { Controller } from "@hotwired/stimulus"

// show section "show" if value of "input" is changed
export default class extends Controller {
  static targets = [ "input", "show" ]
  static values = { ignoreEmpty: Boolean }

  connect(){
    this.originValue = this.inputTarget.value;
  }

  changed(event) {
    if( this.ignoreEmptyValue &&  this.originValue == "") return;

    const same = this.originValue == this.inputTarget.value;
    this.showTarget.classList.toggle('d-none', same)
  }

}
