import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  rowClick(e) {
    if(e.target.tagName == 'TD' || e.target.tagName == 'TR') {
      let checkBox = $(e.currentTarget).find("input[type='checkbox']")[0];
      if(checkBox) {
        $(checkBox).prop('checked', !$(checkBox).is(':checked'))
      }
    }
  }
}
