
class MapItem {
  constructor(data) {
    this.data = data;
    this.attr = data.attributes;
  }

  isFinished() {
    return this.attr.status == 'finished'
  }

  hasAddress() {
    return this.attr.address_position.lat;
  }

  address() {
    return attr.address;
  }

  addressPosition() {
    return {lat: parseFloat(this.attr.address_position.lat), lng: parseFloat(this.attr.address_position.lng)}
  }
}

export default MapItem;
