import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }
  static targets = ['bulkSwitch', 'switch', 'title']

  connect() {
    this._update({ isSyncBulkSwitch: true })
  }

  toggleAll() {
    const bulkChecked = Number(this.bulkSwitchTarget.checked).toString()
    const name = this.switchTargets.map(el => el.getAttribute('data-event') + ':' + el.getAttribute('data-column')).join(',')
    fetch(this.urlValue, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $("[name='csrf-token']").attr('content')
      },
      body: JSON.stringify({ name, value: bulkChecked })
    }).then(() => {
      Notify.success("The settings is updated")
    }).catch(() => {
      Notify.error("The settings isn't updated")
    })

    this._update({ isSyncBulkSwitch: false })
  }

  toggleOne() {
    this.element.setAttribute('data-params', this.element.name + '=' + Number(this.element.checked))
    this._update({ isSyncBulkSwitch: true })
  }

  _update({ isSyncBulkSwitch }) {
    if (isSyncBulkSwitch) { // sync bulk switch with its group switches
      this.bulkSwitchTarget.checked = this.switchTargets.some(el => el.checked)
    }
    else { // sync group switches with their bulk switch
      const bulkChecked = Number(this.bulkSwitchTarget.checked)
      this.switchTargets.forEach(el => el.checked = bulkChecked)
    }

    // update title styles
    const columns = {}
    this.switchTargets.forEach(el => columns[el.getAttribute('data-column')] ||= el.checked)
    this.titleTargets.forEach(el => el.classList.toggle('inactive', !columns[el.getAttribute('data-column')]))
  }
}
