import {Controller} from "@hotwired/stimulus"

// data-chart-data="[{title:, values: [1,2,3]}, ..]"
// data-chart-labels="['Negative', 'Neutral', 'Positive']"
export default class extends Controller {
  static values = {
    xFirstLetter: Boolean,
  }

  async connect() {
    const {Highcharts} = await import("../../imports/highcharts")

    const chartData = JSON.parse(this.element.dataset.chartData)
    const chartLabels = JSON.parse(this.element.dataset.chartLabels)
    const chartColors = ['#ec2a24', '#f9b12b', '#4f9f46']
    const xAxis = chartData.map(x => {
      return {short: x.title, title: x.title}
    })

    const series = chartLabels.map((label, i) => {
      const data = chartData.map(x => x['values'][i])
      return {name: label, data: data, color: chartColors[i]};
    })

    Highcharts.chart(this.element, {
      chart: {
        type: 'column'
      },
      title: {
        text: null,
      },
      xAxis: {
        categories: xAxis,
        labels: {
          formatter() {
            return this.value.short;
          }
        }
      },
      yAxis: {
        title: {
          text: null,
        }
      },
      credits: {
        enabled: false
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        formatter() {
          return `<b>${this.key.title}</b>
<br/>${this.series.name}: <b>${this.y}</b>
<br/>Total: ${this.total}`;
        },
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      },
      plotOptions: {
        column: {
          opacity: 0.9,
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        }
      },
      legend: {
        enabled: false
      },
      series: series
    });
  }
}
