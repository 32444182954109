import {Controller} from "@hotwired/stimulus"
import consumer from "./../channels/consumer"

export default class extends Controller {
  static values = {
    type: String,
    token: String,
  }

  connect() {
    this.channel = consumer.subscriptions.create({channel: 'DirectEntryChannel', type: this.typeValue, token: this.tokenValue}, {
      received: this._cableReceived.bind(this),
    });
  }

  _cableReceived(data) {
    if( data.event == 'status_changed' ){
      Turbo.visit(location.toString())
    }else if ( data.event == 'omw_location' ){
      window.dispatchEvent(new CustomEvent('direct-entry-omw-location-changed', {detail: data.data}));
    }
  }
}
