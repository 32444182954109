import {Controller} from "@hotwired/stimulus"

// send current form to the url as turbo stream
// used to update part of form when other element is changed
export default class extends Controller {
  static values = {
    url: String,
    method: {type: String, default: 'POST'}
  }

  submit(event) {
    const formData = new FormData(event.target.form);
    formData.delete('_method')
    formData.delete('authenticity_token')

    fetch(this.urlValue, {
      method: this.methodValue,
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
        'X-CSRF-Token': $("[name='csrf-token']").attr('content')
      },
      body: formData
    }).then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html));
  }


}
