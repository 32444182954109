import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if(document.documentElement.hasAttribute("data-turbo-preview")) return;

    $(this.element).on('click', '.page-link', this.switchPage.bind(this))
  }

  switchPage(event) {
    event.preventDefault()

    let href = event.target.href

    if(href){
      fetch(href)
          .then(response => response.text())
          .then(html => {
            this.element.innerHTML = html
          })
    }
  }

}
