import moment from "moment-timezone";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "allDay",
    "recurring",
    "startAt",
    "endAt",
    "startDate",
    "endDate",
    "startTime",
    "endTime",
    "sameDayEndTime",
    "endDateUntil",
    "endTimes"
  ];

  connect() {
    // this.element["controller"] = this;
    let startAt = moment(this.startAtTarget.value);
    let endAt = moment(this.endAtTarget.value);

    this.startDateTarget.value = startAt.format();
    this.endDateTarget.value = endAt.format();
    this.endDateUntilTarget.value = endAt.format();

    this.startTimeTarget.value = startAt.format('HH:mm');
    this.endTimeTarget.value = endAt.format('HH:mm');
    this.sameDayEndTimeTarget.value = this.endTimeTarget.value;

    $(this.startTimeTarget).selectize({ onChange: this.timeChanged.bind(this) });
    $(this.endTimeTarget).selectize({ onChange: this.timeChanged.bind(this) });
    $(this.sameDayEndTimeTarget).selectize({ onChange: this.timeChanged.bind(this) });

    this.startDatePicker = this.initDatePicker(this.startDateTarget.getAttribute("id"), this.startDateChanged);
    this.endDatePicker = this.initDatePicker(this.endDateTarget.getAttribute("id"), this.dateChanged);
    this.endDateUntilPicker = this.initDatePicker(this.endDateUntilTarget.getAttribute("id"), this.dateChanged);

    this.endDatePicker.set('minDate', this.startDateTarget.value)
    this.endDateUntilPicker.set('minDate', moment(this.startDateTarget.value).add(1, 'days').format())

    this.viewStateChanged();
  }

  viewStateChanged() {
    if(this.allDayTarget.checked) {
      $(this.startTimeTarget).closest('.form-group').addClass('d-none')
      $(this.endTimeTarget).closest('.form-group').addClass('d-none')
      $(this.sameDayEndTimeTarget).closest('.form-group').addClass('d-none')
    }
    else {
      $(this.startTimeTarget).closest('.form-group').removeClass('d-none')
      $(this.endTimeTarget).closest('.form-group').removeClass('d-none')
    }

    if(this.recurringTarget.checked) {
      $(this.endDateUntilTarget).closest('.form-group').removeClass('d-none')
      $(this.endTimesTarget).addClass('d-none')

      if(!this.allDayTarget.checked) {
        $(this.sameDayEndTimeTarget).closest('.form-group').removeClass('d-none')
      }

      if(!this.endDateUntilTarget.value || (moment(this.endDateUntilTarget.value) <= moment(this.startDateTarget.value))
      ) {
        this.endDateUntilPicker.setDate(moment(this.startDateTarget.value).add(1, 'days').format())
      }

    } else {
      $(this.endTimesTarget).removeClass('d-none')
      $(this.endDateUntilTarget).closest('.form-group').addClass('d-none')
      $(this.sameDayEndTimeTarget).closest('.form-group').addClass('d-none')
    }

    this.setValues();
  }

  allDayChanged(e) {
    this.viewStateChanged();
  }

  recurringChanged(e) {
    this.viewStateChanged();
  }

  timeChanged(e) {
    this.setValues()
  }

  startDateChanged(_, dateStr) {
    const startDate = dateStr;

    if(moment(startDate) > moment(this.endDateTarget.value)) {
      this.endDatePicker.setDate(moment(startDate).format())
    }

    if(moment(startDate) >= moment(this.endDateUntilTarget.value)) {
      this.endDateUntilPicker.setDate(moment(startDate).add(1, 'days').format())
    }

    this.endDatePicker.set('minDate', startDate)
    this.endDateUntilPicker.set('minDate', moment(startDate).add(1, 'days').format())

    this.setValues()
  }

  dateChanged() {
    this.setValues()
  }

  setValues() {
    let startAt, endAt;

    if(this.allDayTarget.checked) {
      startAt = moment(`${this.startDateTarget.value}`)

      endAt = this.recurringTarget.checked ?
              moment(`${this.endDateUntilTarget.value}`) :
              moment(`${this.endDateTarget.value}`)
    } else {
      startAt = moment(`${this.startDateTarget.value} ${this.startTimeTarget.value}`);

      if(this.recurringTarget.checked) {
        endAt = moment(`${this.endDateUntilTarget.value} ${this.sameDayEndTimeTarget.value}`);
      } else {
        endAt = moment(`${this.endDateTarget.value} ${this.endTimeTarget.value}`);
      }
    }

    this.startAtTarget.value = startAt.format('D/M/YYYY HH:mm');
    this.endAtTarget.value = endAt.format('D/M/YYYY HH:mm');
  }

  initDatePicker(id, onChange) {
    return flatpickr(`#${id}`, {
      altInput: true,
      altFormat: "M j, Y",
      onChange: onChange.bind(this)
    });
  }
}
