import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // previewTemplate is optional if `code` and `preview` should be different
  static targets = ["template", "previewTemplate", "preview", "code", "vars"]

  connect() {
    this.update()
  }

  update() {
    this.previewTarget.innerHTML = this.applyToTemplate(this.previewTemplateTargets[0] || this.templateTarget)
    if(this.hasCodeTarget) {
      this.codeTarget.value = this.applyToTemplate(this.templateTarget)
    }
  }

  applyToTemplate(templateEl){
    let code = this.templateValue(templateEl);
    for(const input of this.varsTargets){
      code = code.split(input.name).join(input.value)
    }
    return code;
  }

  templateValue(target){
    return target.innerHTML.trim()
  }
}
