import {Controller} from "@hotwired/stimulus"

// data-chart-data="[{month, count, rating}]"
export default class extends Controller {
  async connect() {
    const {Highcharts} = await import("../../imports/highcharts")

    const data = JSON.parse(this.element.dataset.chartData)
    const dataCategories = data.map(x => x['month'])
    const dataCounts = data.map(x => x['count'])
    const dataRating = data.map(x => x['rating'])
    Highcharts.chart(this.element, {
      title: {
        text: null,
      },
      xAxis: {
        categories: dataCategories
      },
      yAxis: [{ // Amount Axis
        title: {
          text: null,
        },
        opposite: true
      }, { // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: null,
        }
      }],
      credits: {
        enabled: false
      },
      plotOptions: {
        column: {
          opacity: 0.9
        }
      },
      legend: {
        itemStyle: {
          color: '#9b9b9b',
          fontWeight: 'normal'
        }
      },
      series: [
        {
          name: "Reviews count",
          type: "spline",
          data: dataCounts,
          color: '#3796F7',
          yAxis: 1,
          zIndex: 2
        },
        {
          name: "Rating",
          type: "column",
          data: dataRating,
          color: '#bce1ec',
          zIndex: 1
        }
      ]
    });
  }
}
