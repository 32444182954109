import { Controller } from '@hotwired/stimulus';

// Hide error message under field if it edited. Live check for blank.
// Controller is added for fields where 'required' is not used.
export default class extends Controller {
  connect() {
    this.inputs = this.element.querySelectorAll('input.is-invalid,select.is-invalid,textarea.is-invalid');
    this.boundInputErrorToggle = this.handleInputErrorToggle.bind(this);
    this.inputs.forEach(input => {
      input.addEventListener('input', this.boundInputErrorToggle);
      input.addEventListener('change', this.boundInputErrorToggle);
      input.addEventListener('click', this.boundInputErrorToggle);  // for input with datepicker
    });
  }

  handleInputErrorToggle(e) {
    const input = e.target;
    const isBlank =
      input.tagName === 'INPUT' || input.tagName === 'TEXTAREA'
        ? !input.value
        : input.tagName === 'SELECT'
          ? !input.selectedIndex > -1
          : false;

    const errorType = input.name.replace('[', '.').replace(']', '');
    document.querySelector(`[data-error-name$="${errorType}"]`)?.classList.toggle('d-none', !isBlank);
    input.classList.toggle('is-invalid', isBlank);
  }

  disconnect() {
    this.inputs.forEach(input => {
      input.removeEventListener('input', this.boundInputErrorToggle)
      input.removeEventListener('change', this.boundInputErrorToggle)
      input.removeEventListener('click', this.boundInputErrorToggle)
    });
  }
}