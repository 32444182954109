// Original source from https://guides.rubyonrails.org/active_storage_overview.html
// Currently it used and tested only for an import

addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail

  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div class="clearfix">
        <div class="float-left"><strong id="direct-upload-progress-percentage-${id}">0%</strong></div>
        <div class="float-right"><small class="text-muted">${file.name}</small></div>
      </div>
      <div class="progress progress-xs">
        <div id="direct-upload-progress-${id}" class="progress-bar bg-green" role="progressbar" style="width: 0%"></div>
      </div>
    </div>
  `)
})

addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`

  const progressPercentageElement = document.getElementById(`direct-upload-progress-percentage-${id}`)
  progressPercentageElement.innerText = `${Math.round(progress)}%`
})

addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)

  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.classList.add("bg-red")
})

addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})
