// Keep scroll menu scroll position (/settings page) after page reload/turbolink reload

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const self = this
    const activeItem = self.element.querySelector('a.active')
    function scrollIntoView() {
      if (!activeItem) return;

      window.settingsMenuScrollY === undefined
        ? activeItem.scrollIntoView()
        : self.element.scrollTop = window.settingsMenuScrollY
    }
    // use several timeouts to cover different system performances to rendering
    setTimeout(scrollIntoView)
    setTimeout(scrollIntoView, 300)
    setTimeout(scrollIntoView, 500)
    setTimeout(scrollIntoView, 1000)

    this.boundHandleWindowScrollY = this.handleWindowScrollY.bind(this)
    this.element.addEventListener('click', this.boundHandleWindowScrollY)

    let openedMenuTitles = localStorage.getItem('sts-opened-menu-titles')
    let params = new URLSearchParams(window.location.search)
    let sq = params.get('sq')
    // If search then expand all found
    if(sq) {
      $('.sub-menu-group').each(function() {
        $(this).toggleClass('open')
      })
    }
    else if(openedMenuTitles) {
      let openedMenuTitlesArray = JSON.parse(openedMenuTitles)

      $('.sub-menu-group .sub-menu-group--title').each(function() {
        if(openedMenuTitlesArray.includes(this.innerText)) {
          $(this).parent().addClass('open')
        }
      })
    }
    else if (!$(this.element).find('.sub-menu-group.open')[0]) { // By default open first and second settings group
      $(this.element).find('.sub-menu-group:first-child, .sub-menu-group:nth-child(2)').addClass('open');
    }

    // add toggle handler
    $('.column-settings-menu').on('click', '.sub-menu-group--title', (e) => {
      $(e.currentTarget).parent().toggleClass('open')
      this.rememberExpandedSubmenu()
    })

    // Remember expanded submenus for case when menu expanded from the settings home page - do not save on search
    if(!sq) {
      this.rememberExpandedSubmenu()
    }
  }

  // remember the selection of expanded submenu
  rememberExpandedSubmenu() {
    let openedMenuTitles = $('.sub-menu-group.open .sub-menu-group--title').map(function() {
      return this.innerText
    }).get()
    localStorage.setItem('sts-opened-menu-titles', JSON.stringify(openedMenuTitles))
  }

  disconnect() {
    this.element.removeEventListener('click', this.boundHandleWindowScrollY)
  }

  handleWindowScrollY(e) {
    if (e.target.closest('a'))
      window.settingsMenuScrollY = this.element.scrollTop
  }
}
