import {Controller} from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input';

// wrapper around https://github.com/jackocnr/intl-tel-input
// @see PhoneNumberHelper#tel_input_controller_dataset
export default class extends Controller {
  static targets = ['input']
  static values = {
    country: String, // if not have phone, set country
    utilsUrl: {
      type: String,
      // just fallback, prefer own app/javascript/packs/intl_tel_input_utils.js
      default: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/19.5.6/js/utils.js',
    },
    intFormat: Boolean, // international mode
  }

  connect() {
    const options = {
      utilsScript: this.utilsUrlValue,
      hiddenInput: (name) => ({phone: name, country: '_tel_country'}),
      countrySearch: false,
      nationalMode: !this.intFormatValue,
      preferredCountries: ['us', 'ca', 'gb'],
    }
    if( !this.inputTarget.value.startsWith('+') ) {
      options.initialCountry = this.countryValue;
    }
    this.intlInput = intlTelInput(this.inputTarget, options);
  }

  disconnect() {
    this.intlInput.destroy();
    super.disconnect();
  }
}
