import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["map", "address"]

  initialize() {
    this.mapOptions = {
      center: {lat: 37.1, lng: -95.7},
      zoom: 3
    };
  }

  connect() {
    if (typeof(google) != "undefined") this.initMap()
  }

  initMap() {
    this.map = new google.maps.Map(this.mapTarget, this.mapOptions);

    if(this.hasAddressTarget) {
      this.markers = []
      this.addressTargets.forEach(el => {
        if(el.dataset.lat && el.dataset.lng) {
          this.markers.push(this.buildMarker(el));
        }
      });

      if(this.markers.length == 0) return;

      //center the map to the geometric center of all markers
      var bounds = new google.maps.LatLngBounds();
      this.markers.forEach(marker => bounds.extend(marker.getPosition()))

      google.maps.event.addListenerOnce(this.map, 'bounds_changed', function(event) {
        //remove one zoom level to ensure no marker is on the edge.
        // this.setZoom(this.getZoom() - 1);

        // set a minimum zoom
        // if you got only 1 marker or all markers are on the same address map will be zoomed too much.
        if(this.getZoom() > 15) this.setZoom(15);
      });

      this.map.setCenter(bounds.getCenter());
      this.map.fitBounds(bounds);
    }
  }

  buildMarker(el) {
    return new google.maps.Marker({
            position: {lat: parseFloat(el.dataset.lat), lng: parseFloat(el.dataset.lng)},
            map: this.map
          });
  }
}
