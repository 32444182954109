import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["lat", "lng", "radius", "radiusWrapper", "polygon", "map", "autocomplete"]

  initialize() {
    this.mapOptions = {
      center: {lat: 39.5, lng: -98.35},
      zoom: 3,
      fullscreenControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: true,
      scaleControl: true,
      rotateControl: true
    };

    if(this.latTarget.value && this.lngTarget.value) {
      this.mapOptions.center = this.center
      this.mapOptions.zoom = 10;
    }
  }

  connect() {
    if (typeof(google) != "undefined") this.initMap()
  }

  initMap() {
    this.map = new google.maps.Map(this.mapTarget, this.mapOptions);

    this.drawingManager = new google.maps.drawing.DrawingManager({
      // drawingMode: google.maps.drawing.OverlayType.CIRCLE,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON,
          google.maps.drawing.OverlayType.CIRCLE
        ],
      },
      polygonOptions: this.polygonOptions,
      circleOptions: this.circleOptions,
    });

    this.drawingManager.setMap(this.map);

    google.maps.event.addListener(this.drawingManager, 'drawingmode_changed', this.onDrawingModeChange.bind(this));
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', this.onDrawComplete.bind(this));


    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteTarget);
    this.autocomplete.setComponentRestrictions({ country: ["us", "ca"] });
    this.autocomplete.addListener('place_changed', this.onAddressAutocompleChanged.bind(this));

    // prevent autocomplete for the address field. Override autocomplete='off'.
    setTimeout(() => {
      this.autocompleteTarget.setAttribute('autocomplete', 'new-password')
    }, 300);

    if(this.center) {
      // console.log('center', this.center)
      this.setModeCircle()
      this.drawCircle()
    } else if(this.polygonPath) {
      // console.log('p', this.polygonPath)
      this.setModePolygon()
      this.drawPolygon()
    }
  }

  onDrawingModeChange(event) {
    // console.log('draw mode change', event)

    // if ((drawingManager.getDrawingMode() == google.maps.drawing.OverlayType.CIRCLE) &&
    // (rectangle != null))
    // rectangle.setMap(null);
  }

  onDrawComplete(event) {
     // reset the DrawingManager to its initial state
     this.drawingManager.setDrawingMode(null);

    //  console.log(">>>>>", event, event.type)

     this.clearMap()

     if(event.type == 'circle') {
      this.setModeCircle()

       this.circle = event.overlay;
       this.radius = this.circle.radius
       this.center = this.circle.center

       this.subscribeCircleEvents()

     } else if(event.type == 'polygon') {
      this.setModePolygon()

       this.polygon = event.overlay;

       this.savePolygon()
     }
  }

  onRadiusChange() {
    if(this.radiusTarget.value == '') {
      this.radiusTarget.value = 1;
    }
    this.drawCircle()
  }

  onAddressAutocompleChanged() {
    const place = this.autocomplete.getPlace();

    if (place.geometry) {
      // this.center = place.geometry.location
      // this.drawCircle()
      this.map.panTo(place.geometry.location);
      this.map.setZoom(10);
    }
  }

  setModePolygon() {
    this.latTarget.value = ''
    this.lngTarget.value = ''
    this.radiusTarget.value = ''
    this.radiusWrapperTarget.style.display = 'none'
  }

  setModeCircle() {
    this.polygonPath = []
    this.radiusWrapperTarget.style.display = 'block'
  }

  drawCircle() {
    this.clearMap()

    if(this.center == null) return;

    this.circle = new google.maps.Circle({
      ...this.circleOptions,
      ...{
        center: this.center,
        radius: this.radius, // set the radius in meters
        map: this.map
      }
    });

    this.subscribeCircleEvents()
    this.map.fitBounds(this.circle.getBounds());
  }

  drawPolygon() {
    this.polygon = new google.maps.Polygon({
      ...this.polygonOptions,
      ...{paths: this.polygonPath, map: this.map}
    });

    var bounds = new google.maps.LatLngBounds();
    this.polygon.getPaths().forEach((path) => {
      var ar = path.getArray();
      for(var i = 0; i < ar.length; i++) {
        bounds.extend(ar[i]);
      }
    });

    this.subscribePolygonEvents()
    this.map.fitBounds(bounds);
  }

  clearMap() {
    if(this.circle) {
      this.circle.setMap(null)
    }

    if(this.polygon) {
      this.polygon.setMap(null);
    }
  }

  subscribeCircleEvents() {
    google.maps.event.addListener(this.circle, 'radius_changed', () => {
      // console.log('Radius changed:', this.circle.center, this.circle.radius);
      this.radiusTarget.value = Math.ceil(this.circle.radius / 1609)
    });

    google.maps.event.addListener(this.circle, 'center_changed', () => {
      // console.log('center changed')
      this.center = this.circle.center
    });

    google.maps.event.addListener(this.circle, 'dragend', (e) => {
      // console.log('Drag End Circle:', e);
      this.center = e.latLng
    });
  }

  subscribePolygonEvents() {
    google.maps.event.addListener(this.polygon.getPath(), 'insert_at', () => {
      // console.log('Polygon insert_at:');
      this.savePolygon()
    });

    google.maps.event.addListener(this.polygon.getPath(), 'remove_at', () => {
      // console.log('remove_at')
      this.savePolygon()
    });

    google.maps.event.addListener(this.polygon.getPath(), 'set_at', () => {
      // console.log('set_at')
      // console.log(this.polygon.getPath())
      this.savePolygon()
    });
  }

  savePolygon() {
    const path = this.polygon.getPath();
    let points = []
    for (let i = 0; i < path.getLength(); i++) {
      const xy = path.getAt(i);
      points.push([xy.lat(), xy.lng()])
    }

    this.polygonPath = points
  }



  get center() {
    if(this.latTarget.value && this.lngTarget.value) {
      return {lat: parseFloat(this.latTarget.value), lng: parseFloat(this.lngTarget.value)}
    } else {
      return null
    }
  }

  set center(latLng) {
    this.latTarget.value = latLng.lat()
    this.lngTarget.value = latLng.lng()
  }

  // get a radius in meters
  get radius() {
    return parseFloat(this.radiusTarget.value) * 1609
  }

  set radius(meters) {
    this.radiusTarget.value = Math.ceil(meters / 1609)
  }

  get polygonPath() {
    // console.log(this.polygonTarget.value)
    // const json = JSON.parse(this.polygonTarget.value)
    return JSON.parse(this.polygonTarget.value).map((point) => ({lat: parseFloat(point[0]), lng: parseFloat(point[1])}));

  }

  set polygonPath(path) {
    this.polygonTarget.value = JSON.stringify(path)
  }

  get polygonOptions() {
    return {
      fillColor: "#FFFF00",
      fillOpacity: 0.5,
      strokeWeight: 1,
      clickable: false,
      editable: true,
      zIndex: 1,
    }
  }

  get circleOptions() {
    return {
      fillColor: '#FFFF00',
      fillOpacity: 0.5,
      strokeWeight: 1,
      editable: true,
      draggable: true,
      zIndex: 1
    }
  }

}
