import {Controller} from "@hotwired/stimulus"

// if selected 'the same day' period selector then set `number` input to 0 and hide it, show `time` input
// if selected 'd' (day) period then show number and time input
// if selected 'h' (hour) or 'm' (minute) period then hide time input
export default class extends Controller {
  static targets = ["number", "selector", "time", "period"]

  connect() {
    if(this.numberTarget.value == '0' && (!this.hasPeriodTarget || this.periodTarget.value == 'd')){
      this.selectorTarget.value = 'same_day'
    }
    this.display()
  }

  onPeriodSelected() {
    if(this.hasPeriodTarget){
      this.periodTarget.value = this.isSameDay ? 'd' : this.selectorTarget.value
    }
    if(this.isSameDay){
      this.numberTarget.value = '0'
    }
    this.display()
  }

  display() {
    this.numberTarget.classList.toggle('d-none', this.isSameDay)
    if( this.hasTimeTarget ) {
      // hide time at if non day period selected
      const hide = !this.isDay
      this.timeTarget.classList.toggle('d-none', hide)
      // if hide, no require it
      this.timeTarget.querySelectorAll('input[type=time]').forEach(el =>{ el.toggleAttribute('required', !hide) })
    }
  }

  // private

  get isSameDay() {
    return this.selectorTarget.value == 'same_day'
  }
  get isDay() {
    return this.selectorTarget.value == 'same_day' || this.selectorTarget.value == 'd'
  }

}
