import {Controller} from "@hotwired/stimulus"

function supportsIntersectionObserver() {
  return (
      "IntersectionObserver" in window ||
      "IntersectionObserverEntry" in window ||
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
  );
}

// load content only when element become visible
export default class extends Controller {
  static values = {
    url: String,
    replace: Boolean,
  }

  initialize() {
    if(supportsIntersectionObserver()){
      this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries))
    }
  }

  connect() {
    if(document.documentElement.hasAttribute("data-turbo-preview")) return;
    this.element.controller = this

    if(this.intersectionObserver){
      this.intersectionObserver.observe(this.element)
    } else {
      this.loadOnce();
    }
  }

  disconnect() {
    this.intersectionObserver && this.intersectionObserver.unobserve(this.element)
  }

  loadOnce() {
    if(this.loaded) return;

    this.loaded = true;
    this.load()
  }

  reload(callback) {
    this.load(callback)
  }

  // private

  load(callback) {
    fetch(this.urlValue)
        .then(response => response.ok ? response.text() : '')
        .then(html => {
          if( this.replaceValue ){
            this.element.outerHTML = html
          } else {
            this.element.innerHTML = html
            this.disconnect()
          }
          if(callback) {
            callback()
          }
        })
  }

  processIntersectionEntries(entries) {
    entries.forEach(entry => {
      if(entry.isIntersecting){
        this.loadOnce()
      }
    })
  }


}

