import {Controller} from "@hotwired/stimulus"
import Inputmask from "inputmask";

export default class extends Controller {

  connect() {
    Inputmask({
      mask: '99:99',
      placeholder: '00:00',
      insertMode: false,
      insertModeVisual: true,
      shiftPositions: false,
      keepStatic: false,
      inputmode: "numeric"}).mask(this.element);
  }
}
