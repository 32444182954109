import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets =  ["category"];

  connect() {
    this.initializeSortables();
  }

  disconnect() {
    this.sortables.forEach(sortable => sortable.destroy());
  }

  initializeSortables() {
    this.sortables = [];

    // Initialize Sortable for categories
    const categorySortable = Sortable.create(this.element, {
      handle: ".holder",
      animation: 150,
    });
    this.sortables.push(categorySortable);

    // Initialize Sortable for items within each category
    this.categoryTargets.forEach((category, index) => {
      const itemSortable = Sortable.create(category, {
      handle: ".holder",
      animation: 150,
      group: `items-${index}`,
      });
      this.sortables.push(itemSortable);
    });
  }

  onDelete(e) {
    e.currentTarget.closest(".booking-page-form--services-table, .booking-page-form--services-row:not(:only-child)")?.remove();
  }
}
