class MainMenu {
  constructor() {
    let defaultTitle = $('title').data('title')
    let currentTitle = $('title').text()
    let menu = $('#main-menu .menu-left-link');

    let current_url = window.location.pathname

    let setTitle = function(title) {
      // don't change if it was changed on the server
      if(currentTitle != defaultTitle) return;

      $('.header-primary .header-title h2').text(title);
      if (defaultTitle) {
        title += ` | ${defaultTitle}`
      }
      $('title').text(title)
    }

    menu.removeClass('active');

    if($('.header-title--reports')[0]) {
      $('#menu-link-reports').addClass('active')
      return true;
    }

    if($('.header-title--settings')[0]) {
      $('#menu-link-settings').addClass('active')
      return true;
    }

    menu.each(function(){
      if(this.pathname == current_url) {
        $(this).addClass('active');
        setTitle($(this).find('.menu-item-label').text())
        return false;
      } else if(isUrlMatch(current_url, this.pathname) || (this.dataset.path && isUrlMatch(current_url, this.dataset.path))) { // test /resource/:id urls
        $(this).addClass('active');
        setTitle($(this).find('.menu-item-label').text())
        return false;
      }
    });

    menu.on('click', function(e) {
      menu.removeClass('active');
      $(this).addClass('active');
    });
  }
}

function isUrlMatch(current_url, path){
  return current_url.match(new RegExp("^" + path + "\/\\d+"))
}


export default MainMenu;
