import { Controller } from "@hotwired/stimulus"
import moment from 'moment-timezone'

// Select timezone with auto-detecting user's timezone
export default class extends Controller {
  connect() {
    let $element = $(this.element)

    let tz = moment.tz.names()

    for (var i = 0; i < tz.length; i++) {
      $element.append(`<option value="${tz[i]}" data-offset="${moment.tz(tz[i]).utcOffset()}">GMT ${moment.tz(tz[i]).format('Z')} ${tz[i]}</option>`)
    }

    let value = this.element.dataset.value || moment.tz.guess()

    $element.selectpicker({ liveSearch: true })
    $element.selectpicker('val', value)
  }
}
