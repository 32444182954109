import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "template",
    "itemsContainer",
    "add", // add button, for minItems (optional)
    "remove", // remove buttons, for maxItems (optional)
  ];
  static values = {
    minItems: Number, // if minItems left, hide delete button (optional)
    maxItems: Number, // if maxItems in itemsContainer, hide add button  (optional)
  };

  connect() {
    this.limitMaxItemsCount();
    this.ensureMinItemsCount();
  }

  addItem(event) {
    event.preventDefault();
    if (this.isButtonAutoClick(event)) return;

    let content = this.templateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    );

    this.itemsContainerTarget.querySelector('.no-items-added-line')?.classList.add('d-none');

    this.itemsContainerTarget.insertAdjacentHTML("beforeend", content);

    this.element.dispatchEvent(new Event("itemAdded", { bubbles: true }));

    this.limitMaxItemsCount();
    this.ensureMinItemsCount();
  }

  removeItem(event) {
    event.preventDefault();
    if (this.isButtonAutoClick(event)) return;

    let fieldsContainer = event.target.closest(".nested-record");

    if (fieldsContainer.dataset.persisted == "true") {
      fieldsContainer.querySelector("input[name*='_destroy']").value = 1;
      fieldsContainer.style.setProperty("display", "none", "important")
      fieldsContainer.classList.add('nested-record-destroyed')
    } else {
      fieldsContainer.remove();
    }

    this.element.dispatchEvent(new Event("itemRemoved", { bubbles: true }));

    this.ensureMinItemsCount();
  }

  // when focus on input and enter is pressed and button auto clicked
  isButtonAutoClick(event) {
    return (
      event.pageX === 0 &&
      event.pageX === 0 &&
      event.target != document.activeElement
    );
  }

  get itemsCount() {
    return $(this.itemsContainerTarget).find(".nested-record").length;
  }

  limitMaxItemsCount() {
    if (!(this.hasAddTarget && this.maxItemsValue)) return;
    let hide = this.itemsCount >= this.maxItemsValue;
    this.addTarget.classList.toggle("d-none", hide);
  }

  ensureMinItemsCount() {
    if (!this.minItemsValue) return;

    let hide = this.itemsCount <= this.minItemsValue;
    for (const el of this.removeTargets) {
      el.classList.toggle("d-none", hide);
    }
  }
}
