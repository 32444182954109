// ajax indicator for a.remote-loader
const selector = "a.remote-loader"

export default class {

  constructor() {
    $(document).on("ajax:before", selector, function () {
      this.classList.add('d-none')
      this.insertAdjacentHTML('afterend', '<div class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div>')
    }).on("ajax:complete", selector, function() {
      // do not revert link to initial state if freeze-loader class set
      if( !this.classList.contains('freeze-loader') ) {
        this.classList.remove('d-none')
        this.nextSibling.remove()
      }
    })
  }
}
