Notify = {
  show: function(msg, type, icon, options) {
    options ||= {}
    $.notify({
        icon: icon,
        message: msg,
        url: options?.url
      },
      {
        type: type, // 'info','success','warning','danger','rose','primary'
        delay: typeof(window.gNotifyDelay) === 'number' ? window.gNotifyDelay : (options.delay || 3000), // 3 seconds
        z_index: 2000,
        element: '#notify-wrapper', // don't remove Notification on turbo visit
        position: 'fixed',
        placement: {
          from: 'top',
          align: 'center'
        },
        template: '<div data-notify="container" class="col-xs-11 col-sm-4 alert alert-{0}" role="alert"><button type="button" aria-hidden="true" class="close" data-notify="dismiss"><span class="mdi mdi-close" ></span></button><span data-notify="icon"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span><div class="progress" data-notify="progressbar"><div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" target="{4}" data-notify="url" onclick="$(\'#notify-wrapper\').html(\'\')"></a></div>',
        animate: {
          enter: 'animated fadeInDown faster',
          exit: 'animated fadeOutUp faster'
        },
        url_target: ''
      });
  },

  notice: function(msg) {
    Notify.show(msg, 'info', 'mdi mdi-information')
  },

  success: function(msg) {
    Notify.show(msg, 'success', 'mdi mdi-check')
  },

  error: function(msg) {
    Notify.show(msg, 'danger', 'mdi mdi-alert')
  },

  // browser web push message, when app is foreground
  push: function(title, body, link) {
    Notify.show(body, 'info', 'mdi mdi-information', {delay: 10000, url: link})
  },

  close: function() {
    $('#notify-wrapper').html('')
  },

  isVisible: function() {
    let wrapper = $('#notify-wrapper')
    return wrapper.length > 0 && wrapper.is(':visible') && wrapper.html().length > 0
  }
}

