import {Controller} from "@hotwired/stimulus"

// data-chart-data="[[date,value]]"
// data-chart-type="optional|column"
// data-value-label="optional"
// data-value-format="optional|amount"
// data-value-currency="optional|$|CA$"
// data-date-format="optional|wday|hour"
export default class extends Controller {
  async connect() {
    const {Highcharts} = await import("../../imports/highcharts")

    const data = JSON.parse(this.element.dataset.chartData)
    const chartType = this.element.dataset.chartType
    const serieName = this.element.dataset.valueLabel
    const serieFormat = this.element.dataset.valueFormat
    const serieCurrency = this.element.dataset.valueCurrency || '$' // suitable only for amount format
    const dateFormat = this.element.dataset.dateFormat

    let dataSerie = data.map(x => ({x: new Date(x[0]).getTime(), y: x[1]}))

    const options = {
      chart: {
        // type:
      },
      title: {
        text: undefined,
      },
      xAxis: [{
        lineWidth: 0,
        tickWidth: 0,
        type: 'datetime',
        labels: {
          format: "{value:%b %e}"
        }
      }],
      yAxis: {
        allowDecimals: false,
        labels: {
          // for format
        },
        title: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      tooltip: {
        // for valuePrefix
      },
      plotOptions: {
        column: {
          minPointLength: 3
        },
        series: {
          marker: {
            enabled: true
          }
        }
      },

      series: [{
        name: serieName,
        data: dataSerie,
        color: '#9dd5fd',
        marker: {
          fillColor: '#3e98f4',
        }
      }]
    };
    if(chartType){
      options.chart.type = chartType
    }
    if(serieFormat == 'amount'){
      options.yAxis.labels.format = `${serieCurrency}{value}`
      // use numberFormatter to show decimal point only if need
      options.chart.numberFormatter = (number, decimals) => number % 1 ? Highcharts.numberFormat(number, decimals) : number
      options.tooltip.valueDecimals = 2
      options.tooltip.valuePrefix = serieCurrency
    }
    if(dateFormat == 'wday'){
      options.tooltip.xDateFormat = '%a'
      options.xAxis[0].tickInterval = 24 * 3600 * 1000
      options.xAxis[0].labels.format = "{value:%a}"
    }
    if(dateFormat == 'hour'){
      options.tooltip.xDateFormat = '%l%p'
      options.xAxis[0].labels.format = "{value:%l%p}"
    }
    Highcharts.chart(this.element, options);
  }
}
