import {Controller} from "@hotwired/stimulus"
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ["itemsContainer"];

  connect() {
    Sortable.create(this.itemsContainerTarget, {
      store: {
        set: this._save.bind(this)
      }
    })
  }

  disconnect() {
    Sortable.get(this.itemsContainerTarget).destroy()
  }

  addItem() {
    this._save()
  }

  removeItem() {
    this._save()
  }

  _save() {
    $(this.itemsContainerTarget).find('.nested-record:not(.nested-record-destroyed)').find('.sortable-position').each((i, el) => el.value = i)
  }
}
