import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["iframe", "form", "input"]

  initialize() {
    this.previewUrl = this.iframeTarget.getAttribute('src')
    this.update()
  }

  update(){
    const formData = new FormData(this.formTarget);
    formData.delete('_method')
    formData.delete('authenticity_token')
    let query = new URLSearchParams(formData).toString()
    let src = `${this.previewUrl}?` + query.toString()

    if( this.iframeTarget.contentWindow.Turbo ){
      this.iframeTarget.contentWindow.Turbo.visit(src)
    } else {
      this.iframeTarget.setAttribute('src', src)
    }
  }
}
