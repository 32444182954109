import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"]

  setColor(event) {
    this.fieldTarget.value = event.target.dataset.color;
    $(this.element).css({background: this.fieldTarget.value});
    $('.avatar-control .avatar--default').css({background: this.fieldTarget.value});
  }
}
