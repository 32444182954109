import {Controller} from "@hotwired/stimulus"

// data-chart-data="[1,2,3]"
// data-value-currency="optional|$|CA$"
export default class extends Controller {
  async connect() {
    const {Highcharts} = await import("../../imports/highcharts")

    const data = JSON.parse(this.element.dataset.chartData)
    const currency = this.element.dataset.valueCurrency || '$'

    Highcharts.chart(this.element, {
      chart: {
        type: 'column',
        spacing: 0,
        backgroundColor: 'transparent'
      },
      title: {
        text: null,
        margin: 0
      },
      yAxis: {
        visible: false
      },
      xAxis: {
        categories: [`<b>${currency}${data[0]}<b><br>Past Due`, `<b>${currency}${data[1]}<b><br>Due`, `<b>${currency}${data[2]}<b><br>Unsent`]
      },
      plotOptions: {
        series: {
          colorByPoint: true,
          enableMouseTracking: false,
          colors: ["#d62162", "#fdb22b", "#2c98f0"]
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [{
        name: "Amount",
        data: data
      }]
    });
  }
}
