import { Controller } from "@hotwired/stimulus"

let ajaxSelectModule = undefined;

export default class extends Controller {
  initialize() {
    ajaxSelectModule ||= import('ajax-bootstrap-select');

    this.form = $(this.element).closest('form')[0]
  }

  async connect() {
    this.element.controller = this

    let titlePlural = $(this.element).data('title-plural')
    let select = $(this.element).selectpicker({
      showContent: false,
      countSelectedText: function (numSelected, numTotal) {
        return '{0} '.concat(numSelected == 1 ? this.title : titlePlural, ' selected')
      },
    });

    this.boundSetDisabledArchived = this.setDisabledArchived.bind(this);
    this.selectAllButton = this.element.closest('.dropdown').querySelector('.bs-select-all');
    this.selectAllButton?.addEventListener("click", this.boundSetDisabledArchived, true);

    if(this.element.dataset.absAjaxUrl){
      await ajaxSelectModule;
      select.ajaxSelectPicker({
        ajax: { type: 'GET' },
        templates: {
          // The placeholder for status updates pertaining to the list and request.
          status: '<div class="filter-select-loading-text"></div>',
        }
      });
    }

    if(this.element.dataset.autosubmit == "true") {
      $(this.element).on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        this.form.requestSubmit()
      });
    } else {
      $(this.element).on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => {
        this.toggleResetButton();
      });
    }

    this.toggleResetButton();
  }

  disconnect() {
    this.selectAllButton?.removeEventListener("click", this.boundSetDisabledArchived, true);
  }

  setDisabledArchived() {
    if($(this.element).selectpicker('val').length == 0) {
      const archived = document.querySelector('#status_ [value=archived]');
      archived?.remove();
    }
  }

  setOptions(value) {
    $(this.element).val(value)
    $(this.element).selectpicker('refresh')
    this.toggleResetButton();
  }

  toggleResetButton() {
    if($(this.element).selectpicker('val').length == 0) {
      $(this.element).closest('.dropdown').find('.btn-filter-reset').remove();
      $(this.element).closest('.dropdown').find('.bs-deselect-all').remove();
      return false;
    }
    else {
      $(this.element).closest('.dropdown').find('.bs-select-all').remove();
    }

    if(!$(this.element).closest('.dropdown').find('.btn-filter-reset')[0]) {
      $(this.element.parentElement).find('.filter-option').after('<span class="btn-filter-reset" data-controller="tooltip" data-tooltip-text-value="Deselect All"><span>×</span></span>');

      $(this.element).closest('.dropdown').find('.btn-filter-reset').on('click', (e)=>{
        e.preventDefault()

        // add field to form - this allow to reset multiple select by passing blank value
        let selectName = $(this.element).prop('name')
        selectName = selectName.replace('[]', '')
        if($(`#${selectName}`).length === 0) {
          $(this.element).closest('form').append(`<input type='hidden' name='${selectName}' id='${selectName}'/>`)
        }

        $(this.element).selectpicker('deselectAll') // multiple select
        $(this.element).selectpicker('val', null) // single select
        return false;
      })
    }
  }
}
