import tippy, { hideAll, followCursor, inlinePositioning } from "tippy.js"; // tooltip
import Color from "color";

class CalendarEvent {
  constructor(calendar, info) {
    this.calendar = calendar;
    this.info = info;
    this.el = info.el;
    this.event = info.event;
  }

  render() {
    let attr = this.event.extendedProps.attr;
    // open event|task modal on event click
    if (this.event.url && ["appointment", "class_session", "event", "task", "timeoff"].includes(this.event.extendedProps.type)) {
      this.info.el.classList.add("remote-modal");
      this.info.el.setAttribute("data-target", `${this.event.extendedProps.type}-form`);
    }

    // task completed
    if (this.event.extendedProps.type == "task" && attr.completed) {
      this.info.el.classList.add("completed");
    }

    // timeoff
    if (this.event.extendedProps.type == "timeoff") {
      this.info.el.classList.add("fc-timeoff");
    }

    if (attr.users[0] && this.event.extendedProps.type != "timeoff") {
      let eventEl = $(this.el).find(".fc-content");
      if (eventEl.length == 0) eventEl = $(this.el);
      eventEl.prepend(attr.users[0].avatar);
      this.info.el.style.background = Color(`${attr.users[0].color}`).lighten(0.5)
        // this.info.el.style.border = `1px solid ${attr.users[0].color}`
      this.updateEventsCounter(attr)
    }
  }

  updateEventsCounter(attr) {
    let counterElem = $(`#resource-${attr.users[0].id}-events-counter`)
    let eventIds = counterElem.data('event-ids') || []
    eventIds.push(`${attr.id}_${attr.sequential_id}`)
    eventIds = Array.from(new Set(eventIds))
    counterElem.data('event-ids', eventIds)
    eventIds.length === 0 ? counterElem.addClass('d-none') : counterElem.removeClass('d-none')
    counterElem.text(eventIds.length)
  }

  buildTooltip() {
    if (!this.info.el) return;
    if (this.info.el && this.info.el._tippy) this.info.el._tippy.destroy();

    let attr = this.event.extendedProps.attr;

    let content = '<div class="event-tooltip">';
    content += `<div class="info-content">${attr.card_html}</div>`;
    content += "</div>";

    tippy(this.el, {
      theme: 'calendar',
      allowHTML: true,
      arrow: false,
      content: content,
      duration: 100,
      delay: [300, 100],
      maxWidth: "none",
      interactive: true,
      followCursor: "initial",
      plugins: [followCursor, inlinePositioning],
      inlinePositioning: true,
      placement: 'auto',
      appendTo: () => document.body,
      onShow: (instance) => {
        instance.popper.style.overflowY = 'auto';
        instance.popper.style.maxHeight = '100%';
        hideAll({ exclude: instance });
        return this.calendar.allowTooltip;
      },
      // for testing
      // onHide(instance) {
      //   return false;
      // }
    });
  }

  // Is visible with applied filters
  isVisible(filteredUserIds, filteredCustomerIds) {
    let filterByUsers = filteredUserIds.length > 0 && this.event.extendedProps.attr.user_ids.length > 0
    let filterByCustomers = filteredCustomerIds.length > 0 && this.event.extendedProps.attr.customer_id
    return (
        (!filterByUsers || this.intersection(this.event.extendedProps.attr.user_ids, filteredUserIds).length > 0) &&
        (!filterByCustomers || filteredCustomerIds.includes(this.event.extendedProps.attr.customer_id))
    );
  }

  // Array intersection
  intersection(a, b) {
    return a.filter(Set.prototype.has, new Set(b));
  }
}

export default CalendarEvent;
