import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.$element = $(this.element)
  }

  toggle() {
    if( this.$element.hasClass('no-data') ) return

    this.$element.toggleClass('details-expanded')
    this.$element.next().toggle()
  }
}
