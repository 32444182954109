// Choose services on the first page choosing services and classes
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['service', 'nextButton', 'count', 'amount']
  static values = {
    multiple: Boolean,
    currency: { type: String, default: 'USD' }
  }

  initialize() {
    this.currencyFormatter = Intl.NumberFormat('en', {style: 'currency', currency: this.currencyValue})
  }

  connect() {
    if(!this.multipleValue) return;
  }

  chooseService(e) {
    e.preventDefault();

    if (e.target.classList.contains("carousel-control")) return true;

    if (!this.multipleValue) {
      Turbo.visit(e.currentTarget.dataset.href);
      return true;
    }

    e.currentTarget.classList.toggle('selected')

    if(this.isExists) {
      this.nextButtonTarget.classList.remove('d-none')
    } else {
      this.nextButtonTarget.classList.add('d-none')
    }

    if(!this.validateUsersForMultiple()){
      Notify.notice("Please select services from the same employee")
      e.currentTarget.classList.remove('selected')
    }

    this.countTarget.innerText = this.selectedCount
    this.amountTarget.innerHTML = this.currencyFormatter.format(this.selectedAmount)
  }

  submit(e) {
    if(!this.multipleValue) return true;
    e.preventDefault();

    let url = this.nextButtonTarget.getAttribute('href')
    url += `?service=${encodeURIComponent(this.selectedServiceIds.join(','))}`
    Turbo.visit(url);
  }

  get isExists() {
    return this.selectedCount != 0;
  }

  get selectedCount() {
    return this.selectedServices.length;
  }

  get selectedServiceIds() {
    return this.selectedServices.map((e) => e.dataset.service_id)
  }

  get selectedAmount() {
    return this.selectedServices.reduce((total, el) => total + parseInt(el.dataset.amount), 0);
  }

  get selectedServices() {
    return this.serviceTargets.filter((el) => el.classList.contains('selected'))
  }

  validateUsersForMultiple() {
    const users = this.selectedServices.map((el) => JSON.parse(el.dataset.users))

    if(users.length <= 1) return true

    return this.intersection(users).length > 0
  }

  intersection(arrays) {
    const intersection = arrays[0].filter(function(element) {
      for (let i = 1; i < arrays.length; i++) {
        if (arrays[i].indexOf(element) === -1) return false;
      }
      return true;
    });

    return intersection;
  }
}
