// DEPRECATED
import moment from 'moment';

class FilterDateRange {
  constructor(el, ranges, autosubmit) {
    this.filter = $(el);

    this.picker = this.filter.daterangepicker({ ranges: ranges }, this.apply.bind(this));

    if(autosubmit) {
      this.filter.on('apply.daterangepicker',  ()=> {
        this.filter.closest('form').submit();
      });
    }


    if(this.filter.find('#start_date').val() == '') {
      this.apply(null, null, 'All');
    } else if(this.filter.find('#start_date').val() == 'overdue') {
      this.apply(null, null, 'Overdue');
    } else {
      var start = this.getStartDate();
      var end = this.getEndDate();
      this.filter.data('daterangepicker').setStartDate(start);
      this.filter.data('daterangepicker').setEndDate(end);
      this.apply(start, end);
    }
  }

  apply(start, end, label) {
    if(label == 'All') {
      this.filter.find('span').html('All');
      this.filter.find('#start_date').val('');
      this.filter.find('#end_date').val('');
      this.filter.data('daterangepicker').setStartDate(undefined);
      this.filter.data('daterangepicker').setEndDate(undefined);
    } else if(label == 'Overdue') {
      this.filter.find('span').html('Overdue');
      this.filter.find('#start_date').val('overdue');
      this.filter.find('#end_date').val('overdue');
      this.filter.data('daterangepicker').setStartDate(undefined);
      this.filter.data('daterangepicker').setEndDate(undefined);
      // $('.ranges li[data-range-key="Overdue"]').addClass('active');

    } else {
      this.filter.find('span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      this.filter.find('#start_date').val(start.format('YYYY-MM-DD'));
      this.filter.find('#end_date').val(end.format('YYYY-MM-DD'));
      this.filter.find('#drn').val(label);
    }
  }

  getStartDate() {
    const val = this.filter.find('#start_date').val()
    return val == "" ? moment() : moment(val)
  }

  getEndDate() {
    const val = this.filter.find('#end_date').val()
    return val == "" ? moment() : moment(val)
  }
}

export default FilterDateRange;
