import { Controller } from "@hotwired/stimulus"

// upload singe file on file select
export default class extends Controller {
  static targets = ['input']
  static values = {
    url: String,
    useInputName: Boolean
  }

  selected(event) {
    this.uploadFile()
  }

  uploadFile() {
    const fd = new FormData
    fd.append(this.__parameterName(), this.inputTarget.files[0])

    $.ajax({
      url: this.urlValue,
      type: 'PATCH',
      data: fd,
      contentType: false,
      processData: false,
    })
  }

  __parameterName() {
    let parameterName = 'file'

    if(this.useInputNameValue) {
      parameterName = this.inputTarget.name
    }

    return parameterName
  }
}
