
class Users {
  constructor(parentElement, users, onChange) {
    const elementName = '#booking_user'

    this.element = $(elementName)
    this.onChangeOption = onChange
    if(this.element.length == 0) return;

    $(parentElement).on('change', elementName, this.onChange.bind(this));

    this.element.selectpicker({});

    this.setValues(users);
  }

  setValues(users) {
    let htmlOptions = "<option value=''>Auto-assign team member</option>"

    users.forEach((user) => {
      htmlOptions += `<option value='${user.value}'>${user.text}</option>`
    })

    this.element.html(htmlOptions).selectpicker('refresh');
    // if(users.length > 0) {
    //   this.element.selectpicker('val', users[0].value);
    // }
    if(this.element.data('value')) { // set user id values that comes from info step
      this.element.selectpicker('val', this.element.data('value'))
      if(this.element.val() !== String(this.element.data('value'))) {
        this.element.selectpicker('val', '') // keep 'Auto-assign team member value' if user not present in select options
      }
    }
  }

  get value() {
    return this.element.val()
  }

  disable() {
    this.element.prop('disabled', true).selectpicker('refresh')
  }

  enable() {
    this.element.prop('disabled', false).selectpicker('refresh')
  }

  onChange(e) {
    if(this.onChangeOption) this.onChangeOption();
  }
}

export default Users;
