import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['section', 'navlink', 'tab', 'tabPane', 'scrollContainer', 'leftcontrol', 'rightcontrol'];
  timerId;

  connect() {
    this.boundHandleScroll = this.handleScroll.bind(this)
    window.addEventListener('scroll', this.boundHandleScroll)
    this.handleScroll();

    this.boundMoveLeft = this.moveLeft.bind(this)
    this.leftcontrolTarget.addEventListener('click', this.boundMoveLeft)

    this.boundMoveRight = this.moveRight.bind(this)
    this.rightcontrolTarget.addEventListener('click', this.boundMoveRight)
  }

  disconnect() {
    window.removeEventListener('scroll', this.boundHandleScroll);
    this.leftcontrolTarget.removeEventListener('click', this.boundMoveLeft)
    this.rightcontrolTarget.removeEventListener('click', this.boundMoveRight)
  }

  handleScroll() {
    let currentId;
    let currentTabId;

    this.sectionTargets.forEach(section => {
      if (window.scrollY >= section.offsetTop - 165)
        currentId = section.id;
    });

    this.tabPaneTargets.forEach(tabPane => {
      if (window.scrollY >= tabPane.offsetTop - 165)
        currentTabId = tabPane.id;
    });

    this.tabTargets.forEach(tab => {
      const isActive = tab.getAttribute('href') === '#' + currentTabId;
      tab.classList.toggle('active', isActive);
    });

    this.navlinkTargets.forEach(navlink => {
      const isActive = navlink.getAttribute('href') === '#' + currentId;
      navlink.classList.toggle('active', isActive);

      if (isActive) {
        const x = navlink.offsetLeft; // pill x
        const w = navlink.offsetWidth; // pill w
        const px = navlink.parentNode.scrollLeft; // parent scroll x
        const pw = navlink.parentNode.offsetWidth; // parent width

        clearTimeout(this.timerId);
        this.timerId = setTimeout(() => {
          let scrollX = x < px ? x - 35 : x + w > px + pw ? x + w - pw + 35 : undefined;
          if (scrollX !== undefined)
            navlink.parentNode.scroll({ left: scrollX, behavior: 'smooth' });
        }, 50);
      }
    });
  }

  moveLeft() {
    const nav = this.element.querySelector('nav .nav-link.active')?.previousElementSibling;
    nav.click();
  }

  moveRight() {
    const nav = this.element.querySelector('nav .nav-link.active')?.nextElementSibling;
    nav.click();
  }
}
