import flatpickr from 'flatpickr';

class Calendar {
  constructor(onDayChange, onMonthChange) {
    const elementName = "#booking-calendar"

    this.element = $(elementName)
    if(this.element.length == 0) return;

    this.onDayChangeOption = onDayChange
    this.onMonthChangeOption = onMonthChange

    this.calendar = flatpickr("#booking-calendar", {
      inline: true,
      minDate: 'today',
      monthSelectorType: 'static',
      onChange: this.onChange.bind(this),
      onMonthChange: this.onMonthChange.bind(this),
    });
  }

  get currentYear() {
    return this.calendar.currentYear
  }

  get currentMonth() {
    return this.calendar.currentMonth
  }

  onChange(selectedDates, dateStr, instance) {
    this.onDayChangeOption(selectedDates, dateStr, instance)
    // this.selectedDate = dateStr;
    // this.renderTimeSlots();
  }

  onMonthChange(selectedDates, dateStr, instance) {
    this.onMonthChangeOption(selectedDates, dateStr, instance)
  }

  setDate(date, triggerChangeEvent = false) {
    this.calendar.setDate(date, triggerChangeEvent);
  }

  setDateAndJump(date) {
    this.calendar.setDate(moment(date).format(), true);
    this.calendar.jumpToDate(date, true);
  }

  enableDates(dates) {
    this.calendar.set('enable', dates);
  }

  disable() {
    $('#booking-calendar--disable').show()
  }

  enable() {
    $('#booking-calendar--disable').hide();
  }

}

export default Calendar;
