import { Controller } from "@hotwired/stimulus";

// controller closes modal menu when target is clicked
export default class extends Controller {
  static targets = ["btn"]

  connect() {
    this.modal = this.element
    this.btnTargets.forEach(item =>
      item.addEventListener("click", item.boundHide = this.hide.bind(this))
    )
  }

  disconnect() {
    this.btnTargets.forEach(item =>
      item.removeEventListener("click", item.boundHide)
    )
  }

  hide() {
    $(this.modal).modal('hide')
  }
}