import {Controller} from "@hotwired/stimulus"

// data-chart-data="{
//   dates: [...],
//   reviews: [NN, NN, NN, ...],
// }"
// DEPRECATED: can be replaced with column_date_counts_controller.js
export default class extends Controller {
  async connect() {
    const {Highcharts} = await import("../../imports/highcharts")

    const data = JSON.parse(this.element.dataset.chartData)
    const chartTitle = this.element.dataset.chartTitle || 'Reviews count'
    const time = new Highcharts.Time()

    Highcharts.chart(this.element, {
      chart: {
        type: 'column',
        height: 200
      },
      title: {
        text: chartTitle,
        useHTML: true,
        align: 'left',
        style: {fontSize: '14px'}
      },
      xAxis: [{
        categories: data.dates.map(d => time.dateFormat("%b %e", new Date(d)))
      }],
      yAxis: {
        allowDecimals: false,
        title: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: [{
        data: data.reviews,
        color: '#BBE1ED'
      }]
    });
  }
}
