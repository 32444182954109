import { Controller } from "@hotwired/stimulus"

// if selected 'the same day' selector then set `day` to 0
export default class extends Controller {
  static targets = [ "day", "selector" ]

  connect(){
    this.display()
  }

  selected(){
    if(this.selectorTarget.value == "true") {
      this.dayTarget.value = '0'
      this.display()
    } else {
      this.dayTarget.classList.remove('d-none')
    }
  }

  display(){
    if(this.dayTarget.value == '0') {
      this.dayTarget.classList.add('d-none')
    } else {
      this.dayTarget.classList.remove('d-none')
    }
  }

}
