import { Controller } from "@hotwired/stimulus"

// Select industries on a setup wizard
export default class extends Controller {
  connect() {
    $(this.element).selectize({
      plugins: ['remove_button'],
      maxItems: null,
      persist: false,
      closeAfterSelect: true,
      highlight: false,
      dropdownParent: 'body'
    })
  }
}
