import {Controller} from "@hotwired/stimulus"
import Quill from 'quill';
import 'quill/dist/quill.snow';

export default class extends Controller {
  static targets = ['builder', 'fields']

  connect() {
    this.$element = $(this.element)

    this.element.onsubmit = this.save.bind(this);

    this.builder = $(this.builderTarget).formBuilder({
      sortableControls: true,
      disableFields: ['autocomplete', 'button', 'file', 'hidden', 'number'],
      disabledAttrs: [
        'access',
        'className',
        'type',
        'description',
        'inline',
        'max',
        'maxlength',
        'min',
        'multiple',
        'name',
        'other',
        'rows',
        'step',
        'style',
        'value',
        'subtype',
        'toggle',
        'placeholder'
       ],
      disableHTMLLabels: true,
      controlOrder: ['header', 'paragraph', 'text', 'textarea', 'select', 'radio-group', 'checkbox-group', 'date', 'attachment'],
      disabledActionButtons: ['save', 'data', 'clear'],
      i18n: {
        location: 'https://formbuilder.online/assets/lang/'
      },
      typeUserEvents: {
        paragraph: {
          onadd: (field) => {
            let label = $(field).find('.fld-label').hide();
            let content = $(`<div class='p-content'>${label.val()}</div>`);
            content.insertAfter(label);

            let quill = new Quill(content[0], {
              theme: 'snow',
              modules: {
                toolbar: [
                  ['bold', 'italic', 'underline', 'link'],
                  [ { list: 'ordered' }, { list: 'bullet' }, { 'align': [] }, { 'size': [] } ],
                  [{ color: [] }, { background: [] }],
                  ['clean']
                ]
              }
            });

            quill.on('text-change', function(delta, oldDelta, source){
              let html = quill.root.innerHTML
              // update a preview
              $(field).find('.field-label').html(html);
              // update a hidden label to save changes
              label.val(html)
            });


            // Prevent a toggle field on double click for a quill editor
            this.$element[0].addEventListener('dblclick', function (event) {
              if($(event.target).closest('.ql-container')[0]) {
                event.stopPropagation();
              }
            }, true);

          }
        }
      }
    })

    // load a saved json form
    this.builder.promise.then(builder => {
      builder.actions.setData(this.fieldsTarget.value);
    });

  }

  save() {
    this.fieldsTarget.value = this.builder.actions.getData('json')
  }
}
