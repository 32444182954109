// ajax indicator for checkbox[data-remove].remote-loader

const selector = "label.remote-loader > input[type=checkbox]";

export default class {

  constructor() {
    $(document).on("ajax:before", selector, function () {
      this.nextSibling.innerHTML = '<img width="16" height="16" title="" alt="" src="data:image/gif;base64,R0lGODlhEAAQAPMLAFZWVoSEhDY2NpqamtjY2B4eHgQEBLy8vLa2tsbGxuTk5P///wAAAAAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUAM/eHAAIfkEBQoACwAsAAAAABAAEABABE1wybnGoDPogCf6xyJcS8FJyKJ80iolaZx2ZWoYyzFjwk5TBJ8HocDoUC+h5HRD0gSFRZOIAQgEhA7op91yJVmKdxLWhT3ZGeJMKToxEQAh+QQFCgALACwAAAAADAAMAAAEKXDJKRGdQ5YiDRXHsoneBAhiQRhZEC7oVCJBoFxXkOA8fiDAnnAo3OEiACH5BAUKAAAALAEAAAAOAAcAAAQnEMiJpp1HCiHLBQEBbKN3DcEoKEUFKIgIuoA3GEaC0BZuArEPQBYBACH5BAUKAAMALAQAAAAMAAkAAAQmcMiUpJVEhiDFRcqwDYBnHYjImV/WDUhRXJfM0lKIX0Y/7z1DIQIAIfkEBQoABwAsBwAAAAkADAAABCOQkEPrGYMGi0/YVRdMlkYJQokCZVsWcGrBhVyBrWFkbWEEEQAh+QQFCgAHACwHAAAACQAPAAAEK1CpQ+tBiA6L767dZ1EZEQTjiY6sJbzA+ApxyxZFVgXJIRQWg8FmWMmGlAgAIfkEBQoABAAsCAABAAgADwAABCmQSCQrOdQSpHRVSDYhh2cSQTpYabCelSBYLyAMBlEUVq73EgBP4tNEAAAh+QQFCgAAACwEAAcADAAJAAAEJBDIKZE9lFqEc/VZEBAgQoqYYAAFIAiZEbCSMEzFStfTLbWeCAAh+QQFCgALACwBAAkADwAHAAAEKHDJSRepwVSJkJKGIVTJdyDEoC1AsYxBsEnuKwXHJtTjMsgVxKRXiQAAIfkEBQoABwAsAAAIAA0ACAAABCHQSHOqvTjjom0pAEZYCFcJR3AgSCYMaYWMFxofSaupVgQAIfkEBQoAAAAsAQAEAAgADAAABB7QBECrMCbVKrf/YDgInkCW1DAhW3AALBVMcEVUSAQAIfkEBQoACwAsAAABAAcADwAABCdwyRXmNMiuYmS5wAIUBaEVg2YJrKC+RzUFQSoRcpJZiDkdu4lCEgEAOw==" style="position: absolute;left: -24px;top: 1px; z-index: 1;" />'
    }).on("ajax:complete", selector, function(xhr, status){
      this.nextSibling.innerHTML = ''
    }).on("ajax:error", selector, function(){
      this.checked = !this.checked;
    })
  }

}

