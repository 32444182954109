import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['template']

  add(e) {
    e.preventDefault();
    let tdDay = $(e.target).closest('.flex-column').find('.time-ranges')
    tdDay.append(this.templateTarget.innerHTML)
  }

  toggleDay(e) {
    let row = $(e.target).closest('.flex-column');
    let selects = row.find('select');
    selects.prop('disabled', !e.target.checked)
    selects.selectpicker('refresh');

    row.toggleClass('closed')

    $(e.target).closest('.flex-column').find('.business-hours-hidden-value').prop('disabled', !e.target.checked)

    $(e.target).closest('.flex-column').find('.btn-add-hours, .btn-remove-hours').toggleClass('d-none', !e.target.checked)
  }
}
