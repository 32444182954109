
class Timezone {
  constructor(parentElement, defaultTimezone, onChange) {
    const elementName = '#booking_timezone'

    this.defaultTimezone = defaultTimezone
    this.element = $(elementName)
    if(this.element.length == 0) return;

    this.onChangeOption = onChange

    $(parentElement).on('change', elementName, this.onChange.bind(this));

    let tz = moment.tz.names();
    for (var i = 0; i < tz.length; i++) {
      this.element.append(`<option value="${tz[i]}" data-offset="${moment.tz(tz[i]).utcOffset()}">GMT ${moment.tz(tz[i]).format('Z')} ${tz[i]}</option>`);
    }

    let value = this.element.data('value') || moment.tz.guess();

    this.element.selectpicker({ liveSearch: true });
    this.element.selectpicker('val', value);
  }

  get value() {
    return this.element[0] ? this.element.selectpicker('val') : this.defaultTimezone;
  }

  disable() {
    this.element.prop('disabled', true).selectpicker('refresh')
  }

  enable() {
    this.element.prop('disabled', false).selectpicker('refresh')
  }

  onChange(e) {
    if(this.onChangeOption) this.onChangeOption();
  }
}

export default Timezone;
