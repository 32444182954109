import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["img", "data"]
  static values = {
    dataFieldId: String // get crop data from non controller field
  }

  async connect() {
    const {Cropper} = await import("../imports/cropperjs") // https://fengyuanchen.github.io/cropperjs/

    var data = this.dataSourceField.value ? JSON.parse(this.dataSourceField.value) : null
    const cropper = new Cropper(this.imgTarget, {
      viewMode: 2,
      // autoCropArea: 1.0,
      data: data,
      crop: this._onCrop.bind(this)
    });
  }

  get dataSourceField() {
    if(this.dataFieldIdValue){
      return document.getElementById(this.dataFieldIdValue)
    } else {
      return this.dataTarget
    }
  }

  _onCrop(event) {
    let data = JSON.stringify({
      x: Math.round(event.detail.x),
      y: Math.round(event.detail.y),
      width: Math.round(event.detail.width),
      height: Math.round(event.detail.height)
    });
    this.dataTarget.value = data
  }
}
