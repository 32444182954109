import {Controller} from "@hotwired/stimulus"

// data-chart-data="{
//   positive_ratings: NN, neutral_ratings: NN, negative_ratings: NN,
//   dates: [...],
//   reviews: [NN, NN, NN, ...],
//   ratings: [N.N, N.N, N.N, ...]
// }"
export default class extends Controller {
  async connect() {
    const {Highcharts} = await import("../../imports/highcharts")

    const data = JSON.parse(this.element.dataset.chartData)
    const time = new Highcharts.Time()

    Highcharts.chart(this.element, {
      title: {
        useHTML: true,
        text: `<div class="d-flex justify-content-between text-center">
            <div class="mr-5"><b>${data.positive_ratings}</b><br/><span class="font-weight-bolder" style="color: #adb5bd; font-size: 0.6em;">Positive</span></div>
            <div class="mr-5"><b>${data.neutral_ratings}</b><br/><span class="font-weight-bolder" style="color: #adb5bd; font-size: 0.6em;">Neutral</span></div>
            <div><b>${data.negative_ratings}</b><br/><span class="font-weight-bolder" style="color: #adb5bd; font-size: 0.6em;">Negative</span></div>
          </div>`
      },
      xAxis: [{
        categories: data.dates.map(d => time.dateFormat("%b %e", new Date(d)))
      }],
      yAxis: [{ // left yAxis used for ratings (1-5)
        alignTicks: false,
        max: 5,
        allowDecimals: false,
        labels: {
          style: {
            color: '#adb5bd'
          }
        },
        title: {
          enabled: false
        }
      }, { // right yAxis used for reviews counts
        opposite: true,
        allowDecimals: false,
        title: {
          enabled: false
        },
        labels: {
          style: {
            color: '#adb5bd'
          }
        }
      }],
      tooltip: {
        shared: true
      },
      legend: {
        itemStyle: {
          color: '#adb5bd',
          fontWeight: 'normal'
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        name: 'Reviews Count',
        type: 'column',
        yAxis: 1,
        data: data.reviews,
        color: '#BBE1ED'
      }, {
        name: 'Reviews Rating',
        type: 'line',
        data: data.ratings.map(n => parseFloat(n)),
        color: '#4E99B0'
      }]
    });
  }
}
