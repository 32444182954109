import { Controller } from "@hotwired/stimulus"

import Quill from 'quill'
import 'quill/dist/quill.snow'
//import 'quill-mention'

export default class extends Controller {
  static values = {
    formType: String,
    method: String,
    url: String
  }

  connect() {
    if( !this.hasMethodValue ) {
      this.methodValue = this.isCreateForm() ? 'POST' : 'PUT'
    }

    let $element = $(this.element)

    if( this.isCreateForm() ) {
      this.$editorContainer = $element
    } else {
      this.$editorContainer = $element.closest('.note').find('.note-content')
    }

    if( this.isCreateForm() ) {
      this.initEditor()
    } else {
      $element.on('click', this.initOnClick.bind(this))
    }
  }

  isCreateForm() {
    return this.formTypeValue == 'create'
  }

  initOnClick(event) {
    event.preventDefault()

    if( this.$editorContainer.find('.ql-editor').length == 0 ) {
      this.initEditor()

      event.currentTarget.innerHTML = "<i class='mdi mdi-close'></i>"
    } else {
      event.currentTarget.innerHTML = "<i class='mdi mdi-pencil'></i>"
      this.cancel()
    }
  }

  initEditor() {
    this.originalContent = this.$editorContainer.html()

    this.$editorContainer.html(`<div class='note-editor-wrapper'>
                                  <div class='note-editor'></div>
                                  <div class='note-editor--actions'>
                                    <button type="button" class="save-note-btn btn text-primary">
                                      <i class="mdi mdi-plus-circle"></i>
                                    </button>
                                  </div>
                               </div`)

    this.saveBtn = this.$editorContainer.find('.save-note-btn')

    if( this.isCreateForm() ) {
      this.saveBtn.prop('disabled', true)
    }

    this.quill = new Quill(this.$editorContainer.find('.note-editor')[0], {
      theme: 'snow',
      // placeholder: 'Add a note about this contact. Mention someone by typing "@" in front of their name to notify them about it.',
      placeholder: 'Add text notes, not visible to the customer',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'link'],
          [ { list: 'ordered' }, { list: 'bullet' }, { 'align': [] }, { 'size': [] } ],
          [{ color: [] }, { background: [] }],
          ['clean']
        ]
        // TODO: disable mentions for now
        // mention: {
        //   dataAttributes: ['id'],
        //   source: function(searchTerm, renderList, mentionChar) {
        //     $.getJSON('/users/fetch', { q: searchTerm}, function(data){
        //       renderList(data, searchTerm);
        //     });
        //   },
        //   renderItem: function(item, searchTerm) {
        //     let html = `<div>${item.value}</div><small class='text-muted'>${item.email}</small>`;
        //     return html;
        //   }
        // }
      }
    })

    // set content with innerHTML to avoid "TypeError: after.appendChild is not a function" error for the <strong> tag
    this.quill.root.innerHTML = this.originalContent

    this.saveBtn.on('click', this.save.bind(this))

    this.$editorContainer.find('.btn-note-cancel').on('click', this.cancel.bind(this))

    this.quill.on('text-change', this.toggeDisabledSubmitButton.bind(this));
  }

  save() {
    this.saveBtn.attr('disabled', true).val('Saving...')

    $.ajax({
      type: this.methodValue,
      url: this.urlValue,
      data: { 'note[content]': this.quill.root.innerHTML }
    })
    .done((data) => {
      if( this.isCreateForm() ) {
        this.quill.setText('')
      } else {
        this.$editorContainer.html(this.quill.root.innerHTML)
        $(this.element).closest('.note').find('.btn-note-edit').inenerHTML = "<i class='mdi mdi-pencil'></i>"
        Notify.success('Note is updated')
      }

    });
  }

  cancel() {
    if( this.isCreateForm() ) {
      this.quill.setText('');
      this.toggeDisabledSubmitButton();
    } else {
      this.$editorContainer.html(this.originalContent);
    }
  }

  toggeDisabledSubmitButton() {
    if(this.quill.getLength() > 1) {
      this.saveBtn.attr('disabled', false)
    } else {
      this.saveBtn.attr('disabled', true).val('Save')
    }
  }
}
