import { Controller } from "@hotwired/stimulus"

// 1. show section "show" if checkbox is checked and hide "hide" section
// 2. show section "show" that has [data-name] same as select.value
export default class extends Controller {
  static targets = [ "show", "hide", "source" ]
  static values = {
    disableInputs: Boolean
  }

  connect() {
    for(const el of this.sourceTargets){
      el.dispatchEvent(new Event('change'));
    }
  }

  toggle(event) {
    if(event.target.checked) {
      $(this.showTargets).removeClass('d-none');
      $(this.hideTargets).addClass('d-none');
    } else {
      $(this.showTargets).addClass('d-none');
      $(this.hideTargets).removeClass('d-none');
    }
  }

  // next two methods are usable for switching visibility with radio buttons or plain elements
  show(event) {
    if(event.target.checked || event.target.checked === undefined) {
      $(this.showTargets).removeClass('d-none');
      $(this.hideTargets).addClass('d-none');
    }
  }

  hide(event) {
    if(event.target.checked || event.target.checked === undefined) {
      $(this.showTargets).addClass('d-none');
      $(this.hideTargets).removeClass('d-none');
    }
  }

  select(event) {
    const value = event.target.value
    for(const el of this.showTargets){
      const show = el.dataset.name == value;
      el.classList.toggle('d-none', !show);
      if( this.disableInputsValue ){
        for(const input of el.querySelectorAll('input, select, textarea')){
          input.disabled = !show;
        }
      }
    }
  }

}
