import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    window.ServerMessage.on('import_progress', this.updateProgress)
  }

  updateProgress(e) {
    const data = e.message.data
    const el = $('#import_' + data.id);
    el.attr('class', 'import-item ' + data.attributes.status)
    el.find('.import-created').text(data.attributes.created_objects_count)
    // el.find('.import-updated').text(data.attributes.updated_objects_count)
    el.find('.import-error').text(data.attributes.error_count)
    el.find('.import-status').text(data.attributes.status)

    if(data.attributes.error_file_url) {
      el.find('.item-action .dropdown-menu').
        append(`<a href='${data.attributes.error_file_url}' class='dropdown-item'>Download error file</a>`)
    }
  }
}
