// Filter a list of items based on a search input

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item"]

  filter(e) {
    const value = e.currentTarget.value.toLowerCase();

    this.itemTargets.forEach((item) => {
      if (item.dataset.itemValue.toLowerCase().includes(value)) {
        item.classList.remove('d-none')
      } else {
        item.classList.add('d-none')
      }
    })
  }
}
