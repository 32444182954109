import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["value"]

  connect() {
    let selectOptions = {
      liveSearch: true,
      noneSelectedText: ''
    };

    this.startTime = $(this.element).find('.start-time').selectpicker(selectOptions);
    this.endTime = $(this.element).find('.end-time').selectpicker(selectOptions);

    this.setInitialValues();

    $(this.startTime).on('changed.bs.select', this.startTimeChanged.bind(this));
    $(this.endTime).on('changed.bs.select', this.endTimeChanged.bind(this));
  }

  startTimeChanged(e, clickedIndex, isSelected, previousValue) {
    if(this.isNotValidTimes()) {
      $(this.endTime).selectpicker('val', this.startTimeValue())
    }
    this.buildValue()
  }

  endTimeChanged(e, clickedIndex, isSelected, previousValue) {
    if(this.isNotValidTimes()) {
      $(this.startTime).selectpicker('val', this.endTimeValue())
    }
    this.buildValue()
  }

  buildValue() {
    if(this.isNotValidTimes()) {
      this.valueTarget.value = null;
    } else {
      this.valueTarget.value = `${this.startTimeValue()} - ${this.endTimeValue()}`
    }
  }

  startTimeValue() {
    return $(this.startTime).selectpicker('val');
  }

  endTimeValue() {
    return $(this.endTime).selectpicker('val');
  }

  isNotValidTimes() {
    return this.startTimeValue() == null || this.endTimeValue() == null;
  }

  setInitialValues() {
    let value = this.valueTarget.value.split(' - ');
    if(value.length == 2) {
      $(this.startTime).selectpicker('val', value[0]);
      $(this.endTime).selectpicker('val', value[1]);
    } else {
      $(this.startTime).selectpicker('val', null);
      $(this.endTime).selectpicker('val', null);
    }
  }

  remove(e) {
    this.element.remove();
  }
}
