import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

// on form submit, prevent it, take form data, send it as GET to url and update content
// handle div(data: {'form-filter-target': 'links', 'filter-name': 'name'})) > a href=#value
// click on link set filter to name=value
export default class extends Controller {
  static values = {
    // TODO: update to type-default format after rails & stimulus upgrade
    // remote: { type: Boolean, default: true }
    remote: Boolean
  }
  static targets = ["form", "links", "content"]

  connect() {
    if( !this.hasLinksTarget ) return;

    const linkHiddenEl = $('<input type="hidden" disabled>')[0]
    linkHiddenEl.name = this.linksTarget.getAttribute('data-filter-name')
    this.formTarget.append(linkHiddenEl)

    $(this.linksTarget).on('click', 'a', this._linkClick.bind(this, linkHiddenEl))
  }


  save() {
    // change this condition when default will be added
    if( !this.hasRemoteValue || this.remoteValue ) {
      let data = new FormData(this.formTarget)
      data.set('FILTER', '1')
      data.set('_method', 'GET')

      let url = this.formTarget.action;
      url += url.includes('?') ? '&' : '?'
      url += new URLSearchParams(data).toString()

      fetch(url, {
        method: 'POST',
        body: data,
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
        .then(response => response.text())
        .then(html => {
          this.contentTarget.innerHTML = html
        })
    } else {
      Rails.fire(this.formTarget, 'submit')
    }
  }

  _linkClick(inputEl, e) {
    e.preventDefault();

    for(let a of Array.from(e.target.parentNode.children)){
      a.classList.toggle('active', a == e.target)
    }

    inputEl.disabled = false
    inputEl.value = e.target.href.split('#', 2)[1]

    this.save()
  }
}
